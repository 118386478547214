export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_RESET='SIGNUP_RESET'
export const LOGOUT = "LOGOUT";

export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_ERROR="LOGIN_ERROR";
export const LOGIN_REQUEST='LOGIN_REQUEST'


export const PAYMENT_SUCCESS='PAYMENT_SUCCESS'