import React, { useState, useEffect, useRef } from "react";
import Navbar from "../SelfNavbar";
import Footer from "../Foot";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProfileFetch, ProfileReset, UpdateProdile } from "../../../Redux/SelfLearning/SelfAction/Profile.action";
import { SignOutfuntion } from "../../../Redux/SelfLearning/SelfAction/Auth.action";
import { environment } from "../../../Api";
import { Toast } from "primereact/toast";
import brain from "../../../brain.gif"


const initial={
      first_name : "",
      last_name : "",
      address : "",
      country : "",
      state : "",
      pincode : "",
      role : "STUDENT",
      mobile : "",
      age:"",
      gender:""
}

const EditProfile = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 876);
  const [update,setUpdate]=useState(initial)
  useEffect(() => {
    // Function to update isSmallScreen when the window is resized
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 876);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleChange=(e)=>{
    const {name,value}=e.target 
    setUpdate({...update,[name]:value})
    }

  // const [selectedCity, setSelectedCity] = useState(null);
  // const cities = [
  //   { name: "New York", code: "NY" },
  //   { name: "Rome", code: "RM" },
  //   { name: "London", code: "LDN" },
  //   { name: "Istanbul", code: "IST" },
  //   { name: "Paris", code: "PRS" },
  // ];

  const listStyles = {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: isSmallScreen?"16px":"20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    listStyle: "none",
    textDecoration: "none",
    paddingLeft: "5%",
    marginRight: "5%",
  };

const dispatch=useDispatch()
const {data,message,loading}=useSelector(store=>store.profile)
const {token}=useSelector(store=>store.auth)
const navigate=useNavigate()
const toast = useRef(null);

useEffect(()=>{
dispatch(ProfileFetch())
},[])

console.log(data,"profileedit",token)

let name=data && data.full_name ? data.full_name.split(" ") : "";

const handleLogout=()=>{
  dispatch(SignOutfuntion())
  navigate("/self-learning")
}
const handleSubmit=(e)=>{
e.preventDefault()
// console.log(update);
const payload={
  first_name: update.first_name? update.first_name : name[0],
  last_name: update.last_name? update.last_name : name[1],
  address: update.address? update.address : data&& data.address&& data.address.address,
  country: update.country? update.country : data&&data.address&& data.address.country,
  state: update.state? update.state : data&&data.address&& data.address.state,
  pincode: update.pincode? update.pincode : data&&data.address&& data.address.pincode,
  role:"STUDENT",
  mobile: update.mobile? update.mobile : data&&data.phone_number,
  gender: update.gender? update.gender : data&&data.gender,
  age: update.age? update.age : data&&data.age,
}
// console.log(payload)
dispatch(UpdateProdile(payload))
}
// useEffect(()=>{
// if(token && token.status === 'INACTIVE') navigate("/self-learning/cart")
// },[token])

useEffect(()=>{
  if(message==='basic details updated'){
    showSuccess("basic details updated")
    dispatch(ProfileReset())
    dispatch(ProfileFetch())
    setTimeout(()=>{
    navigate("/self-learning")
    },3000)
  }
},[message])
const showError = (message) => {
  toast.current.show({
    severity: "error",
    summary: "Error",
    detail: message,
    life: 3000,
  });
};

const showSuccess = (message) => {
  toast.current.show({
    severity: "success",
    summary: "Success",
    detail: message,
    life: 1000,
  });
};

  return (
    <>
                  <Toast ref={toast} />

      <Navbar />
      {data.length==0?<div className="flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto  block" ></img></div>:

      <div
        className="fle"
        style={{
          display: isSmallScreen ? "" : "flex",
          justifyContent: isSmallScreen ? "center" : "flex-start",
          alignItems: "flex-start",
          marginTop: "6%",
          gap: "6%",
        }}
      >
        <div
          className="c pl-4 pr-3 md:pl-0 md:pr-0 pb-3 md:pb-0"
          style={{
            width: isSmallScreen ? "100%" : "306px",
            height: isSmallScreen ? "auto" :"492px",
            flexShrink: 0,
            marginLeft: isSmallScreen ? "" : "5%",
            borderRadius: "10px",
            border: isSmallScreen ? "" : "1px solid #FFF",
            background: "#FFF",
            boxShadow: isSmallScreen
              ? "none"
              : "0px 1px 4px 2px rgba(0, 176, 90, 0.15)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "7%",
            }}
          >
            <Button
              className=" flex align-items-center "
              icon="pi pi-user"
              style={{
                width:  isSmallScreen ?"90px":"100px",
                height: isSmallScreen ?"90px":"100px",
                flexShrink: 0,
                fill: "#0E8E60",
                backgroundColor: "#0E8E60",
                border: "none",
                borderRadius: "50%",
              }}
            />
            <div>
              <p
                style={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen ?"20px":"24px",
                  fontStyle: "normal",
                  fontWeight: isSmallScreen ?500:600,
                  lineHeight: "normal",
                }}
              >
                {/* Sarathi */}
                {data&&data.full_name}
              </p>
            </div>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "50px",
            }}
          >
            <Link
              to="/self-learning/edit-profile"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li
                className="text-left py-2 md:py-0"
                style={{
                  color: isSmallScreen ? "#fff" : "#0E8E60",
                  backgroundColor: isSmallScreen ? "#0E8E60" : "#fff",
                  paddingLeft: "5%",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen?"16px":"20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  listStyle: "none",
                  textDecoration: "none",
                  marginRight: isSmallScreen ?"3%": "5%",
                  borderRadius:isSmallScreen? "5px":""
                }}
              >
                Profile
              </li>
            </Link>
            <Link
              to="/self-learning/edit-photo"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="text-left" style={listStyles}>
                Photo
              </li>
            </Link>
            {/* <Link
              to="/self-learning/add-payments"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="text-left" style={listStyles}>
                Payment Method
              </li>
            </Link> */}
            <li className="text-left cursor-pointer" onClick={()=>handleLogout()} style={listStyles}>
              Logout
            </li>
          </ul>
        </div>
        <div
          className=" pt-4 pl-2 pr-2 md:pr-8 h-full overflow-y-scroll card2"
          style={{
            display: "flex",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "70%",
            // border:"1px solid "

          }}
        >
          <div
            className="flex flex-column w-11"
            style={{
              width: isSmallScreen?"70%":"",
              display: "flex !important",
              alignContent: "center",
              flexDirection: "column",
              alignItems: "center", // Center vertically
              // margin: " auto", // Center horizontally
              // marginRight: isSmallScreen ? "auto" : "",
              // border:"1px solid red"
            }}
          >
            <h1
              style={{
                color: "#0E8E60",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "16px" : "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginTop: isSmallScreen ? "0px" :"-30px",
              }}
            >
              Public Profile
            </h1>
            <p
              style={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "12px" : "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Add information about yourself
            </p>
          </div>

          <h3 className="checkoutformhead text-left ml-3 md:ml-0 mt-5 md:mt-0" style={{fontSize:isSmallScreen?"16px":"",fontWeight:isSmallScreen?600:""}}>Basics</h3>
          <form
            className="w-11"
            style={{
              margin:isSmallScreen?"auto":"",
              marginBottom: "20px",
              // border:"1px solid red"
            }}  
            onSubmit={handleSubmit}
          >
            <div className="flex mt-2 gap-1 md:gap-7 mb-3">
              <div className="flex flex-column gap-2 w-6 md:w-6">
                <label className="text-sm text-left checkoutlbl">
                  First name
                </label>
                <InputText
                  // placeholder="First name"
                  className="w-full h-3rem " 
                  defaultValue={name[0]} name="first_name" onChange={handleChange}
                />
              </div>
              <div className="flex flex-column gap-2 w-6 md:w-6">
                <label className="text-sm text-left checkoutlbl">
                  Last name
                </label>
                <InputText
                  name="last_name" onChange={handleChange}
                  // placeholder="Last name"
                  className="w-full h-3rem "                   defaultValue={name[1]}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-column mb-3 gap-2 w-12 md:w-12">
                <label className="text-sm text-left checkoutlbl">Address</label>
                <InputText
                  name="address" onChange={handleChange}
                  // placeholder="Address"
                  className="w-full h-3rem "
                  defaultValue={data&& data.address? data.address.address:"" }
                />
              </div>
              <div className="flex gap-1 md:gap-7 mb-3">
                <div className="flex flex-column gap-2 w-6 md:w-6">
                  <label className="text-sm text-left checkoutlbl">
                    Country
                  </label>
                  <InputText
                  name="country" onChange={handleChange}
                  // placeholder="country"
                  className="w-full h-3rem "                    defaultValue={data&& data.address? data.address.country:"" }
                />                  {/* <Dropdown
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.value)}
                    options={cities}
                    optionLabel="name"
                    placeholder="Choose your Country"
                    className="w-full h-3rem text-left"
                  /> */}
                </div>
                <div className="flex flex-column gap-2 w-6 md:w-6">
                  <label className="text-sm text-left checkoutlbl">State</label>
                  <InputText
                  name="state"  onChange={handleChange}
                  // placeholder="state"
                  className="w-full h-3rem "                    defaultValue={data&& data.address? data.address.state:"" }
                />
                  {/* <Dropdown
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.value)}
                    options={cities}
                    optionLabel="name"
                    placeholder="Choose your State"
                    className="w-full h-3rem text-left"
                  />{" "} */}
                </div>
              </div>
              <div className="flex gap-1 md:gap-7 mb-3">
                <div className="flex flex-column gap-2 w-6 md:w-6">
                  <label className="text-sm text-left checkoutlbl">
                    Pincode
                  </label>
                  <InputText
                    name="pincode" onChange={handleChange}
                    // placeholder="Pincode"
                    className="w-full h-3rem "                   defaultValue={data&& data.address? data.address.pincode:"" }

                  />
                </div>
                <div className="flex flex-column gap-2 w-6 md:w-6">
                  <label className="text-sm text-left checkoutlbl">
                    Mobile
                  </label>
                  <InputText
                    name="mobile" onChange={handleChange}
                    // placeholder="Mobile"
                    className="w-full h-3rem "                   defaultValue={data&& data.phone_number}
                  />
                </div>
              </div>
              <div className="flex gap-1 md:gap-7 mb-3">
                <div className="flex flex-column gap-2 w-6 md:w-6">
                  <label className="text-sm text-left checkoutlbl">
                    Gender
                  </label>
                  <InputText
                   name="gender" onChange={handleChange}
                    // placeholder="Pincode"
                    className="w-full h-3rem "                   defaultValue={data&& data.gender}

                  />
                </div>
                <div className="flex flex-column gap-2 w-6 md:w-6">
                  <label className="text-sm text-left checkoutlbl">
                    Age
                  </label>
                  <InputText
                   name="age" onChange={handleChange}
                    // placeholder="Mobile"
                    className="w-full h-3rem "                   defaultValue={data&& data.age}
                  />
                </div>
              </div>
            </div>
            {/* <h3 className="checkoutformhead text-left mb-2 md:mb-0" style={{fontSize:isSmallScreen?"16px":"",fontWeight:isSmallScreen?600:""}}>Links</h3>
            <div className="flex flex-column mb-3 gap-2 w-12 md:w-12">
              <label className="text-sm text-left checkoutlbl">
                Personal Website
              </label>
              <InputText
                name="firstName"
                // placeholder="Personal Website"
                className="w-full h-3rem "
              />
            </div>
            <div className="flex flex-column mb-3 gap-2 w-12 md:w-12">
              <label className="text-sm text-left checkoutlbl">Twitter</label>
              <InputText
                name="firstName"
                // placeholder="Twitter"
                className="w-full h-3rem "
              />
            </div>
            <div className="flex flex-column mb-3 gap-2 w-12 md:w-12">
              <label className="text-sm text-left checkoutlbl">Facebook</label>
              <InputText
                name="firstName"
                // placeholder="Facebook"
                className="w-full h-3rem "
              />
            </div> */}
             {loading?
              <Button style={{
                width: isSmallScreen ? "100%" : "126px",
                height: "50px",
                flexShrink: 0,
                borderRadius: "5px",
                border: "1px solid #888",
                background: "#0E8E60",
                justifyContent: "flex-end",
                marginTop:isSmallScreen?"15px":"25px",
                marginBottom:isSmallScreen?"20px":"20px"
              }}
               type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"white" }}></i>} />
             :      <Button
             label="Save"
             type="submit"
             style={{
               width: isSmallScreen ? "100%" : "126px",
               height: "50px",
               flexShrink: 0,
               borderRadius: "5px",
               border: "1px solid #888",
               background: "#0E8E60",
               justifyContent: "flex-end",
               marginTop:isSmallScreen?"15px":"25px",
               marginBottom:isSmallScreen?"20px":"20px"
             }}
           />
             }
            {/* <Button
              label="Save"
              type="submit"
              style={{
                width: isSmallScreen ? "100%" : "126px",
                height: "50px",
                flexShrink: 0,
                borderRadius: "5px",
                border: "1px solid #888",
                background: "#0E8E60",
                justifyContent: "flex-end",
                marginTop:isSmallScreen?"15px":"25px",
                marginBottom:isSmallScreen?"20px":"20px"
              }}
            /> */}
          </form>
        </div>
      </div>}
      <Footer />
    </>
  );
};

export default EditProfile;
