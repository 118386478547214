import React, { useState } from 'react'
import { Button } from 'primereact/button'
import bbcolor from "../../asserts/bbColor.png"
import self from "../../asserts/selflearning.png"
import online from "../../asserts/onlinelearning.png"
import offline from "../../asserts/offlinelearning.png"
import mainback from "../../asserts/mainBack3.png"
import "./PreHome.css"
import { useNavigate } from 'react-router-dom'

export default function PreHome() {
    const [flag,setFlag]=useState(0)
    const navigate=useNavigate()
  return (
   <>

   <div style={{
            backgroundImage: ` url(${mainback})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right ",
            backgroundSize: "100% ",
          }} className='hidden md:block pl-8 pr-8' >
   <div   className='flex justify-content-start align-items-center mt-5'><img src={bbcolor} className="h-2rem " alt="logo" /></div>
   <h1 className='phheading mt-6 mb-5' >“ Learn a technology in 3 different ways ”</h1>
   <div  className='flex justify-content-center align-items-center'>
                 <div className='mt-1'>
                    <div className='flex gap-4' >
                        <div className='imgdiv h-17rem w-4' ><img src={self} style={{borderRadius:"10px",height:"100%",width:"100%",objectFit:"cover"}}/></div>
                        <div className='imgdiv h-17rem w-4'><img src={online} style={{borderRadius:"10px",height:"100%",width:"100%",objectFit:"cover"}}/></div>
                        <div className='imgdiv h-17rem w-4'><img src={offline} style={{borderRadius:"10px",height:"100%",width:"100%",objectFit:"cover"}}/></div>
                    </div>
                    <span   className="p-buttonset w-full flex mt-3">
                <Button label="Self Learning"   className={`${flag===1?'redrctbutton1':'redrctbutton'} w-4`} style={{borderTopLeftRadius:"15px",borderBottomLeftRadius:"15px"}} onClick={()=>navigate("/self-learning")} onMouseEnter={()=>setFlag(1)} onMouseLeave={()=>setFlag(0)}/>
                <Button label="Online Live Classes" className={`${flag===2?'redrctbutton1':'redrctbutton'} w-4`} onMouseEnter={()=>setFlag(2)} onClick={()=>navigate("/online-classes")} onMouseLeave={()=>setFlag(0)}/>
                <Button label="Office Programs" className={`${flag===3?'redrctbutton1':'redrctbutton'} w-4`} style={{borderTopRightRadius:"15px",borderBottomRightRadius:"15px"}} onMouseEnter={()=>setFlag(3)} onMouseLeave={()=>setFlag(0)} onClick={()=>navigate("/office-classes")} />
            </span>
            <div className='flex gap-5 mt-3' >
                <div className='w-4 pl-6'>
                    <ul >
                        <li className='phlist'>Starts at Rs 499</li>
                        <li className='phlist'>Flexible Learning</li>
                        <li className='phlist'>Life time Validity</li>
                    </ul>
                </div>
                <div className='w-4 pl-5'>
                    <ul>
                        <li className='phlist'>Under Rs 65000</li>
                        <li className='phlist'>Interactive Sessions</li>
                        <li className='phlist'>More Exposure</li>
                    </ul>
                </div>
                <div className='w-4 pl-5'>
                    <ul>
                        <li className='phlist'>Under Rs 120000</li>
                        <li className='phlist'>Job Internship</li>
                        <li className='phlist'>Real-time Experience</li>
                    </ul>
                </div>
                </div>
                 </div>
   </div>
   </div>
   
   <div className='block md:hidden px-4'>
   <div   className='flex justify-content-start align-items-center mt-4 '><img src={bbcolor} className="h-2rem " alt="logo" /></div>
   <div style={{height:"70%"}} className='flex flex-column justify-content-center align-items-center pt-4'>
   <h1 className='phheading0 mt-8 mb-3' style={{whiteSpace:"nowrap"}} >“ Learn a technology in 3 different ways ”</h1>
   <div  className='flex justify-content-center align-items-center' >
                 <div className='mt-2'>
                    <div className='flex gap-2'>
                        <div className='imgdiv h-7rem w-4' ><img src={self} style={{borderRadius:"10px",height:"100%",width:"100%",objectFit:"cover"}}/></div>
                        <div className='imgdiv h-7rem w-4'><img src={online} style={{borderRadius:"10px",height:"100%",width:"100%",objectFit:"cover"}}/></div>
                        <div className='imgdiv h-7rem w-4'><img src={offline} style={{borderRadius:"10px",height:"100%",width:"100%",objectFit:"cover"}}/></div>
                    </div>
                    <span  className="p-buttonset w-full flex mt-2">
                <Button label="Self elearning"   className={`${flag===1?'redrctbutton1_0':'redrctbutton_0'} w-4`} style={{borderTopLeftRadius:"10px",borderBottomLeftRadius:"10px",whiteSpace:"nowrap"}} onClick={()=>navigate("/self-learning")} onMouseEnter={()=>setFlag(1)} onMouseLeave={()=>setFlag(0)}/>
                <Button label="Online Classes" className={`${flag===2?'redrctbutton1_0':'redrctbutton_0'} w-4`} onMouseEnter={()=>setFlag(2)} onClick={()=>navigate("/online-classes")} style={{whiteSpace:"nowrap"}} onMouseLeave={()=>setFlag(0)}/>
                <Button label="Office Programs" className={`${flag===3?'redrctbutton1_0':'redrctbutton_0'} w-4`} style={{borderTopRightRadius:"10px",borderBottomRightRadius:"10px",whiteSpace:"nowrap"}} onMouseEnter={()=>setFlag(3)} onClick={()=>navigate("/office-classes")} onMouseLeave={()=>setFlag(0)}/>
            </span>
                 </div>
   </div>
   <div className='flex gap-2 mt-3' >
                <div className='w-4 pl-4'>
                    <ul >
                        <li className='phlist0' style={{whiteSpace:"nowrap"}}>Starts at Rs 499</li>
                        <li className='phlist0' style={{whiteSpace:"nowrap"}}>Flexible Learning</li>
                        <li className='phlist0' style={{whiteSpace:"nowrap"}}>Life time Validity</li>
                    </ul>
                </div>
                <div className='w-4 pl-4'>
                    <ul>
                        <li className='phlist0'>Under Rs 65000</li>
                        <li className='phlist0' style={{whiteSpace:"nowrap"}}>Interactive Sessions</li>
                        <li className='phlist0'>More Exposure</li>
                    </ul>
                </div>
                <div className='w-4 pl-3'>
                    <ul>
                        <li className='phlist0'>Under Rs 120000</li>
                        <li className='phlist0'>Job Internship</li>
                        <li className='phlist0' style={{whiteSpace:"nowrap"}}>Real-time Experience</li>
                    </ul>
                </div>
                </div>
   </div>
   </div>
   
   
   </>
  )
}
