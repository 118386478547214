import './App.css';
import Navbar from './Component/navbar/navbar';
import Home from './Component/HomePage/Home';
import { Route, Routes } from 'react-router-dom';
import Course from './Component/Courses/Course';
import Individual from './Component/IndividualCourse/Individual';
import Cart from './Component/Cart/Cart';
import Checkout from './Component/Cart/CheckOut/Checkout';
import DynamicCourses from './Component/DynamicCourses/DynamicCourses';
import PreHome from './Component/PreHome/PreHome';
import OfflineHome from './OfflineComponent/HomePage/Home';
import OfflineCourse from './OfflineComponent/Courses/Course';
import OfflineIndividual from './OfflineComponent/IndividualCourse/Individual';
import OfflineDynamicCourses from './OfflineComponent/DynamicCourses/DynamicCourses';
import OfflineCart from './OfflineComponent/Cart/Cart'
import OfflineCheckout from './OfflineComponent/Cart/CheckOut/Checkout'
import OfflineBlog_Home from './OfflineComponent/HomeBlog/Blog_Home';
import OfflineCreateBlog from './OfflineComponent/createBlog/CreateBlog';
import OfflineAllBlogs from './OfflineComponent/AllBlogs/AllBlogs';
import SelfLearningPage from "./SelfComponent/pages/SelfLearningPage";
import OfflineContactUs from "./OfflineComponent/contactus/contact"
import OfflineMotivation from './OfflineComponent/motivationpage/motivation'
import OfflineReadBlog from './OfflineComponent/AllBlogs/ReadBlog/ReadBlog';

// import Main from "./pages/Main"; 
import SelfCourses from "./SelfComponent/pages/SelfCourses";
import BuyThisCoursePage from "./SelfComponent/pages/SelfBuyThisCoursePage";
import CoursePage from "./SelfComponent/pages/SelfCoursesPage";
import EditProfile from "./SelfComponent/components/SelfEditProfile";
import EditPhoto from "./SelfComponent/components/SelfEditPhoto";
import AddPayment from "./SelfComponent/components/SelfAddPayments";
import NewPaymentCard from "./SelfComponent/components/SelfNewPaymentsCard";
import MyLearning from "./SelfComponent/components/SelfMyLearning";
import SelfCart from "./SelfComponent/components/Cart/Cart";
import SelfCheckout from "./SelfComponent/components/Cart/SelfCheckOut/Checkout";
import Motivation from './Component/motivationpage/motivation';
import ContactUs from './Component/contactus/contact';
import Blog_Home from './Component/HomeBlog/Blog_Home';
import CreateBlog from './Component/createBlog/CreateBlog';
import ReadBlog from './Component/AllBlogs/ReadBlog/ReadBlog';
import AllBlogs from './Component/AllBlogs/AllBlogs';

import Self_Blog from "./SelfComponent/components/SelfBlog";
import SelfContactUs from "./SelfComponent/components/SelfContactUs";
import AboutUs from "./SelfComponent/components/AboutUs";
import SelfReadBlog from "./SelfComponent/components/SelfAllBlogs/SelfReadBlog/SelfReadBlog";
import SelfAllBlogs from "./SelfComponent/components/SelfAllBlogs/SelfAllBlogs";
import SelfDynamicCourses from "./SelfComponent/components/SelfDynamicCourses/SelfDynamicCourses";
import SelfRefundPolicy from './SelfComponent/components/refundpolicy/refundpolicy';
import SelfPrivacypolicy from './SelfComponent/components/privacypolicy/privacypolicy';
import SelfTermsandConditions from './SelfComponent/components/termandconditions/termsandconditions';
import OnlineRefundPolicy from './Component/refundpolicy/refundpolicy';
import OnlinePrivacypolicy from './Component/privacypolicy/privacypolicy';
import OnlineTermsandConditions from './Component/termandconditions/termsandconditions';
import OfflineRefundPolicy from './OfflineComponent/refundpolicy/refundpolicy';
import OfflinePrivacypolicy from './OfflineComponent/privacypolicy/privacypolicy';
import OfflineTermsandConditions from './OfflineComponent/termandconditions/termsandconditions';
import PrivateRoute from './SelfComponent/components/PrivateRoute/PrivateRoute';
import SelfChangePassword from './SelfComponent/components/ForgetPassword/ForgetPassword';
import UpdatePassword from './SelfComponent/components/ForgetPassword/UpdatePassowrd';
import SelfInstructor from './SelfComponent/components/Instructor/SelfInstructor';
import OfficeInstructor from './OfflineComponent/Instructor/SelfInstructor';
import OnlineInstructor from './Component/Instructor/SelfInstructor';
function App() {
  return (
    <div className="App">
      <Routes>
      <Route path='/' element={<PreHome/>}  />


      
        <Route path='/online-classes' element={<Home/>}  />
        <Route path='/online-classes/instructor-registration' element={<OnlineInstructor/>}  />
        <Route path='/online-classes/courses' element={<Course/>}  />
        <Route path='/online-classes/individual-courses/:id' element={<Individual/>}  />
        <Route path='/online-classes/cart' element={<Cart/>}  />
        <Route path='/online-classes/checkout' element={<Checkout/>}  />
        <Route path='/online-classes/categories/:course' element={<DynamicCourses/>} />
        <Route path='/online-classes/about-us' element={<Motivation/>} />
        <Route path='/online-classes/contact-us' element={<ContactUs/>} />
        <Route path='/online-classes/blog' element={<Blog_Home/>} />
        <Route path='/online-classes/create-blog' element={<CreateBlog/>} />
        <Route path='/online-classes/read-blog/:id' element={<ReadBlog/>} />
        <Route path='/online-classes/all-blogs' element={<AllBlogs/>} />
        <Route path='/online-classes/all-blogs/:category' element={<AllBlogs/>} />
        <Route path="/online-classes/refund-policy" element={<OnlineRefundPolicy />} />
        <Route path="/online-classes/privacy-policy" element={<OnlinePrivacypolicy />} />
        <Route path="/online-classes/terms-and-condition" element={<OnlineTermsandConditions />} />





      
      
        <Route path='/office-classes' element={<OfflineHome/>}  />
        <Route path='/office-classes/instructor-registration' element={<OfficeInstructor/>}  />
        <Route path='/office-classes/courses' element={<OfflineCourse/>}  />
        <Route path='/office-classes/individual-courses/:id' element={<OfflineIndividual/>}  />
        <Route path='/office-classes/cart' element={<OfflineCart/>}  />
        <Route path='/office-classes/checkout' element={<OfflineCheckout/>}  />
        <Route path='/office-classes/categories/:course' element={<OfflineDynamicCourses/>} />
        <Route path='/office-classes/about-us' element={<OfflineMotivation/>} />
        <Route path='/office-classes/contact-us' element={<OfflineContactUs/>} />
        <Route path='/office-classes/blog' element={<OfflineBlog_Home/>} />
        <Route path='/office-classes/create-blog' element={<OfflineCreateBlog/>} />
        <Route path='/office-classes/read-blog/:id' element={<OfflineReadBlog/>} />
        <Route path='/office-classes/all-blogs' element={<OfflineAllBlogs/>} />
        <Route path='/office-classes/all-blogs/:category' element={<OfflineAllBlogs/>} />
        <Route path="/office-classes/refund-policy" element={<OfflineRefundPolicy />} />
        <Route path="/office-classes/privacy-policy" element={<OfflinePrivacypolicy />} />
        <Route path="/office-classes/terms-and-condition" element={<OfflineTermsandConditions />} />





        <Route path="/self-learning/courses" element={<SelfCourses />} />
        <Route path="/self-learning" element={<SelfLearningPage />} />
        <Route path="/self-learning/selfbuythiscourse/:id" element={<BuyThisCoursePage />} />
        <Route path="/self-learning/course-page/:id" element={<PrivateRoute><CoursePage /></PrivateRoute>} />
        <Route path="/self-learning/edit-profile" element={<EditProfile />}/>
        <Route path="/self-learning/edit-photo" element={<EditPhoto />} />
        <Route path="/self-learning/add-payments"element={<AddPayment />} />
        <Route path="/self-learning/newpaymentcard" element={<NewPaymentCard />} />
        <Route path="/self-learning/mylearning" element={<PrivateRoute><MyLearning /></PrivateRoute>} />
        <Route path="/self-learning/cart" element={<SelfCart />} />
        <Route path="/self-learning/checkout" element={<SelfCheckout />} />
        <Route path="/self-learning/blog" element={<Self_Blog />} />
        <Route path="/self-learning/contact-us" element={<SelfContactUs />} />
        <Route path="/self-learning/about-us" element={<AboutUs />} />
        <Route path="/self-learning/refund-policy" element={<SelfRefundPolicy />} />
        <Route path="/self-learning/privacy-policy" element={<SelfPrivacypolicy />} />
        <Route path="/self-learning/terms-and-condition" element={<SelfTermsandConditions />} />
        <Route path='/self-learning/change-password/*' element={<SelfChangePassword/>} />
        <Route path='/self-learning/update-password' element={<UpdatePassword/>} />
        <Route path="/self-learning/read-blog/:id" element={<SelfReadBlog />} />
        <Route path="/self-learning/all-blogs" element={<SelfAllBlogs />} />
        <Route path="/self-learning/all-blogs/:category"  element={<SelfAllBlogs />} />
        <Route path="/self-learning/categories/:course"  element={<SelfDynamicCourses />} />
        <Route path="/self-learning/instructor-registration"  element={<SelfInstructor />} />

      </Routes>
    </div>
  );
}

export default App;
