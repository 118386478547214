import { INDIVIDUALCOURSEDETAILS_REQUEST, INDIVIDUALCOURSEDETAILS_SUCCESS, OPDYNAMICCOURSES_SUCCESS } from "../OfflineTypes/offlinecourse.types";

  
//   let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
  const initialState = {
    loading: false,
    error: false,
    message: "",
    dynamicCourses:[],
    IndividualCourseData:[]
  };
  
  export const OfflineCourseReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case INDIVIDUALCOURSEDETAILS_REQUEST:{
      return{
        ...state,loading:true
      }
    }
    case INDIVIDUALCOURSEDETAILS_SUCCESS:{
      return {
        ...state,IndividualCourseData:payload,loading:false
      }
    }
    case OPDYNAMICCOURSES_SUCCESS:{
      return{
        ...state,dynamicCourses:payload
      }
     }
      default:
        return state;
    }
  };
  