import React, { useEffect, useState } from "react";
import "./AccordionStyles.css";
import { Accordion, AccordionTab } from "primereact/accordion";

const Accordians = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1030);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1030);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    // <div className="app">
    //   <Accordion
    //     title="1. What services does BrainyBits?"
    //     content="Content for Accordion 1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb
    //      1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb
    //      "
    //   />
    //   <Accordion
    //     title="2. Why should i choose a BrainyBits ?"
    //     content="Content for Accordion 2 goes here.
    //     Content for Accordion 1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb
    //      1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb"
    //   />
    //   <Accordion
    //     title="3. What are the different types of Learning Courses available ?"
    //     content="Content for Accordion 3 goes here.
    //     Content for Accordion 1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb
    //      1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb"
    //   />
    //   <Accordion
    //     title="4. What are my prerequisites to learn over BrainyBits?"
    //     content="Content for Accordion 4 goes here.
    //     Content for Accordion 1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb
    //      1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb"
    //   />
    //   <Accordion
    //     title="5. Why only BrainyBits?"
    //     content="Content for Accordion 5 goes here.
    //     Content for Accordion 1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb
    //      1 goes here. Lorem ipsum paragraph kjasnxmsnkxbasbckbsdcbskdbckjs
    //      kjdncan ksjdbckjdsbjc bskc scjdbskjcnsdbkjbkjdsbcLASJKDCnladjcnjdn kzxbzxckjb"
    //   />
    // </div>
    <div
      className="pr-4 md:pr-1"
      style={{
        paddingLeft: isSmallScreen ? 0 : "5rem !important",
      }}
    >
      <div className="w-11 hidden md:flex flex-column">
        <div className="card">
          <Accordion
            className="border-round-lg accordion-containeroffline "
            expandIcon="pi pi-plus custom-expand-iconoffline"
            collapseIcon="pi pi-minus custom-expand-iconoffline"
          >
            <AccordionTab
              header={
                <span className="acrhdroffline flex text-left">
                  1. Which services BrainyBits provides?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans">BrainyBits provides three different kind of learning modules. Self-learning resources, online live classes, Additionally, we offer office job-oriented internship learning programs. Allowing learners to access all kinds of software technology learning courses in various fields.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline flex text-left">
                  2. Why should I choose BrainyBits?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans">BrainyBits offers the convenience of learning from anywhere with an internet connection, expert-led courses, and internship programs that prepare you for real-world job opportunities. Our platform is designed to provide a rich and effective learning experience.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline flex text-left">
                  3. What are the Learning Courses available ?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans">BrainyBits offers a wide range of learning courses, including but not limited to IT Software, Data Science, Artificial Intelligence, Design and more. We cover diverse topics to cater to your learning needs. Find more details in courses page of each module of learning.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline flex text-left">
                  4. What are my prerequisites to learn over BrainyBits platform?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans">Prerequisites may vary depending on the specific course you choose. However, for most courses, a basic understanding of English language and mobile/computer device is beneficial. Detailed prerequisites are usually provided in the course descriptions.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline flex text-left">
                  5. Why only BrainyBits?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans">BrainyBits stands out due to its comprehensive course offerings, expert instructors, flexible learning options, and office job-oriented internship programs. We are committed to delivering high-quality education and helping you acquire practical skills for success in your chosen field. Choose BrainyBits for a rewarding learning experience.</p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
      <div className="w-12 flex md:hidden flex-column">
        <div className="card">
          <Accordion
            className="border-round-lg accordion-containeroffline "
            expandIcon="pi pi-plus custom-expand-iconoffline"
            collapseIcon="pi pi-minus custom-expand-iconoffline"
          >
            <AccordionTab
              header={
                <span className="acrhdroffline0 flex text-left">
                  1. Which services BrainyBits provides?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans0">BrainyBits provides three different kind of learning modules. Self-learning resources, online live classes, Additionally, we offer office job-oriented internship learning programs. Allowing learners to access all kinds of software technology learning courses in various fields.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline0 flex text-left">
                  2. Why should I choose BrainyBits?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans0">BrainyBits offers the convenience of learning from anywhere with an internet connection, expert-led courses, and internship programs that prepare you for real-world job opportunities. Our platform is designed to provide a rich and effective learning experience.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline0 w-11 flex text-left">
                  3. What are the Learning Courses available ?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans0">BrainyBits offers a wide range of learning courses, including but not limited to IT Software, Data Science, Artificial Intelligence, Design and more. We cover diverse topics to cater to your learning needs. Find more details in courses page of each module of learning.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline0 w-11 flex text-left">
                  4. What are my prerequisites to learn over BrainyBits platform?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans0">Prerequisites may vary depending on the specific course you choose. However, for most courses, a basic understanding of English language and mobile/computer device is beneficial. Detailed prerequisites are usually provided in the course descriptions.</p>
            </AccordionTab>
            <AccordionTab
              header={
                <span className="acrhdroffline0 flex text-left">
                  5. Why only BrainyBits?
                </span>
              }
              className=" accordion-taboffline "
            >
              <p className="qsans0">BrainyBits stands out due to its comprehensive course offerings, expert instructors, flexible learning options, and office job-oriented internship programs. We are committed to delivering high-quality education and helping you acquire practical skills for success in your chosen field. Choose BrainyBits for a rewarding learning experience.</p>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Accordians;
