import axios from "axios";
import { DynamicCourseApi, environment, fetchCourseDetailsApi, fetchCourseListApi, individualCourseDetailsApi } from "../../../Api";
import { COURSEDETAILS_SUCCESS, COURSELIST_SUCCESS, DYNAMICCOURSES_SUCCESS, INDIVIDUALCOURSEDETAILS_REQUEST, INDIVIDUALCOURSEDETAILS_SUCCESS } from "../SelfTypes/Course.types";


export const fetchCourseList=()=>async(dispatch)=>{
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
const payload={
    environment: environment,
    user_id: brainystudenttoken&&brainystudenttoken.user_id
}
    try{
let response=await axios.post(fetchCourseListApi,payload)
console.log(response.data)
dispatch({type:COURSELIST_SUCCESS,payload:response.data})
    }catch(e){
console.log(e)
    }
}

export const fetchCourseDetails=(id)=>async(dispatch)=>{
    let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
const payload={
    environment: environment,
    user_id: brainystudenttoken&&brainystudenttoken.user_id,
    course_id:id
}
console.log(id,payload);
    try{
let response=await axios.post(fetchCourseDetailsApi,payload)
console.log(response.data)
dispatch({type:COURSEDETAILS_SUCCESS,payload:response.data})
    }catch(e){
console.log(e)
    }
}

export const IndividualFetchCourse=(id)=>async(dispatch)=>{
    dispatch({type:INDIVIDUALCOURSEDETAILS_REQUEST})
    const payload={
        environment: environment,
        course_id: id
    }
    console.log(id,payload)
    try{
     let response=await axios.post(individualCourseDetailsApi,payload)
     console.log(response.data)
     dispatch({type:INDIVIDUALCOURSEDETAILS_SUCCESS,payload:response.data})
    }
    catch(e){
        console.log(e);
    }
}

export const FetchDynamicCourse=(param)=>async(dispatch)=>{
    const payload={
    category: param
}
    try{
        let response=await axios.post(DynamicCourseApi,payload)

        dispatch({type:DYNAMICCOURSES_SUCCESS,payload:response.data})
    }
    catch(e){

    }
}