import React, { useEffect, useState } from "react";
import Navbar from "../navbar/navbar";
import "./Cart.css";
import Rectangle from "../../asserts/Rectanglerobot.png";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import ranjith from "../../asserts/Ranjith.png";
import Rectanglerobot from "../../asserts/Rectanglerobot.png";
import Artificialinteligence from "../../asserts/Artificialinteligence.png";
import RectangleDeepLearning from "../../asserts/RectangleDeepLearning.png";
import RectangleMechineLearning from "../../asserts/Test_Automation.png";
import Signup from "../Signup/Signup";
import { useDispatch, useSelector } from "react-redux";
import { FetchCartFunction, RemoveCart } from "../../Redux/SelfLearning/SelfAction/Cart.action";
import emptycart from "../../asserts/EmptyCart.png"
import brain from "../../brain.gif"
import axios from "axios";
import { onlinefetchbatchaccrdtocourseApi } from "../../Api";



export default function Cart() {
  const navigate = useNavigate();
  const [flag,SetFlag]=useState(false)
  const {token,isAuth}=useSelector(store=>store.auth)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
const handleChech=()=>{
  if(isAuth){
    SetFlag(false)
    navigate("/online-classes/checkout")
  }
SetFlag(true)
}
const dispatch=useDispatch()
const {data,loading}=useSelector(store=>store.cart)
useEffect(()=>{
dispatch(FetchCartFunction("OL"))
},[])
// console.log("cart",data)
const [batch,setBatch]=useState([])
const getBatch=async(data)=>{
  console.log(data);
try{
  let resp=await axios.post(onlinefetchbatchaccrdtocourseApi,{
    "course_id": data.cart.course_id,
    "course_name": data.cart.course_name
})
  console.log(resp.data);
  if(resp.data[0]!=undefined){ 
    setBatch([{name:resp.data[0],code:resp.data[0]}])
  }else{
    setBatch([]);

    }
  // SetBatch_list({name})
}
catch(e){
  console.log(e);
}
}
useEffect(()=>{
getBatch(data)
},[data])
const HandleRemove=async()=>{
  console.log(data&&data.cart&&data.cart.course_id)
  await dispatch(RemoveCart(data&&data.cart&&data.cart.course_id,"OL"))
  dispatch(FetchCartFunction("OL"))

}

  return (
    <div className="w-full h-full">
    <Signup flag={flag} SetFlag={SetFlag} batch={batch}/>
    <Navbar />
    { loading? 
     <div className="hidden md:flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
    data&&data.cart?
    <div className="hidden md:block p-8">
<div className="w-full   flex align-items-end " >
<div className="w-9 ">
<div>
<h1 className="shop ml-3 mb-3 text-left">
  Shopping <span className="crt">Cart</span>
</h1>

<h3 className="quantity mt-2 mb-3 ml-3 text-left">1 Course in Cart</h3>
{/* <hr className="line mb-2" /> */}
<div className="itemContainer flex  justify-content-between align-items-center py-3 forborder" style={{borderRight:"0px",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}}>
  <div className="w-2  border-round-lg ml-3 " >
  <img
                      src={data && data.cart && data.cart.course_image}
                      className="imgborder"
                      style={{ objectFit: "contain" }}
                      width="100%"
                      height="100%"
                    />  </div>
  <div className="w-7  flex flex-column   ">
    {/* <h3 className="nametutor text-left my-1">{data&&data.cart&&data.cart.instructor_name}</h3> */}
    <h2 className="namecourse text-left my-0">
    {data&&data.cart&&data.cart.course_name}
    </h2>
    <h3 className="text-left icn flex gap-1 my-0">
      <i
        style={{ color:`${data&&data.cart&&data.cart.rating>=1?"#0E8E60":"#E0E0E0"}` , fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: `${data&&data.cart&&data.cart.rating>=2?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: `${data&&data.cart&&data.cart.rating>=3?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color:`${data&&data.cart&&data.cart.rating>=4?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: `${data&&data.cart&&data.cart.rating>=5?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      ({data&&data.cart&&data.cart.num_of_reviews})
    </h3>
    <h2 className="pricecourse text-left mt-1">₹ {data&&data.cart&&data.cart.amount}</h2>
  </div>
  <div className="w-2 h-full flex flex-column justify-content-center  pt-3 pb-4 gap-2">
    <h3 className="rmv text-right cursor-pointer" onClick={HandleRemove}>Remove</h3>
    <h3 className="rmv text-right cursor-pointer">Save for Later</h3>
  </div>
</div>
{/* <hr className="line mt-2" /> */}

</div>
</div>
<div className="w-3 pl-8  h-10rem flex flex-column justify-content-center align-items-start forborder" style={{borderLeft:"0px",borderTopRightRadius:"20px",borderBottomRightRadius:"20px"}}>
{/* <hr className="line mt-2" /> */}

<h1 className="totl text-left   mb-3">
  Total : <span>₹ {data&&data.cart&&data.cart.amount}</span>
</h1>
{/* <hr className="line mt-2" /> */}

<Button
  className="checkbtn mb-1 "
  // onClick={() => navigate("/slef-learning/checkout")}
  onClick={()=>handleChech()}
>
  Checkout
</Button>
</div>
</div>
{/* <div>
<h1 className="shop text-left">
  Shopping <span className="crt">Cart</span>
</h1>
<br />
<h3 className="quantity mt-4 text-left">1 Course in Cart</h3>
<hr className="line mb-2" />
<div className="itemContainer flex  justify-content-between align-items-center">
  <div className="w-2 h-full border-round-lg ml-3">
    <Image src={Rectangle} width="100%" />
  </div>
  <div className="w-7  flex flex-column   ">
    <h3 className="nametutor text-left my-0">Ranjith Kumar</h3>
    <h2 className="namecourse text-left my-0">
      Master’s Program in Data Science & AI
    </h2>
    <h3 className="text-left icn flex gap-1 my-0">
      <i
        style={{ color: "#0E8E60", fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: "#0E8E60", fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: "#0E8E60", fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: "#0E8E60", fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      <i
        style={{ color: "#E0E0E0", fontSize: "0.65rem" }}
        className="pi pi-star-fill  "
      ></i>
      (231)
    </h3>
    <h2 className="pricecourse text-left mt-3">₹ 40,000</h2>
  </div>
  <div className="w-2 h-full flex flex-column justify-content-between pt-4 pb-4">
    <h3 className="rmv text-right">Remove</h3>
    <h3 className="rmv text-right">Save for Later</h3>
  </div>
</div>
<hr className="line mt-2" />

<h1 className="totl text-left mt-3 mb-3">
  Total : <span>₹ 40,000</span>
</h1>
<Button
  className="checkbtn "
  // onClick={() => navigate("/slef-learning/checkout")}
  onClick={()=>handleChech()}
>
  Checkout
</Button>
</div> */}
<div className='w-full pl-4 pr-2 mt-6 mb-5'>
{data&&data.you_might_like? <h3 className='individualcrslhdr text-left mt-2'>You Might also <span className='individualcrslhdr2'>Like</span></h3>:""}
{data&&data.you_might_like? <Splide hasTrack={ false } className='sdbox pl-4 pr-4'  options={{
  // type: 'loop',
  rewind:false, 
  autoplay: true,
  perMove: 1,
  perPage: 4, 
  gap: '0rem',
  arrows: true,
  pagination: true
 
}}
> 
<SplideTrack>

{data&&data.you_might_like&&data.you_might_like.map((el)=>(
<SplideSlide>
<div  key={el.course_id} className='tabcrslbox w-11 p-3 flex flex-column'>
             <div  className='flex h-2rem align-items-center gap-2'><img src={el.profile_photo_link} style={{borderRadius:"50%",height:"100%",objectFit:"cover"}} /><span className='tabcrslname'>{el.full_name}</span></div>
             <div className='h-12rem pt-2'><img src={el.image} style={{height:"100%",width:"100%",borderRadius:"10px",objectFit:"cover"}} /></div>
             <span className='tabcrslcoursename pt-2'>{el.course_name}</span>
             <svg xmlns="http://www.w3.org/2000/svg" className='mt-1' width="55" height="11" viewBox="0 0 55 11" fill="none">
               <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill={el.rating>=1?"#0E8E60":'#E0E0E0'}/>
               <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={el.rating>=2?"#0E8E60":'#E0E0E0'}/>
               <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={el.rating>=3?"#0E8E60":'#E0E0E0'}/>
               <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={el.rating>=4?"#0E8E60":'#E0E0E0'}/>
               <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={el.rating>=5?"#0E8E60":'#E0E0E0'}/>
             </svg>
             <div className='flex justify-content-between align-items-center'><span className='tabprice'>₹ {el.course_cost}</span><Button className='tabbuybtn'>Buy now</Button></div>
          </div></SplideSlide>
))}
</SplideTrack>


<div className="splide__arrows">
<button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-4"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
<button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-1"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
</div>
</Splide>:""}
</div>
</div>
    :<div className="hidden md:block pl-8 pr-8">
      <h1 className="shop mt-5 ml-3 mb-3 text-left"> Shopping <span className="crt">Cart</span></h1><Image  src={emptycart} width="45%" className="hidden md:block" /></div>


  
}

{ loading? 
       <div className="flex justify-content-center align-items-center h-25rem"><img src={brain} width={80} className="md:hidden m-auto"   ></img></div>:
      data&&data.cart?
      <div className="block md:hidden p-2 pl-4 pr-4  pt-6 h-full">
        <div className="h-30rem">
          <h1 className="shop0 mb-0 mt-0 text-left">
            Shopping <span className="crt0">Cart</span>
          </h1>
          <h3 className="quantity0 mt-1  mb-0 text-left">1 Course in Cart</h3>
          {/* <hr className="line" /> */}
          <div
              style={{ height: "135px" }}
              className="itemContainer flex pt-3 pb-3 gap-2 justify-content-between align-items-center w-full"
            >
              <div className="w-5 h-full border-round-lg smallcrtimgbox">
              <img
                  src={data && data.cart && data.cart.course_image}
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "12px",objectFit:"contain" }}
                />
              </div>
              <div className="w-7 h-full flex flex-column pt-2 pl-1">
                {/* <h3 className="nametutor0 text-left">
                  {data && data.cart && data.cart.instructor_name}
                </h3> */}
                <h2
                  className="namecourse0 text-left w-10"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {data && data.cart && data.cart.course_name}
                </h2>
                <div className="w-full flex justify-content-between align-items-center">
                  <div className="w-6 ">
                    <h3 className="text-left my-2 icn0 flex gap-1">
                      <i
                        style={{
                          color: `${
                            data && data.cart && data.cart.rating >= 1
                              ? "#0E8E60"
                              : "#E0E0E0"
                          }`,
                          fontSize: "0.65rem",
                        }}
                        className="pi pi-star-fill  "
                      ></i>
                      <i
                        style={{
                          color: `${
                            data && data.cart && data.cart.rating >= 2
                              ? "#0E8E60"
                              : "#E0E0E0"
                          }`,
                          fontSize: "0.65rem",
                        }}
                        className="pi pi-star-fill  "
                      ></i>
                      <i
                        style={{
                          color: `${
                            data && data.cart && data.cart.rating >= 3
                              ? "#0E8E60"
                              : "#E0E0E0"
                          }`,
                          fontSize: "0.65rem",
                        }}
                        className="pi pi-star-fill  "
                      ></i>
                      <i
                        style={{
                          color: `${
                            data && data.cart && data.cart.rating >= 4
                              ? "#0E8E60"
                              : "#E0E0E0"
                          }`,
                          fontSize: "0.65rem",
                        }}
                        className="pi pi-star-fill  "
                      ></i>
                      <i
                        style={{
                          color: `${
                            data && data.cart && data.cart.rating >= 5
                              ? "#0E8E60"
                              : "#E0E0E0"
                          }`,
                          fontSize: "0.65rem",
                        }}
                        className="pi pi-star-fill  "
                      ></i>
                      ({data && data.cart && data.cart.num_of_reviews})
                    </h3>
                    <h2 className="pricecourse0 text-left ">
                      ₹ {data && data.cart && data.cart.amount}
                    </h2>
                  </div>
                  <div className="w-6 flex align-self-end justify-content-center">
                    <h3
                      className="rmv0 text-center mt-3 "
                      onClick={HandleRemove}
                    >
                      Remove
                    </h3>
                  </div>
                </div>
              </div>
              {/* <div className="w-2 h-full flex flex-column justify-content-between pt-4 pb-4">
              <h3 className="rmv0 text-right " onClick={HandleRemove}>Remove</h3>
              <h3 className="rmv0 text-right">Save for Later</h3>
            </div> */}
            </div>
          {/* <hr className="line" /> */}

          <h1 className="totl0 text-center mt-0 mb-3">
            Total : <span>₹ {data&&data.cart&&data.cart.amount}</span>
          </h1>
          <Button
            className="checkbtn0 w-6"
            // onClick={() => navigate("/slef-learning/checkout")}
            onClick={()=>handleChech()}

          >
            Checkout
          </Button>
        </div>
        {data&&data.you_might_like&&    <div className='w-full  mt-6 mb-5'>
    <h3 className='individualcrslhdr0 text-left mt-2'>You Might also <span className='individualcrslhdr20'>Like</span></h3>
    <Splide hasTrack={ false } className='sdbox pl-4 pr-4'  options={{
            // type: 'loop',
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 1, 
            gap: '0rem',
            arrows: true,
            pagination: true
           
         }}
        > 
          <SplideTrack>

        {data&&data.you_might_like&&data.you_might_like.map((el)=>(
 <SplideSlide>
<div  key={el.course_id} className='tabcrslbox w-11 p-3 flex flex-column'>
                       <div  className='flex h-2rem align-items-center gap-2'><img src={el.profile_photo_link} style={{borderRadius:"50%",height:"100%",objectFit:"cover"}} /><span className='tabcrslname'>{el.full_name}</span></div>
                       <div className='h-12rem pt-2'><img src={el.image} style={{height:"100%",width:"100%",borderRadius:"10px",objectFit:"cover"}} /></div>
                       <span className='tabcrslcoursename pt-2'>{el.course_name}</span>
                       <svg xmlns="http://www.w3.org/2000/svg" className='mt-1' width="55" height="11" viewBox="0 0 55 11" fill="none">
                       <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.rating)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.rating)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.rating)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.rating)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.rating)>4?"#0E8E60":"#E0E0E0"}/>
                      </svg>
                       <div className='flex justify-content-between align-items-center'><span className='tabprice'>{el.course_cost}</span><Button className='tabbuybtn'>Buy now</Button></div>
                    </div></SplideSlide>
        ))}
        </SplideTrack>

        {/* <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -ml-3 nextbtn"><svg xmlns="http://www.w3.org/2000/svg"   className='h-3rem w-15rem' width="400px" height="400px" viewBox="0 0 50 50" fill="none">
  <path d="M25.1175 31.4306L28.3311 34.6443L37.9766 25.0011L28.3334 15.3579L25.1197 18.5738L29.272 22.7284H12.1425V27.2738H29.272L25.1175 31.4306Z" fill="#0E8E60"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.6773 42.6773C52.4409 32.9136 52.4409 17.0864 42.6773 7.32273C32.9136 -2.44091 17.0864 -2.44091 7.32273 7.32273C-2.44091 17.0864 -2.44091 32.9136 7.32273 42.6773C17.0864 52.4409 32.9136 52.4409 42.6773 42.6773ZM39.4636 39.4636C43.2996 35.6276 45.4547 30.4249 45.4547 25C45.4547 19.5751 43.2996 14.3724 39.4636 10.5364C35.6276 6.70037 30.4249 4.54533 25 4.54533C19.5751 4.54533 14.3724 6.70037 10.5364 10.5364C6.70037 14.3724 4.54533 19.5751 4.54533 25C4.54533 30.4249 6.70037 35.6276 10.5364 39.4636C14.3724 43.2996 19.5751 45.4547 25 45.4547C30.4249 45.4547 35.6276 43.2996 39.4636 39.4636Z" fill="#0E8E60"/>
</svg></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mr-3 nextbtn"><svg xmlns="http://www.w3.org/2000/svg"  className='h-3rem w-15rem' width="400px" height="400px" viewBox="0 0 50 50" fill="none">
  <path d="M25.1175 31.4306L28.3311 34.6443L37.9766 25.0011L28.3334 15.3579L25.1197 18.5738L29.272 22.7284H12.1425V27.2738H29.272L25.1175 31.4306Z" fill="#0E8E60"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.6773 42.6773C52.4409 32.9136 52.4409 17.0864 42.6773 7.32273C32.9136 -2.44091 17.0864 -2.44091 7.32273 7.32273C-2.44091 17.0864 -2.44091 32.9136 7.32273 42.6773C17.0864 52.4409 32.9136 52.4409 42.6773 42.6773ZM39.4636 39.4636C43.2996 35.6276 45.4547 30.4249 45.4547 25C45.4547 19.5751 43.2996 14.3724 39.4636 10.5364C35.6276 6.70037 30.4249 4.54533 25 4.54533C19.5751 4.54533 14.3724 6.70037 10.5364 10.5364C6.70037 14.3724 4.54533 19.5751 4.54533 25C4.54533 30.4249 6.70037 35.6276 10.5364 39.4636C14.3724 43.2996 19.5751 45.4547 25 45.4547C30.4249 45.4547 35.6276 43.2996 39.4636 39.4636Z" fill="#0E8E60"/>
</svg></button>
  </div> */}
  <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-4"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-1"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
    </div>}
      </div>
    :<div className=" md:hidden pl-2 pr-2">
    <h1 className="shop mt-5 ml-3 mb-3 text-left"> Shopping <span className="crt">Cart</span></h1><Image  src={emptycart} width="95%" className="block md:hidden" /></div>


}
    <Footer />
  </div>
  ); 
}
