import React, { useEffect, useState } from "react";
import Navbar from "../SelfNavbar";
import Footer from "../Foot";
import MyLearningCards from "../SelfMylearningCards";
const MyLearning = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 876);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 876);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <Navbar />
      {/* <div className="p-4 md:pl-8 md:pt-5 md:pb-5"
        style={{
          display: "flex",
          width: "100%",
          // height: isSmallScreen ? "100px" : "198px",
          flexShrink: 0,
          background: "#0E8E60",
          margin: "2% 0 0 0",
          alignContent: "center",
          // border:"1px solid red"
        }}
      >
        <p className=" md:flex md:align-items-center md:justify-content-started"
          style={{
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: isSmallScreen ? "24px" : "48px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            // marginLeft: "5%",
            // marginTop: "4%",
          }}
        >
          My Learning
        </p>
      </div> */}
      <div  className="md:pl-8 md:py-5">
      <p
        className="p-4 text-left "
        style={{
          color: "#000",
          fontFamily: "Poppins",
          fontSize: isSmallScreen ? "16px" :"32px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          // border:"1px solid red"
        }}
      >
        My{" "}
        <span
          style={{
            color: "#0E8E60",
          }}
        >
          Learning
        </span>
      </p>
      <div  className="   flex flex-wrap w-full md:w-11  m-auto  pl-4 md:pl-8 pr-4 md:pr-6 gap-2 ">
        <MyLearningCards />
      </div>
      </div>
      <Footer />
    </>
  );
};

export default MyLearning;
