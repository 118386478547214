import React, { useState, useEffect } from "react";
import img from "../../../utils/selfpageimgs/selfmain.png";
// import "../Selftop/selftop.css";
import "./selflearntop.css";
import { useNavigate } from "react-router-dom";

const SelfLearningTop = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1030);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1030);
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const whenClicked = () => {
    navigate("/self-learning/courses");
  };
  return (
    <>
    <div className="w-full px-8 md:flex hidden pt-4" >
   <div  className="w-7 flex flex-column pl-1 pt-3">
   <p className="text-left"
            style={{
              color: "#0E8E60",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "145%",
            }}
          >Learning Technologies in Digital Era
          </p>
          <h1
              className="text-left mt-3"
              style={{
                color: "#000",
                fontFamily: "Inter",
                fontSize:  "48px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "115%",
              }}
            >
              Find Our Best 
              <span
                style={{
                  color: "#0E8E60",
                  fontFamily: "Inter",
                  fontSize: "48px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "115%",
                }}
              > Self Learning  <br /> Courses </span> and Learn More
            </h1>
            <p
            className="text-left my-4"
            style={{
              color: "rgba(0, 0, 0, 0.70)",
              fontFamily: "Inter",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "145%",
             
            }}
          >
            We believe in empowering individuals with the{" "}
            <br />
            knowledge and skills they need to become lifelong{" "}
           <br />
            learners. Our self-learning website offers a diverse{" "}
            <br />
            range of high-quality, interactive courses.
          </p>
          <button
            className={`text-left mt-3`}
            style={{
              width:  "170px",
              height: "50px",
              flexShrink: 0,
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize:  "16px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
              backgroundColor: "#0E8E60",
              border: "1px solid #0E8E60",
              borderRadius: "30px",
              cursor: "pointer",
              display:"flex",
              justifyContent:"center",
              alignItems:"center"
            }}
            onClick={whenClicked}
          >
            Explore Courses
          </button>
   </div>
   <div  className="slimgdiv w-5">
<img src={img}   />
</div>
    </div>
    



        <div className="w-full px-2 flex flex-column md:hidden gap-3 pt-3 " >
        <div  className="slimgdiv w-12">
<img src={img}   />
</div>
   <div  className="w-full flex flex-column pl-1" >
   <p className="text-center"
            style={{
              color: "#0E8E60",
              fontFamily: "Inter",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "125%",
            }}
          >Learning Technologies in Digital Era
          </p>
          <h1
              className="text-center mt-2"
              style={{
                color: "#000",
                fontFamily: "Inter",
                fontSize:  "18px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "115%",
              }}
            >
              Find Our best <span
                style={{
                  color: "#0E8E60",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "115%",
                }}
              >Self Learning  <br /> Courses  </span> and Learn More </h1>
            <p
            className="text-center my-2"
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "145%",
             
            }}
          >
            We believe in empowering individuals with the{" "}
            <br />
            knowledge and skills they need to become lifelong{" "}
           <br />
            learners. Our self-learning website offers a diverse{" "}
            <br />
            range of high-quality, interactive courses.
          </p>
          <button
            className={`text-left mt-1`}
            style={{
              width:  "170px",
              height: "50px",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize:  "12px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
              backgroundColor: "#0E8E60",
              border: "1px solid #0E8E60",
              borderRadius: "30px",
              cursor: "pointer",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              margin:"auto"
            }}
            onClick={whenClicked}
          >
            Explore Courses
          </button>
   </div>
  
    </div>
    </>
  );
};

export default SelfLearningTop;
