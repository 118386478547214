import React, { useEffect, useState } from "react";
import Navbar from "../SelfNavbar";
import Footer from "../Foot";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const AddPayment = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 876);

  useEffect(() => {
    // Function to update isSmallScreen when the window is resized
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 876);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const listStyles = {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    listStyle: "none",
    textDecoration: "none",
    paddingLeft: "5%",
    marginRight: "5%",
  };

  return (
    <>
      <Navbar />
      <div
        className="fle "
        style={{
          display: isSmallScreen ? "" : "flex",
          justifyContent: isSmallScreen ? "center" : "flex-start",
          alignItems: "flex-start",
          marginTop: "6%",
          gap: "6%",
        }}
      >
        <div
          className="c"
          style={{
            width: isSmallScreen ? "100%" : "306px",
            height: "492px",
            flexShrink: 0,
            marginLeft: isSmallScreen ? "" : "5%",
            borderRadius: "10px",
            border: isSmallScreen ? "none" : "1px solid #FFF",
            background: "#FFF",
            boxShadow: isSmallScreen
              ? "none"
              : "0px 1px 4px 2px rgba(0, 176, 90, 0.15)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "7%",
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Selected Profile"
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              />
            ) : (
              <label htmlFor="imageUpload">
                <Button
                  className=" flex align-items-center "
                  icon="pi pi-user"
                  style={{
                    width: "100px",
                    height: "100px",
                    flexShrink: 0,
                    fill: "#0E8E60",
                    backgroundColor: "#0E8E60",
                    border: "none",
                    borderRadius: "50%",
                  }}
                />
              </label>
            )}
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <div>
              <p
                style={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                Sarathi
              </p>
            </div>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "50px",
            }}
          >
            <Link
              to="/self-learning/edit-profile"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="text-left" style={listStyles}>
                Profile
              </li>
            </Link>
            <Link
              to="/self-learning/edit-photo"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="text-left" style={listStyles}>
                Photo
              </li>
            </Link>
            <Link
              to="/self-learning/add-payments"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li
                className="text-left"
                style={{
                  color: isSmallScreen ? "#fff" : "#0E8E60",
                  backgroundColor: isSmallScreen ? "#0E8E60" : "#fff",
                  paddingLeft: "5%",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  listStyle: "none",
                  textDecoration: "none",
                  marginRight: "5%",
                }}
              >
                Payment Method
              </li>
            </Link>
            <li className="text-left" style={listStyles}>
              Logout
            </li>
          </ul>
        </div>
        <div
          className="w-11 pt-4 pl-6 pr-1 h-full overflow-y-scroll card2"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
          }}
        >
          <div
            className="flex flex-column"
            style={{
              width: "70%",
              display: "flex !important",
              alignContent: "center",
              flexDirection: "column",
              alignItems: "center", // Center vertically
              margin: "0 auto", // Center horizontally
              marginRight: isSmallScreen ? "auto" : "30%",
            }}
          >
            <h1
              style={{
                color: "#0E8E60",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "24px" : "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginTop: "-30px",
              }}
            >
              Payment Methods
            </h1>
            <p
              style={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "12px" : "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Add information about yours Payment options
            </p>
          </div>

          <Link
            to="/self-learning/newpaymentcard"
            className="checkoutformhead text-left "
            style={{
              color: "#0E8E60",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "12px" : "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textDecoration: "none",
            }}
          >
            Add Another Card +
          </Link>
          <h3
            className="checkoutformhead text-left "
            style={{
              fontSize: isSmallScreen ? "16px" : "24px",
            }}
          >
            Your saved Payment methods
          </h3>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "1%",
            }}
          >
            <div
              style={{
                width: isSmallScreen ? "auto" : "664px",
                height: "191px",
                flexShrink: 0,
                borderRadius: "15px",
                border: "1px solid #000",
                background: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "5% 0 5% 0",
              }}
            >
              <h1
                style={{
                  color: "rgba(0, 0, 0, 0.70)",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen ? "15px" : "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {" "}
                You don’t have any saved Payment methods
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddPayment;
