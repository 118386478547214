

import { environment, loginApi, onlineSignupApi, signupApi } from "../../../Api";
import {SIGNUP_REQUEST,SIGNUP_SUCCESS,SIGNUP_FAIL, SIGNUP_RESET, LOGOUT, LOGIN_ERROR, LOGIN_SUCCESS, LOGIN_REQUEST, PAYMENT_SUCCESS} from "../SelfTypes/Auth.types"

import axios from "axios"


export const Signupfunction = (data) => async (dispatch) => {
let cart= JSON.parse(localStorage.getItem("SLbrainyCartItem"));
// console.log(data);
dispatch({type:SIGNUP_REQUEST})
const payload={
    environment: environment,
    full_name: `${data.first_Name} ${data.last_Name}`,
    phone_number: data.phone_number,
    email_id: data.email_id,
    password: data.password,
    role: "STUDENT",
    course_id: cart.cart.course_id,
    course_type: "SL",
    gender: data.gender.name,
    age: data.age,
    batch_date: "None",
    batch_time: "None",
    cart:cart.cart
}
console.log(payload,"payload");

    try{
        let response= await axios.post(signupApi,payload)
        console.log(response.data);
        if(response.data.key=="email_id already registered"){
           dispatch({type:SIGNUP_FAIL,payload:response.data.key})
        }else{
            localStorage.setItem("brainypublicuser", JSON.stringify({email_id:data.email_id}));
            dispatch({ type: SIGNUP_SUCCESS,payload:response.data});
        }
       
    }
    catch(e){
console.log(e);
dispatch({type:SIGNUP_FAIL,payload:"Something went wrong! Please try after Sometime"})
    }

    
}
function seperate(inputString){
    const dateRegex = /(\d{1,2}[a-z]{2} \w+'(\d{2}))/i;
    const timeRegex = /(\d{1,2}[ap]m)-(\d{1,2}[ap]m)/i;
    
    const dateMatch = inputString.match(dateRegex);
    const timeMatch = inputString.match(timeRegex);
    
    if (dateMatch && timeMatch) {
      const date = dateMatch[0];
      const timeStart = timeMatch[1];
      const timeEnd = timeMatch[2];
    
    //   console.log("Date:", date);
    //   console.log("Time Start:", timeStart);
    //   console.log("Time End:", timeEnd);
      let x={}
      x.date=date
      x.time=`${timeStart} ${timeEnd}`
      return x
    } else {
      console.log("Invalid input format");
    }
}
export const OnlineSignupfunction = (data,status) => async (dispatch) => {
    let cart=status=="OL"? JSON.parse(localStorage.getItem("OLbrainyCartItem")):JSON.parse(localStorage.getItem("brainyCartItem"));
   //  console.log(data);
    const payload={
        environment: environment,
        full_name: `${data.first_Name} ${data.last_Name}`,
        phone_number: data.phone_number,
        email_id: data.email_id,
        password: data.password,
        role: "STUDENT",
        course_id: cart.cart.course_id,
        course_type: status?status:"",
        gender: data.gender.name,
        age: data.age,
        batch: data.batch&&data.batch.name,
        cart:cart.cart
    }
    console.log(payload,"payload");
    dispatch({type:SIGNUP_REQUEST})
        try{
            let response= await axios.post(onlineSignupApi,payload)
            console.log(response.data);
            if(response.data.key=="email_id already registered"){
                dispatch({type:SIGNUP_FAIL,payload:response.data.key})
             }
             else{
                localStorage.setItem("brainypublicuser", JSON.stringify({email_id:data.email_id}));
                 dispatch({ type: SIGNUP_SUCCESS,payload:response.data});
             }        }
        catch(e){
            dispatch({type:SIGNUP_FAIL,payload:"Something went wrong! Please try after Sometime"})
    console.log(e);
        }
    
    }
    
export const SignupReset=()=>async(dispatch)=>{
    dispatch({ type: SIGNUP_RESET});

}
export const SignOutfuntion=()=>async(dispatch)=>{
dispatch({type: LOGOUT})
}
export const LogInfunction = (data) => async (dispatch) => {

    console.log(data);
    const payload={
        environment: environment,
        email_id: data.email_id,
        password: data.password,
        role: "STUDENT",
     
    }
    // localStorage.setItem("bbemail",data.email_id)
    console.log(payload,"payload");
    dispatch({type:LOGIN_REQUEST})
        try{
            let response= await axios.post(loginApi,payload)
            console.log(response.data);
            if(response.data.key === "incorrect password" || response.data.key === 'user does not exist'){ 
                dispatch({type:LOGIN_ERROR,payload:response.data})
            }
            else { 
                // localStorage.setItem("brainypublicuser", JSON.stringify({email_id:data.email_id}));

                dispatch({ type: SIGNUP_SUCCESS,payload:response.data});
            }
        }
        catch(e){
    console.log(e);
        }
    
    }
    export const LogOutfuntion=()=>async(dispatch)=>{
        dispatch({type: LOGOUT})
        }
    export const PaymentSuccessfuntion=(data)=>async(dispatch)=>{
        dispatch({type:PAYMENT_SUCCESS,payload:data})
    }
// course: "Software Testing"
// email_id: "elon@gmail.com"
// full_name: "Elon Musk"
// role: "STUDENT"
// status: "ACTIVE"
// user_id: "BB-ST-ELO349-5E53"