import React, { useEffect } from 'react'
import Navbar from '../navbar/navbar'
import './Blog_Home.css'
import { Button } from 'primereact/button'
import Circles from '../../asserts/Circles.png'
import Laptop from '../../asserts/laptop.png'
import carauselback from "../../asserts/CarauselBack.png"
import { Image } from 'primereact/image'
import mainback from "../../asserts/mainBack3.png"
import gdp from "../../asserts/GDP.png"
import movies from "../../asserts/MOVIES.png"
import politics from "../../asserts/POLITICS.png"
import ai from "../../asserts/AI.png"
import science from "../../asserts/SCIENCE.png"
import sports from "../../asserts/SPORTS.png"
import lifestyle from "../../asserts/LIFESTYLE.png"
import education from "../../asserts/EDUCATION.png"
import categoryback from "../../asserts/categoryback.png"

import brain from "../../brain.gif"
import Carausels from './Carausel'
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { FetchBlogHome } from '../../Redux/SelfLearning/SelfAction/Blog.action'

export default function Blog_Home() {
  const navigate = useNavigate();
  const dispatch =useDispatch()
  const {homeData,loading}=useSelector(store=>store.blog)
// console.log(homeData);
  useEffect(()=>{
dispatch(FetchBlogHome())
  },[])
   useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    console.log(loading,"homeblog");
  return (
<div className="h-full w-full flex-column">

<Navbar /> 
{loading?<div className="hidden md:flex justify-content-center align-items-center h-25rem "  ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
<div className="w-full hidden md:flex flex-column h-full  gap-6 ">
        <div
          style={{
            backgroundImage: ` url(${mainback})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right ",
            backgroundSize: "100% ",
          }}
          className="p-6"
        >
          <div className="flex">
            <div className="w-5  flex flex-column gap-5 justify-content-center ">
              <h1 className="bghd1 text-left">
                Expand
                <span style={{ color: "#0E8E60" }}>
                  {" "}
                  Our <br /> Knowledge and <br /> Yours{" "}
                </span>
                One Blog <br /> at a time
              </h1>
              {/* <Button
                label="Create Blog Post"
                onClick={() => navigate("/online-classes/create-blog")}
                className="bgcrtbtn border-0 "
              /> */}
            </div>
            <div
              style={{
                backgroundImage: ` url(${Circles})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "90% ",
                height: "580px",
              }}
              className="w-7 flex justify-content-start align-items-center  p-5"
            >
              <div>
                <Image src={Laptop} width="450" className="ml-6" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-column  justify-content-center align-items-center pt-4 relative">
          <h1 className="bghd2 mb-6 text-center w-full">
            Best <span style={{ color: "#0E8E60" }}> Articles</span> Today
          </h1>

          <div
            style={{
              backgroundImage: `url(${carauselback})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom right",
              // border:"1px solid red"
            }}
            className="w-full pl-8 pr-8 pb-8 "
          >
            <Carausels  />
          </div>

          <div className="flex justify-content-end w-full pl-8 pr-8">
            <Button
              className="bgsaabtn"
              onClick={() => navigate("/online-classes/all-blogs")}
              label="See All Articles"
            />
          </div>
        </div>

        <div className=" flex flex-column  justify-content-center align-items-center mb-8">
          <h1 className="bghd3 text-center">
            {" "}
            <span style={{ color: "#0E8E60" }}> Popular</span> Categories
          </h1>

          <div
            style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top left,",
              backgroundSize: "10% 25%",
            }}
            className="flex justify-content-around w-full p-8"
          >
            <div
              onClick={() => navigate("/online-classes/all-blogs/Business & GDP")}
              className=" cursor-pointer w-16rem h-15rem flex flex-column justify-content-center align-items-center"
            >
              <Image src={gdp} width="100%" height="100%" />
              <span className="ctgies">Business & GDP</span>
            </div>
            <div
              onClick={() => navigate("/online-classes/all-blogs/Politics")}
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image src={politics} width="100%" height="100%" />
              <span className="ctgies">Politics</span>
            </div>
            <div
              onClick={() => navigate("/online-classes/all-blogs/Movies & Art")}
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image src={movies} width="100%" height="100%" />
              <span className="ctgies">Movies & Art</span>
            </div>
            <div
              onClick={() =>
                navigate("/online-classes/all-blogs/AI & Data Science")
              }
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image src={ai} width="100%" height="100%" />
              <span className="ctgies">AI & Data Science</span>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: " bottom right",
              backgroundSize: "10% 25%",
            }}
            className="flex justify-content-around align-items-center w-full  pl-8 pr-8 pt-8 pb-5 "
          >
            <div
              onClick={() =>
                navigate("/online-classes/all-blogs/Space Science & Technology")
              }
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image
                src={science}
                width="100%"
                height="100%"
                className="mt-6"
              />
              <span className="ctgies">Space science & technology</span>
            </div>
            <div
              onClick={() => navigate("/online-classes/all-blogs/Sports")}
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image src={sports} width="100%" height="100%" />
              <span className="ctgies">Sports</span>
            </div>
            <div
              onClick={() => navigate("/online-classes/all-blogs/Lifestyle")}
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image src={lifestyle} width="100%" height="100%" />
              <span className="ctgies">Lifestyle</span>
            </div>
            <div
              onClick={() => navigate("/online-classes/all-blogs/Education")}
              className="w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer"
            >
              <Image src={education} width="100%" height="100%" />
              <span className="ctgies">Education</span>
            </div>
          </div>
        </div>
      </div>
}
{loading?<div className="flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className="md:hidden m-auto"   ></img></div>:
      <div className="w-full flex md:hidden flex-column h-full overflow-y-scroll gap-6 ">
        <div
          className="mt-5 pl-4 pr-3"
          style={{
            backgroundImage: ` url(${mainback})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center ",
            backgroundSize: "100% ",
          }}
        >
          <div className="flex flex-column">
            <div className="w-full  flex flex-column gap-5 justify-content-center p-1 ">
              <h1 className="bghd10 text-left">
                Expand
                <span style={{ color: "#0E8E60" }}>
                  {" "}
                  Our <br /> Knowledge and <br /> Yours,
                </span>
                One Blog at a time
              </h1>
              {/* <Button
                label="Create Blog Post"
                onClick={() => navigate("/create_blog")}
                className="bgcrtbtn border-0 "
              /> */}
            </div>
            <div
              style={{
                backgroundImage: ` url(${Circles})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% ",
                height: "319px",
              }}
              className="w-full flex justify-content-center align-items-center "
            >
              <Image src={Laptop} width="300"  />
            </div>
          </div>
        </div>

        <div className="flex flex-column  justify-content-center align-items-center pt-4 ">
          <h1 className="bghd2_1 text-center">
            Best <span style={{ color: "#0E8E60" }}> Articles</span> Today
          </h1>

          <div
            style={{
              backgroundImage: `url(${carauselback})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom right",
            }}
            className="w-full pt-4 "
          >
            <Carausels />
          </div>
        </div>

        <div className=" flex flex-column gap-8 justify-content-center align-items-center">
          <h1 className="bghd3_0 mt-2 -mb-6 text-center">
            {" "}
            <span style={{ color: "#0E8E60" }}> Popular</span> Categories
          </h1>

          <div
            style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top left,",
              backgroundSize: "20% 30%",
            }}
            className="flex pl-4 pr-4 gap-2 justify-content-around w-full pt-8 "
          >
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/Business & GDP")}>
              <Image src={gdp} width="100%" height="100%" />
              <span className="ctgies_0">Business & GDP</span>
            </div>
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/Politics")}>
              <Image src={politics} width="100%" height="100%" />
              <span className="ctgies_0">Politics</span>
            </div>
          </div>
          <div className="flex pl-4 pr-4 gap-2 justify-content-around w-full">
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/Movies & Art")}>
              <Image src={movies} width="100%" height="100%" />
              <span className="ctgies_0">Movies & Art</span>
            </div>
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/AI & Data Science")}>
              <Image src={ai} width="100%" height="100%" />
              <span className="ctgies_0">AI & Data Sc...</span>
            </div>
          </div>
          <div className="flex pl-4 pr-4 gap-2 justify-content-around w-full">
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/Lifestyle")}>
              <Image src={lifestyle} width="100%" height="100%" />
              <span className="ctgies_0">Lifestyle</span>
            </div>
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/Education")}>
              <Image src={education} width="100%" height="100%" />
              <span className="ctgies_0">Education</span>
            </div>
          </div>

          <div
            style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: " bottom right",
              backgroundSize: "20% 30%",
            }}
            className="flex justify-content-around align-items-center w-full pl-4 pr-4 gap-2 pb-6 mb-6 "
          >
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center"               onClick={() => navigate("/online-classes/all-blogs/Space Science & Technology")}>
              <Image
                src={science}
                width="100%"
                height="100%"
                className="mt-4"
              />
              <span className="ctgies_0">Space science & technology</span>
            </div>
            <div className="w-12rem h-10rem flex flex-column justify-content-center align-items-center "               onClick={() => navigate("/online-classes/all-blogs/Sports")}>
              <Image src={sports} width="100%" height="100%" />
              <span className="ctgies_0">Sports</span>
            </div>
          </div>
        </div>
      </div>}

<Footer/>

</div>  )
}

