import React from 'react'
import './blog.css'
import farmer from "../../../asserts/farmer.png"
import { Image } from 'primereact/image'
import profile from '../../../asserts/ProfilePic.png'
import { useNavigate } from 'react-router-dom'
export default function Blog({elem}) {
  const navigate = useNavigate();
  function calculateTimeAgo(dateString) {
    const currentDate = new Date();
    const targetDate = new Date(dateString);
  
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - targetDate;
  
    // Calculate days, hours, and minutes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  
    if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes}m ago`;
    } else {
      return `Just now`;
    }
  }
  const extractFirst50Characters = (htmlContent) => {
    // Create a temporary div element
    const tempDiv = document.createElement('div');
  
    // Set the innerHTML of the div with your HTML content
    tempDiv.innerHTML = htmlContent;
    // tempDiv.dangerouslySetInnerHTML={ __html: htmlContent }
    // Extract the text content and slice the first 50 characters
    const extractedText = tempDiv.textContent.slice(0, 100);
  
    // Return the extracted text
    return extractedText;
  };
  function encodeSpacesInUrl(url) {
    return url.replace(/ /g, "%20");
}
  return (
    <div
      className="m-auto flex flex-column my-6 w-9"
      style={{ height: "400px",cursor: "pointer" }}
      onClick={() => navigate(`/office-classes/read-blog/${elem.blog_id}`)}
    >
     <div
        className="relative"
        style={{
          backgroundImage:  ` url(${encodeSpacesInUrl(elem.title_image)})` ||  ` url(${encodeURI(elem.title_image)})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "55%",
          borderRadius: "20px 20px 0px 0px",
        }}
      >
        <div
          id="usr"
          className="flex justify-content-center align-items-center ml-2 mt-3 gap-1"
        >
          <img src={elem.author_image} className="" />
          <h1 id="nm" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{elem.author_name&&elem.author_name.split(" ")[0]}</h1>
        </div>
        <div id="ctg">{elem.category}</div>
      </div>
      <div
        className="forbg "
        style={{ height: "45%", borderRadius: "0px 0px 20px 20px" }}
      >
        <div className="flex flex-column gap-1 p-3" style={{ height: "70%" }}>
          <h5 id="ttl" className="text-left mb-0 mt-0">
          {elem.title.slice(0, 49)}
          </h5>
          {/* <p  id='desp' dangerouslySetInnerHTML={{ __html: data.content }}></p> */}
          <p id="desp" className="text-left mb-0 mt-0">
          {extractFirst50Characters(elem.content)}...
          </p>
        </div>
        <div
          className="flex justify-content-around align-items-center"
          style={{ height: "18%" }}
        >
          <div className="flex flex-column mb-3 ">
            <p id="time" className="text-left mb-0 mt-0">
              {calculateTimeAgo(elem.datetime)}
            </p>
            <div className="flex gap-1 justify-content-center align-items-center">
              <span>{elem.num_of_likes}</span>
              <i className="pi pi-heart" style={{ fontSize: ".75rem" }}></i>
              <span>{elem.num_of_comments}</span>
              <i className="pi pi-comment" style={{ fontSize: ".75rem" }}></i>
            </div>
          </div>
          <div id="vwbtn">Read More</div>
        </div>
      </div>
    </div>
  );

}
