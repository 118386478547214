import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import Brainybits from "../../../asserts/Brainybits.png";
import Girlwithlaptop from "../../../asserts/Girlwithlaptop.png";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { environment, resetPasswordApi } from "../../../Api";
import { Password } from 'primereact/password';
import "./ForgetPassword.css"

const SelfChangePassword = () => {
  const history = useNavigate();
  const [message, setMessage] = useState("");
  const [password, setpassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email,setEmail]=useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [passwordView, setPasswordView] = useState({
    password: false,
    newPassword: false,
  });
  const searchParams = new URLSearchParams(location.search);
  const x = searchParams.get("x");
  const y = searchParams.get("y");


  const handleSubmit = async (e) => {
    e.preventDefault();
    const regx = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    console.log(regx.test("uI(90ee11"));
    console.log(regx.test(password));
    console.log(password,newPassword);
    if(password=="" || newPassword=="" || email==""){
        setErrorMessage("Please fill all fields");

    }
    else{    
    if (password !== newPassword) {
      setErrorMessage("Password Doesn't match");
    } else if (!regx.test(password)) {
      setErrorMessage(
        "Minimum eight characters, at least one letter and one numbe special char ! @ # $ % ^ &"
      );
    } else {
      const url =resetPasswordApi;
      const body = {
        environment: environment,
        "email_id_encrypted" : y ,
            "password": password,
            "token"  : x,
      };
      const response = await axios.post(url,body)
      console.log(response.data)
      if (response.data.key === "sucessfully_updated") {
        setMessage("Password Updated Successfully");
        setVisible(true);
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      } else {
        setMessage("Password is not updated");
        setVisible(true);
        setLoader(true);
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    }
}
  };

  const footerContent = (
    <div>
      <Button style={{backgroundColor:"#0E8E60",color:"#FFF"}}
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          setVisible(false);
          history("/self-learning");
        }}
        
      />
    </div>
  );

  return (
    <div className=" w-full flex flex-column md:flex-row align-items-center justify-content-center" style={{height:"100vh"}}>
      <section className="md:w-7 w-full h-full flex flex-order-1 md:flex-order-0 align-items-center justify-content-center">
        <div className="md:h-8 my-auto h-full md:w-8 w-full p-4 md:p-0 flex flex-column justify-content-center align-items-start">
          <h1 className="text-4xl font-bold">Change password</h1>
          <p className="text-sm my-2 md:w-9 w-full text-left">
            Input your new desired password in the input fields below to create
            a new password. We strongly advise you to store it safely.
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex flex-column align-items-start md:w-9 w-full"
          >
            <label className="text-sm mt-5 mb-1">New password</label>
            <span className="p-input-icon-right w-full">
             
     <Password  className="p-password-input h-3rem w-full"  strongRegex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$" id="password" placeholder="Password" name='password'    onChange={(e) => {
                  setNewPassword(e.target.value);
                  setErrorMessage("");
                }}   toggleMask/>
            </span>

            <label className="text-sm mt-3 mb-1">Confirm new password</label>
            <span className="p-input-icon-right w-full">
             
                <Password  className="p-password-input h-3rem w-full"  strongRegex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$" id="password" placeholder="Password" name='password'     onChange={(e) => {
                  setpassword(e.target.value);
                  setErrorMessage("");
                }}  toggleMask/>
            </span>
            {errorMessage !== "" && (
              <span className="text-red-400 text-xs">{errorMessage}</span>
            )}
             <Button
              type="submit"
              label="Change password "
              className="w-full mt-5 border-round-lg dgsignupbtn"
            />

          
          </form>
          {/* <form
            onSubmit={handleSubmit}
            className="flex flex-column align-items-start md:w-9 w-full"
          >
             <label className="text-sm mt-5 mb-1">Email</label>
            <span className=" w-full">
              <InputText
                placeholder="Email id"
                type= "email"
                className="w-full h-3rem border-noround"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage("");
                }}
              />
            </span>
            <label className="text-sm mt-3 mb-1">New password</label>
            <span className=" w-full">
            <Password  className="w-12 h-3rem border-noround" placeholder="New Password" onChange={(e) => { setNewPassword(e.target.value); setErrorMessage("");}} toggleMask />
             
            </span>

            <label className="text-sm mt-3 mb-1">Confirm new password</label>
            <span className=" w-full">
            <Password  className="w-12 h-3rem border-noround" placeholder="Confirm new password" onChange={(e) => { setpassword(e.target.value); setErrorMessage("");}} toggleMask />

            </span>
            {errorMessage !== "" && (
              <span className="text-red-400 text-xs">{errorMessage}</span>
            )}

            <Button
              type="submit"
              label="Change password "
              className="w-full mt-5 border-round-lg dgsignupbtn"
            />
          </form> */}
          <Dialog
            header=""
            visible={visible}
            style={{ width: "50vw" }}
            footer={footerContent}
            onHide={() => {
              setVisible(false);
            }}
          >
            <div className="flex justify-content-center align-items-center h-full w-full">
              {loader ? (
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="3"
                  color="var(--greenn-500)"
                  fill="#ffffff"
                  animationDuration=".5s"
                />
              ) : (
                <p>{message}</p>
              )}
            </div>
          </Dialog>
        </div>
      </section>
      <section className="md:w-5 w-full p-4 pt-7 md:pt-0 md:p-0 md:flex-order-1 flex-order-0 md:h-full  flex align-items-center justify-content-center" style={{backgroundColor:"#0E8E60"}}>
        <div className="h-11 md:gap-4 gap-2 w-8 flex flex-column align-items-center mt-8 md:mt-0 mb-4 md:mb-0">
          <img className="w-11" src={Brainybits} alt="KKKK" />
          <img className="md:w-9 w-7" src={Girlwithlaptop} alt="KKKK" />
        </div>
      </section>
    </div>
  );
};

export default SelfChangePassword;
