import React, { useEffect, useState } from "react";
import "./Home.css";
import Saly_10 from "../../asserts/Saly_10.png";
import { Button } from "primereact/button";
import {
  Groupicon,
  Lock_key,
  Technologies,
  TwoUserIcons,
  VideoCamera,
  Screen,
  User,
  Result,
  Pricee,
  Certificate,
} from "../../figmaicons/figma";
import Rectanglerobot from "../../asserts/Rectanglerobot.png";
import Artificialinteligence from "../../asserts/Artificialinteligence.png";
import RectangleDeepLearning from "../../asserts/RectangleDeepLearning.png";
import RectangleMechineLearning from "../../asserts/Test_Automation.png";
import Feedbackimg1 from "../../asserts/Feedbackimg1.png";
import Feedbackimg2 from "../../asserts/Feedbackimg2.png";
import Feedbackimg3 from "../../asserts/Feedbackimg3.png";
import BestPlatformtogrow from "../../asserts/BestPlatformtogrow.jpg";
import Girlwithmice from "../../asserts/Girlwithmice.png";
import { ImQuotesRight } from "react-icons/im";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import Latestnews from "../LatestNews/LatestNews";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/navbar";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomePage } from "../../Redux/Online/OnlineAction/Online.action";
import { AddToCartFunction } from "../../Redux/SelfLearning/SelfAction/Cart.action";
import brain from "../../brain.gif";

export const array = [
  {
    id: 0,
    image: Rectanglerobot,
    courseName: "Masters Program in Data Science & AI",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 40,000",
  },
  {
    id: 1,
    image: RectangleMechineLearning,
    courseName: "Manual Testing & Advancing in Tricentis Tosca ",
    frameWork: "Test Automation",
    mentor: "Pramod k ",
    price: "₹ 25,000",
  },
  {
    id: 2,
    image: RectangleDeepLearning,
    courseName: "Deep Learning  Specialisation ",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 30,000",
  },
  {
    id: 3,
    image: Artificialinteligence,
    courseName: "Artificial Intelligence with Python",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 30,000",
  },
];
const features = [
  {
    id: 0,
    icon: Lock_key,
    feature: "Life Time Access",
    content:
      "Platform offers lifetime access to purchased courses allow for flexible, self-paced learning and the ability to revisit material as needed.",
  },
  {
    id: 1,
    icon: Screen,
    feature: "Online Classes",
    content:
      "Online classes offer remote learning with live video, recorded lectures, and interactive assignments from anywhere with internet access.",
  },
  {
    id: 2,
    icon: Certificate,
    feature: "Get Certificate",
    content:
      "Complete the course requirements to earn a certificate of completion. Digitally singed",
  },
  {
    id: 3,
    icon: User,
    feature: "Expert Trainers",
    content:
      "Expert trainers provide specialised instruction and mentorship to help trainees develop their skills.",
  },
  {
    id: 4,
    icon: Result,
    feature: "Great Result",
    content:
      "After completing the course, participants can apply their new skills to achieve their goals.",
  },
  {
    id: 5,
    icon: Pricee,
    feature: "Best Price",
    content:
      "Our platform offers affordable pricing options without sacrificing the quality of the course content.",
  },
];
export const products = [
  {
    id: "0",
    image: Feedbackimg1,
    name: "Krishna K",
    profession: "Data Scientist",
    comment:
      "Great course! Well-organized content, engaging instructor, interactive exercises, and variety of resources. Highly recommend.",
  },
  {
    id: "1",
    image: Feedbackimg2,
    name: "Anika Donthula",
    profession: "Sr Data Scientist",
    comment:
      "Outstanding e-learning! Structured content, captivating instructor, and interactive exercises with value. ",
  },
  {
    id: "2",
    image: Feedbackimg3,
    name: "Vikrant Bha",
    profession: "Sr Data Scientist",
    comment:
      "Curriculum is quite robust, very well required for modern industry to secure job in very short time with high package",
  },
  {
    id: "3",
    image: Feedbackimg1,
    name: "Shekar Kollu",
    profession: "Data Engineer",
    comment:
      "Great program! Up-to-date, practical curriculum, experienced instructors, and valuable networking opportunities.",
  },
  {
    id: "4",
    image: Feedbackimg2,
    name: "Prathamesh",
    profession: "Data Scientist",
    comment:
      "Outstanding data science program! Challenging yet accessible, brilliant instructors, cutting-edge tools, and job-ready skills.  ",
  },
  {
    id: "5",
    image: Feedbackimg3,
    name: "Kiara Singh",
    profession: "Machine Learning Engineer",
    comment:
      "Exceptional data science program! Rigorous curriculum, supportive instructors, industry-relevant knowledge, and cutting-edge tools.",
  },
];
const ProductTemplate = (product) => {
  // console.log(product)
  return (
    <div className="w-full md:w-11 relative mt-5 md:mt-0 md:m-2 text-center md:py-5 md:px-3">
      <div className="w-full  flex  align-items-center my-2">
        <img
          src={product.product.image}
          alt="imag"
          height={60}
          className="mr-2"
        />

        <section className="w-7 md:w-full" >
          <h3 className="m-0 font-family  text-left pr-3 " style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
            {product.product.name}
          </h3>
          <p className="m-0 font-family text-sm text-left " style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
            {product.product.profession}{" "}
          </p>
        </section>
        <ImQuotesRight
          className="hidden md:flex"
          style={{
            position: "absolute",
            top: "38px",
            left: "67%",
            height: "25px",
            width: "25px",
            color: "#0E8E60",
          }}
        />
      </div>

      <p className="text-left w-10 md:w-9" style={{fontFamily:"Poppins",fontSize:"12px",fontWeight:"400"}}>{product.product.comment}</p>

      <div className="mt-5 flex flex-wrap gap-2 justify-content-center"></div>
    </div>
  );
};

export default function Home() {
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);
  const { data } = useSelector((store) => store.onlinehome);

  useEffect(() => {
    const targetValue = 26345;
    const animationDuration = 3000;
    const frameDuration = 1000 / 60; // Assuming 60 frames per second

    const targetValue1 = 100;
    const targetValue2 = 1000;
    const targetValue3 = 20;

    let frame = 0;
    const totalFrames = Math.round(animationDuration / frameDuration);

    let frame1 = 0;

    let frame2 = 0;

    let frame3 = 0;

    const incrementValue = () => {
      const progress = frame / totalFrames;
      const current = Math.round(progress * targetValue);
      setValue(current);

      if (frame < totalFrames) {
        frame++;
        requestAnimationFrame(incrementValue);
      }
    };

    const incrementValue1 = () => {
      const progress = frame1 / totalFrames;
      const current = Math.round(progress * targetValue1);
      setValue1(current);

      if (frame1 < totalFrames) {
        frame1++;
        requestAnimationFrame(incrementValue1);
      }
    };
    const incrementValue2 = () => {
      const progress = frame2 / totalFrames;
      const current = Math.round(progress * targetValue2);
      setValue2(current);

      if (frame2 < totalFrames) {
        frame2++;
        requestAnimationFrame(incrementValue2);
      }
    };
    const incrementValue3 = () => {
      const progress = frame3 / totalFrames;
      const current = Math.round(progress * targetValue3);
      setValue3(current);

      if (frame3 < totalFrames) {
        frame3++;
        requestAnimationFrame(incrementValue3);
      }
    };

    incrementValue();
    incrementValue1();
    incrementValue2();
    incrementValue3();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomePage());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(data, "chhome");
  const CartAddfunction = (id, el) => {
    // console.log(id,el);
    dispatch(AddToCartFunction(id, { ...el, course_type: "OL" }));
    navigate("/online-classes/cart");
  };
  return (
    <>
      <Navbar background={"green"} />
      {data.length == 0 ? (
        <div className="hidden md:flex justify-content-center align-items-center h-25rem">
          <img
            src={brain}
            width={80}
            className=" m-auto   hidden  md:block"
          ></img>
        </div>
      ) : (
        <div className="hidden md:block">
          <div className="w-full greencontainer flex flex-column align-items-center">
            <div className="w-10 flex text-left align-items-center text-white greencontentbox">
              <div className="  pl-2 w-6 flex flex-column align-items-start align-self-center align-self-start mt-6">
                <h1 className="w-10 md:w-9 m-0 md:mb-1 mt-3 text-4xl md:text-6xl xl:text-6xl  HomeTitle">
                  Upgrade your skills for better future
                </h1>
                <p className="w-full md:w-9 text-sm my-3 md:text-lg paragraph">
                  Join BrainyBits today and start your journey towards mastering
                  the intelligence!
                </p>
                <div className="flex w-full my-2 gap-2 md:mt-5">
                  <Button
                    label="Check Courses"
                    onClick={() => navigate("/online-classes/courses")}
                    text
                    className="homebtn1 h-1rem  md:h-3rem md:w-11rem "
                  />

                  <Button
                    className="h-1rem md:h-3rem md:w-11rem homebtn2"
                    outlined
                    label="Learn more"
                    onClick={() => navigate("/online-classes/courses")}
                    style={{
                      background: "transparent",
                      color: "#FFFFFF",
                    }}
                  />
                </div>
              </div>
              <div className="  w-6 flex justify-content-center">
                <img
                  src={Saly_10}
                  style={{ maxHeight: "90%", maxWidth: "100%" }}
                  alt="img"
                />
              </div>
            </div>
          </div>

          <div className=" align-self-center my-6 my-4 font-family w-full px-8">
            <h1 className="homecoursehead pl-4"> Our Courses</h1>
            <div className="px-6">
                 <Splide  hasTrack={ false } className='mt-2 sdbox' options={{
             rewind:false, 
             autoplay: true,
             perMove: 1,
             perPage: 4, 
             gap: '1.2rem',
             arrows: true,
             pagination: true
            
          }}
          
         > 
           <SplideTrack >
   
         {data &&
                data.All_Courses_List &&
                data.All_Courses_List.map((el)=>( 
   <SplideSlide >
   <div key={el.course_id} className='tabcrslboxol w-12 p-3  flex flex-column justify-content-between cursor-pointer' >
                        {/* <div  className='flex h-2rem align-items-center gap-2 mb-1' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}><img src={el.instructor_photo } style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} /><span className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.instructor_name}</span></div> */}
                        <div className='h-12rem py-2 w-full flex justify-content-center align-items-center' style={{borderRadius:"10px",position:"relative"}}><img src={el.course_photo} onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{ height:"100%",width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"fill"}} /></div>
                        <span className='tabcrslcoursename ' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
                        <span className='offlinecllgrating text-left flex align-items-center' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{height:"15px"}}>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                          <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill={el.ratings>=1?"#0E8E60":"#E0E0E0"}/>
                          <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={el.ratings>=2?"#0E8E60":"#E0E0E0"}/>
                          <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={el.ratings>=3?"#0E8E60":"#E0E0E0"}/>
                          <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={el.ratings>=4?"#0E8E60":"#E0E0E0"}/>
                          <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={el.ratings>=5?"#0E8E60":"#E0E0E0"}/>
                        </svg> ({el.total_people_rated})</span>
                        <div className='flex justify-content-between align-items-center'><span className='tabprice' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
                     </div></SplideSlide>
         ))}
         </SplideTrack>
   
       
    <div className="splide__arrows">
     <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-7"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
     <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>
   
   </button>
   </div>
        </Splide>
   
        </div>     
   
{/*         
            <ul className="flex w-12 m-auto gap-4 h-24rem  pl-8 pr-8">
              {data &&
                data.All_Courses_List &&
                data.All_Courses_List.slice(0, 4).map((el) => (
                  <>
                     <div key={el.course_id} className='tabcrslbox w-3 p-3  flex flex-column justify-content-between cursor-pointer' >
                          <div  className='flex h-2rem align-items-center gap-2' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}><img src={el.instructor_photo } alt="img" style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} /><span className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.instructor_name}</span></div>
                          <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_photo} onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{ height:"100%",width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>
                          <span className='tabcrslcoursename pt-2' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
                          <span className='text-left flex align-items-center' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{height:"15px"}}><span className='dataviewrating' >{el.ratings}</span>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                          <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.ratings)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.ratings)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.ratings)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.ratings)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.ratings)>4?"#0E8E60":"#E0E0E0"}/>
                       </svg></span>
                          <div className='flex justify-content-between align-items-center'><span className='tabprice'onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
                       </div>
                  
                  </>
                ))}
            </ul> */}
          </div>
          {/* <span
            className="flex justify-content-center align-items-center  mt-4 align-self-center cursor-pointer"
            onClick={() => navigate("/online-classes/courses")}
          >
            <span className="homeSeeMorebtn">See More</span>
            <i className="pi pi-angle-down" style={{ color: "#0E8E60" }}></i>
          </span> */}

          <div className="w-full flex flex-column align-items-start font-family mb-8 md:py-3 h-20rem md:px-8">
            <h2 className="homestudent h-3rem px-4   my-2 md:mb-4 md:px-5 m-0">
              Students<span className="homefeedback"> Feedback</span>
            </h2>
            <p className="px-4 md:px-5  m-0 feedbacktext ml-2">
              Here are some feedback comments from students.
            </p>

            <div className="card w-full ">
              <Splide
                hasTrack={false}
                className="ml-8 mt-4"
                options={{
                  // type: 'loop',
                  rewind: false,
                  autoplay: true,
                  perMove: 1,
                  perPage: 3,
                  gap: "2rem",
                  arrows: true,
                  pagination: true,
                }}
              >
                <SplideTrack>
                  {products.map((el) => (
                    <SplideSlide>
                      <ProductTemplate product={el} />
                    </SplideSlide>
                  ))}
                </SplideTrack>

                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev shadow-3 -ml-8">
                    <i
                      className="pi pi-angle-left"
                      style={{ fontSize: "1.2rem" }}
                    ></i>
                  </button>
                  <button className="splide__arrow splide__arrow--next shadow-3 ">
                    <i
                      className="pi pi-angle-right"
                      style={{ fontSize: "1.2rem", color: "white" }}
                    ></i>
                  </button>
                </div>
              </Splide>
            </div>
          </div>
          <br />
          <h1 className="px-4 text-center mt-8 mb-4 homefeatureshead">
            Our Features and Services
          </h1>
          <h3 className="md:w-5 text-center px-4 homefeatureshead2 align-self-center">
            This platform provides access to courses, interactive content,
            personalised learning paths, live and recorded lectures,
            assessments, and making learning convenient and accessible from
            anywhere with an internet connection.
          </h3>
          <br />
          <ul className="list-none  flex w-8  align-self-center  justify-content-center flex-wrap m-auto">
            {features.map((each) => (
              <li className="w-3 ml-5">
                <div className="h-14rem flex flex-column font-family align-items-start  m-1 pr-4">
                  <each.icon className="h-1rem w-1rem " />
                  <h3 className="homefeaturehead3">{each.feature}</h3>
                  <p className="text-left text-600 text-sm m-0">
                    {each.content}
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <div className="h-30rem flex   w-full my-7 py-3 px-8">
            <div className="w-full h-30rem hidden justify-content-center flex md:w-6">
              <img src={BestPlatformtogrow} alt="img" className="h-full" />
            </div>
            <div className="w-6 flex flex-column font-family px-4 align-items-start justify-content-center  h-full">
              <h1 className="best-grow-head my-1">Best Platform to grow</h1>
              <p className="w-10 text-left  best-grow-paragraph text-600">
                Empower your learning with flexible, expert-led courses and
                personalised support on the best e-learning platform for
                achieving your goals.
              </p>

              <Button
                label="Learn more"
                className="bg-greenn-500 align-self-start  my-1 best-grow-learnMore"
                onClick={() => navigate("/online-classes/about-us")}
              />
            </div>
          </div>
          <div className="h-30rem flex flex-row justify-content-center w-full my-7 py-3 pl-4 px-8">
            <div className="w-6 flex flex-column font-family  ml-0 pr-0 pl-6 align-items-start justify-content-center gap-2 h-full">
              <h1 className="best-grow-head  my-1">24/7 Students Support</h1>
              <p className="text-left  best-grow-paragraph text-600">
                With our 24/7 support, you can learn at your own pace, on your
                own schedule, with the peace of mind that comes from knowing
                help is always just a click away.
              </p>

              <Button
                label="Read more"
                className="bg-greenn-500 align-self-start h-3rem best-grow-learnMore my-1"
                onClick={() => navigate("/online-classes/contact-us")}
              />
            </div>
            <div className="w-full flex justify-content-end w-5">
              <img src={Girlwithmice} alt="img" className="h-full " />
            </div>
          </div>
          <Latestnews />
          <Footer />
        </div>
      )}

      {data.length == 0 ? (
        <div className="flex justify-content-center align-items-center h-25rem">
          <img src={brain} width={80} className="md:hidden m-auto"></img>
        </div>
      ) : (
        <div className="block md:hidden">
          <div className="w-full greencontainer flex flex-column align-items-center">
            <div className="w-10 flex flex-column  text-left align-items-center text-white greencontentbox">
              <div className="  flex flex-column align-items-start align-self-center align-self-start mt-6">
                <h1 className="w-12  m-0  mt-3 text-4xl md:text-6xl xl:text-6xl  HomeTitle">
                  Upgrade your skills for better future
                </h1>
                <p className="w-full paragraph0_m my-2">
                  Join BrainyBits today and start your journey towards mastering
                  the intelligence!
                </p>
                <div className="flex w-full my-2 gap-2">
                  <button
                    // label="Check Courses"
                    onClick={() => navigate("/online-classes/courses")}
                    text
                    // className="bg-white h-2rem font-family md:h-3rem md:w-11rem homebtn10"
                    className={`text-left mt-1`}
                    style={{
                      width: "150px",
                      height: "50px",
                      color: "#0E8E60",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      backgroundColor: "#fff",
                      border: "1px solid #0E8E60",
                      borderRadius: "30px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    Check Courses
                  </button>

                  {/* <Button
                    className="transparent-button font-family p-button-outlined h-2rem md:h-3rem md:w-11rem homebtn20"
                    label="Learn more"
                    onClick={()=>navigate("/online-classes/courses")}
                    style={{
                      background: "transparent",
                      color: "#FFFFFF",
                    }}
                  /> */}
                  <button
                    // label="Check Courses"
                    onClick={() => navigate("/online-classes/courses")}
                    text
                    // className="bg-white h-2rem font-family md:h-3rem md:w-11rem homebtn10"
                    className={`text-left mt-1`}
                    style={{
                      width: "150px",
                      height: "50px",
                      color: "#fff",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      backgroundColor: "#0E8E60",
                      border: "1px solid #fff",
                      borderRadius: "30px",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    Learn more
                  </button>
                </div>
              </div>
              <div className="  flex justify-content-center">
                <img
                  src={Saly_10}
                  style={{ maxHeight: "90%", maxWidth: "100%" }}
                  alt="img"
                />
              </div>
            </div>
          </div>

          <div className=" w-full pl-3 pr-4 mt-6">
            <h1 className="homecoursehead0 text-left pl-2"> Our Courses</h1>
            <div className="px-4">
                 <Splide  hasTrack={ false } className='mt-2 sdbox' options={{
             rewind:false, 
             autoplay: true,
             perMove: 1,
             perPage: 1, 
             gap: '1rem',
             arrows: true,
             pagination: true
            
          }}
          
         > 
           <SplideTrack >
   
         {data &&
                data.All_Courses_List &&
                data.All_Courses_List.slice(0, 10).map((el)=>( 
   <SplideSlide >
   <div key={el.course_id} className='tabcrslboxol w-12 p-3  flex flex-column justify-content-between cursor-pointer' >
                        {/* <div  className='flex h-2rem align-items-center gap-2 mb-1' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}><img src={el.instructor_photo } style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} /><span className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.instructor_name}</span></div> */}
                        <div className='h-12rem py-2 w-full flex justify-content-center align-items-center' style={{borderRadius:"10px",position:"relative"}}><img src={el.course_photo} onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{ height:"100%",width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"fill"}} /></div>
                        <span className='tabcrslcoursename ' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
                        <span className='offlinecllgrating text-left flex align-items-center' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{height:"15px"}}>  <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                          <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill={el.ratings>=1?"#0E8E60":"#E0E0E0"}/>
                          <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={el.ratings>=2?"#0E8E60":"#E0E0E0"}/>
                          <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={el.ratings>=3?"#0E8E60":"#E0E0E0"}/>
                          <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={el.ratings>=4?"#0E8E60":"#E0E0E0"}/>
                          <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={el.ratings>=5?"#0E8E60":"#E0E0E0"}/>
                        </svg> ({el.total_people_rated})</span>
                        <div className='flex justify-content-between align-items-center'><span className='tabprice' onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
                     </div></SplideSlide>
         ))}
         </SplideTrack>
   
       
    <div className="splide__arrows">
     <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-6"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
     <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-6"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>
   
   </button>
   </div>
        </Splide>
   
        </div>   
            {/* <ul className="flex w-full h-25rem pl-0 ">
              {data &&
                data.All_Courses_List &&
                data.All_Courses_List.slice(0, 1).map((el) => (
                  <div
                    key={el.course_id}
                    className="tabcrslbox w-10 m-auto  p-3 flex flex-column"
                  >
                    <div className="flex h-2rem align-items-center gap-2">
                      <img
                        src={el.instructor_photo}
                        style={{
                          borderRadius: "50%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt="img"
                      />
                      <span
                        className="tabcrslname"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {el.instructor_name}
                      </span>
                    </div>
                    <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}>
                      <img src={el.course_photo} onClick={()=>navigate(`/online-classes/individual-courses/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>

                   
                    <span
                      className="tabcrslcoursename pt-2"
                      onClick={() =>
                        navigate(
                          `/online-classes/individual-courses/${el.course_id}`
                        )
                      }
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {el.course_name}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-1"
                      width="55"
                      height="11"
                      viewBox="0 0 55 11"
                      fill="none"
                    >
                      <path
                        d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"
                        fill={
                          Math.round(el.ratings) > 0 ? "#0E8E60" : "#E0E0E0"
                        }
                      />
                      <path
                        d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z"
                        fill={
                          Math.round(el.ratings) > 1 ? "#0E8E60" : "#E0E0E0"
                        }
                      />
                      <path
                        d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z"
                        fill={
                          Math.round(el.ratings) > 2 ? "#0E8E60" : "#E0E0E0"
                        }
                      />
                      <path
                        d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z"
                        fill={
                          Math.round(el.ratings) > 3 ? "#0E8E60" : "#E0E0E0"
                        }
                      />
                      <path
                        d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z"
                        fill={
                          Math.round(el.ratings) > 4 ? "#0E8E60" : "#E0E0E0"
                        }
                      />
                    </svg>
                    <div className="flex justify-content-between align-items-center">
                      <span className="tabprice">₹ {el.course_cost}</span>
                      <Button
                        className="tabbuybtn"
                        onClick={() =>
                          navigate(
                            `/online-classes/individual-courses/${el.course_id}`
                          )
                        }
                      >
                        Full detaols
                      </Button>
                    </div>
                  </div>
                ))}
            </ul> */}
          </div>
          {/* <span
            className="flex justify-content-center align-items-center  mt-4 align-self-center cursor-pointer"
            onClick={() => navigate("/online-classes/courses")}
          >
            <span className="homeSeeMorebtn">See More</span>
            <i className="pi pi-angle-down" style={{ color: "#0E8E60" }}></i>
          </span> */}

          <div className="w-full flex flex-column align-items-start   pl-3 pr-4 h-15rem  mt-6">
            <h2 className="homestudent0 pl-2   mt-0 mb-0 m-0">
              Students<span className="homefeedback0"> Feedback</span>
            </h2>
            <p className=" feedbacktext0 pl-2 my-0">
              Here are some feedback comments from students.
            </p>

            <div className="card w-full ">
              <Splide
                hasTrack={false}
                className="pl-6 "
                options={{
                  // type: 'loop',
                  rewind: false,
                  autoplay: true,
                  perMove: 1,
                  perPage: 1,
                  // gap: '2rem',
                  arrows: true,
                  pagination: true,
                }}
              >
                <SplideTrack>
                  {products.map((el) => (
                    <SplideSlide>
                      <ProductTemplate product={el} />
                    </SplideSlide>
                  ))}
                </SplideTrack>

                <div className="splide__arrows">
                  <button className="splide__arrow splide__arrow--prev shadow-3 -ml-1">
                    <i
                      className="pi pi-angle-left"
                      style={{ fontSize: "1.2rem" }}
                    ></i>
                  </button>
                  <button className="splide__arrow splide__arrow--next shadow-3 ">
                    <i
                      className="pi pi-angle-right"
                      style={{ fontSize: "1.2rem", color: "white" }}
                    ></i>
                  </button>
                </div>
              </Splide>
            </div>
          </div>
          <br />
          <h1 className=" homefeatureshead0  mb-1 mt-5 px-4">
            Our Features and Services
          </h1>
          <h3 className=" text-left   homefeatureshead20 px-4">
            This platform provides access to courses, interactive content,
            personalised learning paths, live and recorded lectures,
            assessments, and making learning convenient and accessible from
            anywhere with an internet connection.
          </h3>
          <br />
          <ul className="list-none  w-full px-4   mt-5">
            <Splide
              hasTrack={false}
              className="m-auto"
              options={{
                // type: 'loop',
                rewind: false,
                autoplay: true,
                perMove: 1,
                perPage: 1,
                // gap: '2rem',
                arrows: true,
                pagination: true,
              }}
            >
              <SplideTrack>
                {features.map((each) => (
                  <SplideSlide>
                    <div className="h-9rem flex flex-column w-10 pl-2 m-auto p-2">
                      <div className="flex gap-2 align-items-center">
                        <each.icon className="h-1rem w-1rem " />
                        <h3 className="homefeaturehead3">{each.feature}</h3>
                      </div>

                      <p className="text-left " style={{fontFamily:"Poppins",fontSize:"12px",fontWeight:"400",color:"#000"}}>
                        {each.content}
                      </p>
                    </div>
                  </SplideSlide>
                ))}
              </SplideTrack>

              <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev shadow-3 -mt-3 -ml-4">
                  <i
                    className="pi pi-angle-left"
                    style={{ fontSize: "1.2rem" }}
                  ></i>
                </button>
                <button className="splide__arrow splide__arrow--next shadow-3 -mt-3 -mr-2">
                  <i
                    className="pi pi-angle-right"
                    style={{ fontSize: "1.2rem", color: "white" }}
                  ></i>
                </button>
              </div>
            </Splide>
          </ul>
          <div className="flex  flex-column  w-full  py-3 mt-6">
            <div className="w-full flex flex-column font-family px-4 align-items-start justify-content-center  ">
              <h1 className="best-grow-head0 my-1">Best Platform to grow</h1>
              <p className="w-full text-left mt-1 mb-2 best-grow-paragraph0 ">
                Empower your learning with flexible, expert-led courses and
                personalised support on the best e-learning platform for
                achieving your goals.
              </p>
            </div>
            <div className="w-full   justify-content-center ">
              <img src={BestPlatformtogrow} alt="img" width={"80%"} />
            </div>
            <Button
              label="Learn more"
              className="bg-greenn-500 align-self-center  my-1 best-grow-learnMore0"
              onClick={() => navigate("/online-classes/about-us")}
            />
          </div>
          {/* <div className="h-30rem mt-8 flex flex-column justify-content-center w-full my-7 py-3 pl-4 px-8">
          <div className="w-6 flex flex-column font-family  ml-0 pr-0 pl-6 align-items-start justify-content-center gap-2 h-full">
            <h1 className="best-grow-head  my-1">24/7 Students Support</h1>
            <p className="text-left  best-grow-paragraph text-600">
              With our 24/7 support, you can learn at your own pace, on your own
              schedule, with the peace of mind that comes from knowing help is
              always just a click away.
            </p>
          
            <Button
              label="Read more"
              className="bg-greenn-500 align-self-start h-3rem best-grow-learnMore my-1"
            />
          </div>
          <div className="w-full flex justify-content-end w-5">
            <img src={Girlwithmice} alt="img" className="h-full " />
          </div>
        </div> */}
          <Latestnews />
          <Footer />
        </div>
      )}
    </>
  );
}
