import "./navbar.css";
import { Button } from "primereact/button";
import WebsiteLogo from "../../asserts/WebsiteLogo.png";
import bbcolor from "../../asserts/bbColor.png"
import { Sidebar } from "primereact/sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import Signup from "../Signup/Signup";
import { TieredMenu } from 'primereact/tieredmenu';
import { Menu } from "primereact/menu";
import SignIn from "../../SelfComponent/components/SelfSignin/Signin";
import { useDispatch, useSelector } from "react-redux";
import { LogOutfuntion } from "../../Redux/SelfLearning/SelfAction/Auth.action";

function Navbar({background}) {
const navigate=useNavigate()
  const [visibleRight, setVisibleRight] = useState(false);
  const menu = useRef(null);
  const menu2=useRef(null)
  const {isAuth} =useSelector(store=>store.auth)
const dispatch=useDispatch()
  const items = [
    {
      label: 'Self Learning',
      icon: 'pi pi-book',
      command: () => {  dispatch(LogOutfuntion());navigate("/self-learning") }
  },
  {
      separator: true
  },
  {
    label: 'Online Classes',
    icon: 'pi pi-desktop',
    command: () => {navigate("/online-classes") }
},
{
    separator: true
},
{
  label: 'Office Programs',
  icon: 'pi pi-users',
  command: () => { dispatch(LogOutfuntion());navigate("/office-classes") }
}
  ]
  const items2 = [
    {
      label: 'About Us',
      icon: 'pi pi-book',
      command: () => {navigate("/online-classes/about-us") }
  },
  {
      separator: true
  },
  {
    label: 'Contact Us',
    icon: 'pi pi-desktop',
    command: () => { navigate("/online-classes/contact-us") }
},
{
    separator: true
},
{
  label: 'Became an Instructor',
  icon: 'pi pi-users',
  command: () => { navigate("/online-classes/instructor-registration") }
}
  ]
  return (
    <nav className={`w-full p-4 md:px-8 md:py-6 h-4rem md:h-4rem flex align-items-center  justify-content-between  ${background==="green"?'navdiv':"navdiv2"}`}>
    <Menu model={items} popup ref={menu} className="lftmnu" />
    <Menu model={items2} popup ref={menu2} className="mt-2 lftmnu smalllftmenu" style={{ width: '14rem' }}  />

        {background==="green"?<img src={WebsiteLogo} className="h-3rem  cursor-pointer" alt="logo"  onClick={()=>navigate("/online-classes")}/>:<img src={bbcolor} onClick={()=>navigate("/online-classes")} className="h-2rem cursor-pointer" alt="logo" />}
        {/* <ul className="list-none hidden justify-content-between  md:flex gap-2 justify-content-center align-items-center text-white">
        </ul> */}
  <ul className="list-none hidden w-8  ml-8 pl-8 -mr-8 md:flex justify-content-around text-white" >
        <span className="md:flex gap-2 justify-content-center align-items-center text-white" onClick={(e) => menu.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline cursor-pointer`} >Online Live Classes</li><i className={`pi pi-angle-down`} style={{ color: `${background=="green"?'white':'green'}`,cursor:"pointer" }}></i></span>
        <Link to="/online-classes/courses" className="no-underline text-white">
          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline`} >Courses</li>
        </Link>
      
        {/* <Link to="/online-classes/about-us" className="no-underline text-white">
          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline`}>About Us</li>
        </Link>

        <Link to="/online-classes/contact-us" className="no-underline text-white">
          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline`}>Contact Us</li>
        </Link> */}
        <Link to="/online-classes/cart" className="no-underline text-white flex justify-content-center align-items-center gap-2">
          {/* <i className="pi pi-cart-plus " style={{color:"#0E8E60"}}></i> */}
          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline`}>Cart</li>
        </Link>
        <Link to="/online-classes/blog" className="no-underline text-white">
          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline`}>Blog</li>
        </Link>
        <span className="md:flex gap-2 justify-content-center align-items-center text-white" onClick={(e) => menu2.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-lg no-underline cursor-pointer`} >Connect</li><i className={`pi pi-angle-down`} style={{ color: `${background=="green"?'white':'green'}`,cursor:"pointer" }}></i>
</span>
      </ul>
     {/* <Button className={`${background==='green'?'signupbtn' : 'signupbtn2'} hidden md:block pt-2`} >Sign up</Button>  */}
     <div className="hidden md:block ">
     {
     //isAuth? 
      //  <Button
      //   icon="pi pi-user"
      //   className={` mr-1`}
      //   aria-controls="popup_menu_right"
      //   aria-haspopup
      //   style={{
      //     backgroundColor: background=="green"?"#FFF":"#0E8E60",
      //     color:background=="green"?"#0E8E60":"#FFF",
      //     border: "none",
      //     borderRadius: "50%",
      //     outline: "none !important",
        
      //   }}
      // />
      //""
      //:<SignIn background={background}/>
      }
     </div>

      <Button
        icon="pi pi-bars"
        rounded
        text
        severity="success"
        aria-label="Cancel"
        className={`md:hidden flex ${background==='green'?'text-white':''}  text-2xl`}
        onClick={(e) => {
          setVisibleRight(true);
        }}
      />
      <Sidebar
        visible={visibleRight}
        closeIcon={<i className="pi pi-times" style={{ color: "white" }}></i>}
        position="right"
        className="sidebarofmobile w-6 navdiv"
        onHide={() => setVisibleRight(false)}
      >
<ul className="list-none flex flex-column w-full h-10rem justify-content-between p-0 pt-3  text-white">
        <span  className="flex gap-2 align-items-center text-white" onClick={(e) => menu.current.toggle(e)}>          <li className={`${background=="green"?'headers':'headers2'} text-sm text-white no-underline cursor-pointer`} >Online Classes</li><i className={`pi pi-angle-down`} style={{ color: "white",cursor:"pointer" }}></i></span>
           
          <Link to="/online-classes/courses" className="no-underline  text-white">
            <li className="headers text-sm">Courses</li>
          </Link>
          <Link to="/online-classes/about-us" className="no-underline  text-white">
            <li className="headers text-sm">About Us</li>
          </Link>
          <Link to="/online-classes/contact-us" className="no-underline text-white">
            <li className="headers text-sm">Contact Us</li>
          </Link>
          <Link to="/online-classes/cart" className="no-underline text-white ">
          {/* <i className="pi pi-cart-plus"></i> */}
          <li className="headers text-sm">Cart</li>
        </Link>
        <Link to="/online-classes/blog " className="no-underline  text-white">
            <li className="headers text-sm">Blog</li>
          </Link>
          <Link
            to="/online-classes/instructor-registration"
            className="no-underline text-white"
          >
            <li className="headers text-sm">Become an Instru...</li>
          </Link>
        </ul>
      {/* <Button className="signupbtn ">Sign up</Button>  */}

        {/* <Signup background={"green"}/> */}
      </Sidebar>
    </nav>
  );
}

export default Navbar;
