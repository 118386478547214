import React, { useEffect, useRef, useState } from "react";
import "./Checkout.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import bb_logo from "../../../../asserts/bb_logo.png";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import netbank from "../../../../asserts/Net_bank.png";
import credit from "../../../../asserts/credit.png";
import upi from "../../../../asserts/UPI.png";
import paytm from "../../../../asserts/Paytm.png";
import Rectangle from "../../../../asserts/robotPlaying.png";
import Navbar from "../../SelfNavbar";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import bbcolor from "../../../../asserts/bbColor.png";
import brain from "../../../../brain.gif"
import { useNavigate } from "react-router-dom";
import Footer from "../../Foot";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutApiCall, FetchCartFunction, PriceSchedule } from "../../../../Redux/SelfLearning/SelfAction/Cart.action";
import { Toast } from "primereact/toast";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import { Mainurl } from "../../../../Api";
// import 'react-phone-input-2/lib/style.css';
// import PhoneInput from 'react-phone-input-2';

const initial={
  first_name  : "",
  last_name   : "",
  phone_number: "",
  address     : "",
  country     : "",
  state       : "",
  pincode    : "",
  user_id: "" ,
  environment:"dev",
  redirect_url: ""

  }
export default function Checkout() {
  const {data,priceSchedule,checkoutdata,loading}=useSelector(store=>store.cart)
  const dispatch=useDispatch()
  console.log(data,"cart");
  useEffect(()=>{
    dispatch(FetchCartFunction("SL"))
    },[])
  const navigate = useNavigate();
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
const [billData,setBillData]=useState(initial)
const toast = useRef(null);
console.log(data,priceSchedule);
  const selectCountry = (val) => {
    setCountry(val);
    setBillData({...billData,["country"]:val})
  }
  const selectRegion=(val)=>{
    setRegion(val)
    setBillData({...billData,["state"]:val})

  }
  const handleChange=(e)=>{
    const {name,value}=e.target 
    setBillData({...billData,[name]:value})
    }

    const handleSubmit=async(e)=>{
e.preventDefault()
console.log(billData)
if(billData.first_name==="" || billData.last_name==="" || billData.address==="" || billData.country==='' || billData.state===''|| billData.pincode==="" || billData.phone_number==""  ){
  showError("Please fill all fields")
}
else{
  dispatch(CheckoutApiCall(billData))
}
    }
    const showError = (message) => {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: message,
        life: 3000,
      });
    };
  // console.log(data,"chdata");
    useEffect(()=>{
      if(data&&data.cart&&data.cart.course_id){
        // setBillData({...billData,["redirect_url"]:`http://dev2.brainybits.in/self-learning/mylearning`})
        setBillData({...billData,["redirect_url"]:`${Mainurl}/self-learning/selfbuythiscourse/${data&&data.cart&&data.cart.course_id}`})

        dispatch(PriceSchedule(data&&data.cart&&data.cart.course_id))
      }
    },[data])
    useEffect(()=>{
if(checkoutdata.pay_page_url){
window.location.href=checkoutdata.pay_page_url
}
    },[checkoutdata])
 
    const [value2, setValue2] = useState()
    const handleChange2=async(e)=>{
      if(e){
        console.log(e);
      
      //  setLoginData({...loginData,["phone_number"]:x})
       setBillData({...billData,["phone_number"]:e})

      }
     }

  return (
    <>     <Toast ref={toast} /> 
{data&&data.length==0 || priceSchedule.length==0?<div className="hidden md:flex justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
      <div className="hidden md:flex h-full">
        <div className="w-8 pt-4 pl-6 pr-6 h-full overflow-y-scroll card2">
          <div className="w-6 mb-2 flex justify-content-start pl-3" >
            {/* <Image
              src={bb_logo}
              height="70"
              style={{
                marginLeft: "22%",
              }}
            /> */}
            <img src={bbcolor} className="h-2rem cursor-pointer" alt="logo"  onClick={()=>navigate("/self-learning")}/>
          </div>
          <br />
          <div className="flex flex-column ml-4 pr-7">
            {/* <h1 className="checkoutttl1 text-left ">
              Checkout <span className="checkoutttl2">Page</span>
            </h1> */}
            
            <h3 className="checkoutformhead text-left my-0"><span className="checkoutformhead2">Billing</span> Address</h3>
            <form className="w-12 mt-3">
              <div className="flex gap-7 mb-2">
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl">
                    First name
                  </label>
                  <InputText
                    name="first_name" onChange={handleChange}
                    placeholder="First name"
                    className="w-full h-3rem border-round"
                  />
                </div>
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl">
                    Last name
                  </label>
                  <InputText
                    name="last_name" onChange={handleChange}
                    placeholder="Last name"
                    className="w-full h-3rem border-round"
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-column mb-2 gap-2 w-12">
                  <label className="text-sm text-left checkoutlbl">
                    Address
                  </label>
                  <InputText
                    name="address" onChange={handleChange}
                    placeholder="Address"
                    className="w-full h-3rem border-round"
                  />
                </div>
                <div className="flex gap-7 mb-2">
                  <div className="flex flex-column gap-2 w-6">
                    <label className="text-sm text-left checkoutlbl">
                      Country
                    </label>
                  
                          <CountryDropdown className="checkcountry border-round"  value={country} onChange={(val) => selectCountry(val)} />
                  </div>
                  <div className="flex flex-column gap-2 w-6">
                    <label className="text-sm text-left checkoutlbl">
                      State
                    </label>
                  
                      <RegionDropdown className="checkcountry border-round" country={country}  value={region} onChange={(val) => selectRegion(val)} />
                  </div>
                </div>
                <div className="flex  gap-7 mb-2">
                  <div className="flex flex-column gap-2 w-6">
                    <label className="text-sm text-left checkoutlbl">
                      Pincode
                    </label>
                    <InputText
                      name="pincode" onChange={handleChange}
                      placeholder="Pincode"
                      className="w-full h-3rem border-round"
                    />
                  </div>
                  <div className="flex flex-column gap-2 w-6">
                    <label className="text-sm text-left checkoutlbl">
                      Mobile
                    </label>
                    {/* <InputText
                      name="phone_number" onChange={handleChange}
                      placeholder="Mobile"
                      className="w-full h-3rem border-round"
                    /> */}
     <PhoneInput className="h-3rem border-round" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />

                  </div>
                </div>
              </div>
            </form>
            <span className="formfooterdesc text-left w-10 mt-3">
              BrainyBits is required by law to collect applicable transaction
              taxes for purchases made in certain tax jurisdictions.
            </span>
          </div>
          <br />
          {/* <div className="flex align-items-center pr-8">
            <h1 className="checkout_method text-left ml-4 w-5">
              Payment method
            </h1>
            <h3 className="cheeckout_conn text-right w-6 ">
              Secured connection{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="17"
                viewBox="0 0 16 19"
                fill="none"
              >
                <path
                  d="M14 6.22222H13V4.44444C13 1.99111 10.76 0 8 0C5.24 0 3 1.99111 3 4.44444V6.22222H2C0.9 6.22222 0 7.02222 0 8V16.8889C0 17.8667 0.9 18.6667 2 18.6667H14C15.1 18.6667 16 17.8667 16 16.8889V8C16 7.02222 15.1 6.22222 14 6.22222ZM8 14.2222C6.9 14.2222 6 13.4222 6 12.4444C6 11.4667 6.9 10.6667 8 10.6667C9.1 10.6667 10 11.4667 10 12.4444C10 13.4222 9.1 14.2222 8 14.2222ZM11.1 6.22222H4.9V4.44444C4.9 2.92444 6.29 1.68889 8 1.68889C9.71 1.68889 11.1 2.92444 11.1 4.44444V6.22222Z"
                  fill="black"
                />
              </svg>
            </h3>
          </div>
          <br />
          <div className="w-11 pr-5 pl-2">
            <Accordion
              className="checkoutacc"
              expandIcon={
                <div className="ml-2 mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="9.75"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                  </svg>
                </div>
              }
              collapseIcon={
                <div className="ml-2 mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="9.75"
                      fill="#0E8E60"
                      stroke="black"
                      stroke-width="0.5"
                    />
                  </svg>
                </div>
              }
            >
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    // style={{ width: "48rem"}}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr ml-6">Net Banking</span>
                    <Image src={netbank} height="20" className="mr-3" />
                  </div>
                }
              >
                <p className="m-0">
                  In order to complete your transaction, we will transfer you
                  over to Adyen's secure servers.
                  <Accordion
                    className=" accordion-container "
                    expandIcon="pi pi-chevron-right custom-icon"
                    collapseIcon="pi pi-chevron-down custom-icon"
                  >
                    <AccordionTab
                      className="accbd p-0"
                      header={
                        <div className="check90 text-left p-0">Select Your bank</div>
                      }
                    >
                      <p className="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </AccordionTab>
                  </Accordion>
                </p>
              </AccordionTab>
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    // style={{ width: "48rem" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr ml-6">Credit Card</span>
                    <Image src={credit} width="150" height="20" />
                  </div>
                }
              >
                <form className="">
                  <div className="flex flex-column gap-2 ">
                    <label className="text-sm text-left checkoutlbl2 ">
                      Name on Card
                    </label>
                    <InputText
                      name="firstName"
                      placeholder="Name on Card"
                      className="w-full h-3rem "
                    />
                  </div>
                  <div className="flex flex-column gap-2  mt-3">
                    <label className="text-sm text-left checkoutlbl2 ">
                      Card Number
                    </label>
                    <InputText
                      type="number"
                      name="firstName"
                      placeholder="1234 4567 7890 1234"
                      className="w-full h-3rem "
                      maxLength={16}
                    />
                  </div>
                  <div className="flex mt-3 gap-7 mb-3">
                    <div className="flex flex-column gap-2 w-6">
                      <label className="text-sm text-left checkoutlbl2 ">
                        Expiry date
                      </label>
                      <InputText
                        name="firstName"
                        placeholder="MM/YY"
                        className="w-full h-3rem "
                      />
                    </div>
                    <div className="flex flex-column gap-2 w-6">
                      <label className="text-sm text-left checkoutlbl2 ">
                        CVC
                      </label>
                      <InputText
                        name="firstName"
                        placeholder="CVC"
                        className="w-full h-3rem "
                      />
                    </div>
                  </div>
                  <div className="flex justify-content-start gap-2">
                    <Checkbox></Checkbox>
                    <span className="">Save this card for later purchase</span>
                  </div>
                </form>
              </AccordionTab>
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    // style={{ width: "48rem" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr ml-6">UPI</span>
                    <Image src={upi} height="20" />
                  </div>
                }
              >
                <div className="flex flex-column gap-2 ">
                  <label className="text-sm text-left checkoutlbl2 ">
                    Make a selection
                  </label>
                  <div className="upi1">
                    <div className="upi2 w-6">
                      <span className="">Virtual payment address</span>
                    </div>
                    <div className="upi3 w-6">
                      <span className=" ">QR code</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column gap-2 mt-3">
                  <label className="text-sm text-left checkoutlbl2 ">
                    Virtual payment address
                  </label>
                  <InputText />
                </div>
              </AccordionTab>
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    // style={{ width: "48rem", height: "100%" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr ml-6">Paytm</span>
                    <Image src={paytm} height="20" />
                  </div>
                }
              >
                <p className="m-0">
                  In order to complete your transaction, we will transfer you
                  over to Adyen's secure servers.
                </p>
              </AccordionTab>
            </Accordion>
          </div> */}
        <br/>
          <div className="flex flex-column ml-4 w-12 ">
            <h1 className="checkoutttl1 text-left ">
              Order <span className="checkoutttl2">Details</span>
            </h1>
            <div className="itemContainer itemContainer2 w-11 gap-3 flex my-2 justify-content-between align-items-center  py-3" >
            <div className="w-2 h-full border-round-lg ml-3">
              <img src={data&&data.cart&&data.cart.course_image} style={{borderRadius:"10px",objectFit:"contain"}}   width="100%" />
            </div>
            <div className="w-10  flex flex-column   ">
              <h3 className="nametutor text-left my-0">{data&&data.cart&&data.cart.instructor_name}</h3>
              <h2 className="namecourse text-left my-0">
              {data&&data.cart&&data.cart.course_name}
              </h2>
              <h3 className="text-left icn flex gap-1 my-0">
                <i
                  style={{ color:`${data&&data.cart&&data.cart.rating>=1?"#0E8E60":"#E0E0E0"}` , fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color: `${data&&data.cart&&data.cart.rating>=2?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color: `${data&&data.cart&&data.cart.rating>=3?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color:`${data&&data.cart&&data.cart.rating>=4?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color: `${data&&data.cart&&data.cart.rating>=5?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                ({data&&data.cart&&data.cart.num_of_reviews})
              </h3>
              <h2 className="pricecourse text-left mt-1">₹ {data&&data.cart&&data.cart.amount}</h2>
            </div>
           
          </div>
          </div>
          <br/>
          <br/>
        </div>
        <div
          className="w-4  pt-8 pl-8 pr-8 "
          style={{ backgroundColor: "#0E8E60" }}
        >
          <div className=" sticky  top-0 flex flex-column mt-8">
            <h1 className="pricesummary text-left my-0">Summary</h1>
            <br />
            <div className="flex justify-content-between mb-2">
              <h3 className="orgpricetext text-left mt-0">Original price :</h3>
              <h3 className="orgpricenumber mt-0 text-right">₹  {priceSchedule && priceSchedule.sub_total !== undefined ? priceSchedule.sub_total.toFixed(2) : 'N/A'}</h3>
            </div>
            <div className="flex justify-content-between mb-2">
              <h3 className="orgpricetextg text-left mt-0">GST :</h3>
              <h3 className="orgpricenumberg mt-0 text-right">₹  {priceSchedule && priceSchedule.GST !== undefined ? priceSchedule.GST.toFixed(2) : 'N/A'}</h3>
            </div>
            <hr className="checkoutline w-full" />
            <div className="flex justify-content-between mt-2 mb-2">
              <h3 className="totlpricetext my-0 text-left">Total :</h3>
              <h3 className="totlpricenumber my-0 text-right">₹  {priceSchedule && priceSchedule.total !== undefined ? priceSchedule.total : 'N/A'}</h3>
            </div>
            <span className="proceeddesc">
              By completing your purchase you agree to Our Terms & Conditions
            </span>
            <br />
            {loading? <Button className="proceedbtn w-8  m-auto" type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#0E8E60" }}></i>} />:         <Button className="proceedbtn w-8  m-auto" onClick={handleSubmit}>
              Proceed
            </Button>}

            {/* <Button className="proceedbtn w-8  m-auto" onClick={handleSubmit}>
              Proceed
            </Button> */}
          </div>
        </div>
       
      </div>
}
{data&&data.length==0 || priceSchedule.length==0?<div className="flex md:hidden justify-content-center align-items-center h-25rem" ><img src={brain} width={80} className="md:hidden m-auto"   ></img></div>:
      <div className="block md:hidden h-full">
        <div className="w-full   h-full overflow-y-scroll card2">
          {/* <div className='w-6 'onClick={()=>navigate("/")}><Image src={bb_logo} height='70' /></div> */}
          <Navbar />
          <br />
          <div className="flex pl-4 pr-4 flex-column  w-full">
            <h1 className="checkoutttl10 text-left my-0">
              Checkout <span className="checkoutttl20">Page</span>
            </h1>
            <br />
            <h3 className="checkoutformhead0 text-left my-0">
              Billing Address
            </h3>
            <form className="w-full">
              <div className="flex mt-2 gap-1 mb-3">
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl0">
                    First name
                  </label>
                  <InputText
                    name="first_name"  onChange={handleChange}
                    placeholder="First name"
                    className="w-full h-3rem border-round"
                  />
                </div>
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl0">
                    Last name
                  </label>
                  <InputText
                    name="last_name"  onChange={handleChange}
                    placeholder="Last name"
                    className="w-full h-3rem border-round"
                  />
                </div>
              </div>

              <div className="flex flex-column mb-3 gap-2 w-full">
                <label className="text-sm text-left checkoutlbl0">
                  Address
                </label>
                <InputText
                 name="address" onChange={handleChange}
                  placeholder="Address"
                  className="w-full h-3rem border-round"
                />
              </div>
              <div className="flex gap-1 mb-3">
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl0">
                    Country
                  </label>
                  <CountryDropdown className="checkcountry border-round"  value={country} onChange={(val) => selectCountry(val)} />

                </div>
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl0">
                    State
                  </label>
                  <RegionDropdown className="checkcountry border-round" country={country}  value={region} onChange={(val) => selectRegion(val)} />
                </div>
              </div>
              <div className="flex  gap-1 mb-3">
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl0">
                    Pincode
                  </label>
                  <InputText
                  name="pincode" onChange={handleChange}
                    placeholder="Pincode"
                    className="w-full h-3rem border-round"
                  />
                </div>
                <div className="flex flex-column gap-2 w-6">
                  <label className="text-sm text-left checkoutlbl0">
                    Mobile
                  </label>
                  {/* <InputText
                  name="phone_number" onChange={handleChange}
                    placeholder="Mobile"
                    className="w-full h-3rem border-round"
                  />  */}
              <PhoneInput className="h-3rem border-round" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />

                </div>
              </div>
            </form>
            <span className="formfooterdesc0 text-left w-full">
              BrainyBits is required by law to collect applicable transaction
              taxes for purchases made in certain tax jurisdictions.
            </span>
          </div>
          <br />
          {/* <div className="flex pl-4 pr-4 align-items-center ">
            <h1 className="checkout_method0 text-left  w-6">Payment method</h1>
            <h3 className="cheeckout_conn0 text-right w-6 ">
              Secured connection{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <path
                  d="M9.71887 4H9.07768V2.85714C9.07768 1.28 7.64141 0 5.87172 0C4.10203 0 2.66576 1.28 2.66576 2.85714V4H2.02457C1.31926 4 0.742188 4.51429 0.742188 5.14286V10.8571C0.742188 11.4857 1.31926 12 2.02457 12H9.71887C10.4242 12 11.0013 11.4857 11.0013 10.8571V5.14286C11.0013 4.51429 10.4242 4 9.71887 4ZM5.87172 9.14286C5.16641 9.14286 4.58934 8.62857 4.58934 8C4.58934 7.37143 5.16641 6.85714 5.87172 6.85714C6.57703 6.85714 7.15411 7.37143 7.15411 8C7.15411 8.62857 6.57703 9.14286 5.87172 9.14286ZM7.85942 4H3.88403V2.85714C3.88403 1.88 4.77528 1.08571 5.87172 1.08571C6.96816 1.08571 7.85942 1.88 7.85942 2.85714V4Z"
                  fill="black"
                  fill-opacity="0.8"
                />
              </svg>
            </h3>
          </div>
          <br />
          <div className="w-full -mt-1 pl-4 pr-4">
            <Accordion
              className="checkoutacc"
              expandIcon={
                <div className="ml-2 mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="9.75"
                      fill="white"
                      stroke="black"
                      stroke-width="0.5"
                    />
                  </svg>
                </div>
              }
              collapseIcon={
                <div className="ml-2 mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="9.75"
                      fill="#0E8E60"
                      stroke="black"
                      stroke-width="0.5"
                    />
                  </svg>
                </div>
              }
            >
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    style={{ width: "65vw" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr0 ml-3">Net Banking</span>
                    <Image src={netbank} height="25" />
                  </div>
                }
              >
                <p className="m-0 text-left">
                  In order to complete your transaction, we will transfer you
                  over to Adyen's secure servers.
                  <Accordion
                    className=" accordion-container "
                    expandIcon={
                      <div className="ml-2 mr-2 mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="9.75"
                            fill="white"
                            stroke="black"
                            stroke-width="0.5"
                          />
                        </svg>
                      </div>
                    }
                    collapseIcon={
                      <div className="ml-2 mr-2 mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="9.75"
                            fill="#0E8E60"
                            stroke="black"
                            stroke-width="0.5"
                          />
                        </svg>
                      </div>
                    }
                  >
                    <AccordionTab
                      className="accbd p-0"
                      header={
                        <div className="check90 p-0">Select Your bank</div>
                      }
                    >
                      <p className="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </AccordionTab>
                  </Accordion>
                </p>
              </AccordionTab>
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    style={{ width: "65vw" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr0 ml-3">Credit Card</span>
                    <Image src={credit} width="120" height="20" />
                  </div>
                }
              >
                <form className="">
                  <div className="flex flex-column gap-2 ">
                    <label className="text-sm text-left checkoutlbl2 ">
                      Name on Card
                    </label>
                    <InputText
                      name="firstName"
                      placeholder="Name on Card"
                      className="w-full h-3rem "
                    />
                  </div>
                  <div className="flex flex-column gap-2  mt-3">
                    <label className="text-sm text-left checkoutlbl2 ">
                      Card Number
                    </label>
                    <InputText
                      type="number"
                      name="firstName"
                      placeholder="1234 4567 7890 1234"
                      className="w-full h-3rem "
                      maxLength={16}
                    />
                  </div>
                  <div className="flex mt-3 gap-3 mb-3">
                    <div className="flex flex-column gap-2 w-6">
                      <label className="text-sm text-left checkoutlbl2 ">
                        Expiry date
                      </label>
                      <InputText
                        name="firstName"
                        placeholder="MM/YY"
                        className="w-full h-3rem "
                      />
                    </div>
                    <div className="flex flex-column gap-2 w-6">
                      <label className="text-sm text-left checkoutlbl2 ">
                        CVC
                      </label>
                      <InputText
                        name="firstName"
                        placeholder="CVC"
                        className="w-full h-3rem "
                      />
                    </div>
                  </div>
                  <div className="flex justify-content-start gap-2">
                    <Checkbox></Checkbox>
                    <span className="">Save this card for later purchase</span>
                  </div>
                </form>
              </AccordionTab>
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    style={{ width: "65vw" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr0 ml-3">UPI</span>
                    <Image src={upi} height="18" />
                  </div>
                }
              >
                <div className="flex flex-column gap-2 ">
                  <label className="text-sm text-left checkoutlbl2 ">
                    Make a selection
                  </label>
                  <div className="upi1">
                    <div className="upi2 w-6">
                      <span className="">Virtual payment address</span>
                    </div>
                    <div className="upi3 w-6">
                      <span className=" ">QR code</span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column gap-2 mt-3">
                  <label className="text-sm text-left checkoutlbl2 ">
                    Virtual payment address
                  </label>
                  <InputText />
                </div>
              </AccordionTab>
              <AccordionTab
                className="checkoutacctab"
                header={
                  <div
                    style={{ width: "65vw", height: "100%" }}
                    className="flex justify-content-between align-items-center"
                  >
                    <span className="checkoutacrhdr0 ml-3">Paytm</span>
                    <Image src={paytm} height="18" />
                  </div>
                }
              >
                <p className="m-0">
                  In order to complete your transaction, we will transfer you
                  over to Adyen's secure servers.
                </p>
              </AccordionTab>
            </Accordion>
          </div> */}
          <br />

          <div className="flex flex-column pl-4 pr-4">
            <h1 className="checkoutttl10 text-left mt-0 ">
              Order <span className="checkoutttl20">details</span>
            </h1>

            <div
              style={{ height: "175px" }}
              className="itemContainer flex pt-5 pb-5 gap-1 justify-content-between align-items-center"
            >
              <div className="w-5 h-full border-round-lg ">
                <img src={data&&data.cart&&data.cart.course_image} style={{borderRadius:"12px",objectFit:"contain"}} width="100%" height="90%" />
              </div>
              <div className="w-7 h-full flex flex-column pt-2 pl-2 ">
                <h3 className="nametutor0 text-left my-0">{data&&data.cart&&data.cart.instructor_name}</h3>
                <h2 className="namecourse0 text-left my-0" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
                {data&&data.cart&&data.cart.course_name}
                </h2>
                <h3 className="text-left icn0 flex gap-1 my-1">
                <i
                  style={{ color:`${data&&data.cart&&data.cart.rating>=1?"#0E8E60":"#E0E0E0"}` , fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color: `${data&&data.cart&&data.cart.rating>=2?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color: `${data&&data.cart&&data.cart.rating>=3?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color:`${data&&data.cart&&data.cart.rating>=4?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                <i
                  style={{ color: `${data&&data.cart&&data.cart.rating>=5?"#0E8E60":"#E0E0E0"}`, fontSize: "0.65rem" }}
                  className="pi pi-star-fill  "
                ></i>
                ({data&&data.cart&&data.cart.num_of_reviews})
                </h3>
                <h2 className="pricecourse0 text-left  mb-0 mt-1">
                  ₹ {data&&data.cart&&data.cart.amount}
                </h2>
              </div>
              {/* <div className='w-2 h-full flex flex-column justify-content-between pt-4 pb-4'>
        <h3 className='rmv0 text-right'>Remove</h3>
        <h3 className='rmv0 text-right'>Save for Later</h3>

     </div> */}
            </div>
  
          </div>
          <div className="flex flex-column pl-4 pr-4  mb-4">
            <h1 className="pricesummary0 text-left my-0">Summary</h1>
            <div className="flex justify-content-between mt-2 mb-1">
              <h3 className="orgpricetext0 mb-0 text-left">Original price :</h3>
              <h3 className="orgpricenumber0 text-right mb-0">₹ {priceSchedule && priceSchedule.sub_total !== undefined ? priceSchedule.sub_total.toFixed(2) : 'N/A'}</h3>
            </div>
            <div className="flex justify-content-between my-1">
              <h3 className="orgpricetext0 mb-0 text-left">GST :</h3>
              <h3 className="orgpricenumber0 text-right mb-0">₹ {priceSchedule && priceSchedule.GST !== undefined ? priceSchedule.GST.toFixed(2) : 'N/A'}</h3>
            </div>
            {/* <hr className="checkoutline0 w-full" /> */}
            <div className="flex justify-content-between mt-1 mb-2">
              <h3 className="totlpricetext0 my-0 text-left">Total :</h3>
              <h3 className="totlpricenumber0 my-0 text-right">₹ {priceSchedule && priceSchedule.total !== undefined ? priceSchedule.total : 'N/A'}</h3>
            </div>
            <span className="proceeddesc0">
              By completing your purchase you agree to Our Terms & Conditions
            </span>
            <br />
            {/* <Button className="proceedbtn0 w-8 m-auto"  onClick={handleSubmit}>
              Proceed
            </Button> */}
           
                {loading? <Button className="proceedbtn0 w-6  m-auto" type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} />:         <Button className="proceedbtn0 w-6  m-auto" onClick={handleSubmit}>
              Proceed
            </Button>}
          </div>
        </div>
       
      </div>}
      <Footer/>

    </>
  );
}
