import React, { useEffect, useRef, useState } from 'react'
import "./CreateBlog.css"
import { Image } from 'primereact/image'
import { InputText } from "primereact/inputtext";
import { Editor } from 'primereact/editor';
import CreateBlog1 from '../../asserts/CreateBlog1.png'
import ConfirmModal from './Confirm';
import OfflineNavbar from '../navbar/navbar';
import { Dropdown } from 'primereact/dropdown';
import OfflineFooter from '../Footer/Footer';
// import { environment_for_Api } from '../apis';

// let localData = localStorage.getItem('Brainy_data');
// let localId = localData ? JSON.parse(localData).id : null;
// "SRI00523-7099-95F0"
let initial={"environment":"dev", "id":"SRI00523-7099-95F0",title:"",title_image:"",content:"",category:""}
export default function OfflineCreateBlog() {
  const [Select,setSelect]=useState(null)
  const cities = [
    { name: 'Business & GDP', code: 'Business & GDP' },
    { name: 'Politics', code: 'Politics' },
    { name: 'Movies & Art', code: 'Movies & Art' },
    { name: 'AI & Data Science', code: 'AI & Data Science' },
    { name: 'Space Science & Technology', code: 'Space Science & Technology' },
    { name: 'Sports', code: 'Sports' },
    { name: 'LifeStyle', code: 'LifeStyle' },
    { name: 'Education', code: 'Education' }

];
    const [article,setArticle]=useState("")
const [data, setData] = useState(initial)

const handleEditorChange = (e) => {
    const htmlContent = e.htmlValue;
    setArticle(htmlContent)
  };
  const handleChange=async(e)=>{
const {name,value}=e.target

if(name==='title_image'){
  //console.log(e.target.files[0])
  const base64String = await fileToBase64(e.target.files[0]);
  // console.log(base64String)
  setData({...data,[name]:base64String})
}
else{
  setData({...data,[name]:value})

}
 // console.log(name,value,data,Select)


  }

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
<div className="h-full w-full flex-column">

    <OfflineNavbar />
    <div className='hidden md:flex flex-column h-full overflow-y-scroll p-8'>

        <div id='firstdiv' className='flex justify-content-center align-items-center gap-8 '>
             <div><Image src={CreateBlog1} /></div>
             <div style={{width:"355px"}}><h1 id='crthdg1' className='text-left'>Write Your<br/><span style={{color:"#0E8E60"}}>Article</span><br/>here...</h1></div>
        </div>

        <div className='flex flex-column  pl-8 pr-8'>
            <form className='flex flex-column gap-4 pl-8 pr-8'>
            <div className="flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Title</label>
                <InputText  id="title" name='title' onChange={handleChange} aria-describedby="title" />
            </div>
            <div  className=" w-full flex  gap-6">
            <div className="w-6 flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Blog image</label>
                <span className="p-input-icon-right w-full h-3rem">
              <i style={{border:"1px solid",padding:"1px"}} className="pi pi-plus " />
                <InputText type='file' accept="image/png, image/gif, image/jpeg, image/jpg" placeholder='Upload Image' id="title" name='title_image' onChange={handleChange} aria-describedby="title" className='w-full' />
                </span>
            </div>
            <div className=" w-6 flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Category</label>
                {/* <InputText  id="title" name='title' onChange={(e)=>{setTitle(e.target.value)}} aria-describedby="title" /> */}
                <Dropdown value={Select} name='category' onChange={(e)=>setSelect(e.value)} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" />
            </div>
            </div>
            <div className="flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Article</label>
                <Editor className=' border-round-3xl text-left' name='content' onTextChange={handleEditorChange} style={{ height: '320px' }} />
            </div>
            <div className='flex justify-content-end align-items-center'>
               <ConfirmModal data={{...data,'content':article,'category':Select?Select.name:""}} />
               </div>
            </form>
        </div>
      
        
    </div>

    
    <div  className='flex md:hidden flex-column h-full overflow-y-scroll  mt-8 mb-4'>

        <div id='firstdiv' className='w-full flex flex-column justify-content-center align-items-center  '>
             <div><Image src={CreateBlog1} width='100%' /></div>
             <div  className='w-full text-center'><h1 id='crthdg1_0'>Write Your<br/><span style={{color:"#0E8E60"}}>Article</span><br/>here...</h1></div>
        </div>

        <div className='w-full flex flex-column mt-8 '>
            <form className=' flex flex-column gap-2 pl-2 pr-2 '>
            <div className=" flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Title</label>
                <InputText  id="title" name='title' onChange={handleChange} aria-describedby="title" />
            </div>
            <div  className=" w-full flex  flex-column gap-2">
            <div className="w-full flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Blog image</label>
                <span className="p-input-icon-right w-full h-3rem">
              <i style={{border:"1px solid grey",padding:"1px"}} className="pi pi-plus " />
                <InputText type='file' accept="image/png, image/gif, image/jpeg, image/jpg" placeholder='Upload Image' id="title" name='title_image' onChange={handleChange} aria-describedby="title" className='w-full' />
                </span>
            </div>
            <div className=" w-full flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Category</label>
                {/* <InputText  id="title" name='title' onChange={(e)=>{setTitle(e.target.value)}} aria-describedby="title" /> */}
                <Dropdown value={data.category} name='category' onChange={handleChange} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" />
            </div>
            </div>
            <div className="flex flex-column gap-2">
                <label id='lbl' htmlFor="username" className='text-left'>Article</label>
                <Editor className='w-full border-round-3xl text-left' name='content' onTextChange={handleEditorChange} style={{ height: '320px' }} />
            </div>
            <div className='flex justify-content-end align-items-center'>
               <ConfirmModal data={{...data,'content':article,'category':Select?Select.name:""}} />
               </div>
            </form>
        </div>
      
        
    </div>
  <OfflineFooter/>

</div>

  )
}
