export const environment = "dev";
export const Mainurl = "https://www.brainybits.in/";
// export const Mainurl='http://localhost:3000'
export const signupApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/signup";
export const ReviewApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/course-review";
export const instructorEnquiry =
  "https://y8jsdm6ey0.execute-api.ap-south-1.amazonaws.com/Prod/instructor-enquiry";

export const resetPasswordApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/reset-password";
export const updatePasswordApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/update-password";
export const forgotPasswordApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/forgot-password";
export const loginApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/login";
export const fetchProfileApi =
  "	https://19cyur7ehk.execute-api.ap-south-1.amazonaws.com/Prod/fetch-profile";
export const updateProdileApi =
  "https://19cyur7ehk.execute-api.ap-south-1.amazonaws.com/Prod/update-profile";
export const updateProfileImageApi =
  "https://19cyur7ehk.execute-api.ap-south-1.amazonaws.com/Prod/update-profile";
export const fetchCoursePageApi =
  "https://nyajn14dsg.execute-api.ap-south-1.amazonaws.com/Prod/slhomepage";
export const enquiryApi =
  "https://twnrhx7f3a.execute-api.ap-south-1.amazonaws.com/Prod/contact-us";
export const fetchCourseListApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/sl-courses-list";
export const fetchCourseDetailsApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/continue-learning";
export const individualCourseDetailsApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/sl-course-details";
export const addtoCartApi =
  "https://23d8i6duf7.execute-api.ap-south-1.amazonaws.com/Prod/add-cart";
export const fetchCartApi =
  "	https://23d8i6duf7.execute-api.ap-south-1.amazonaws.com/Prod/fetch-cart";
export const removeCartApi =
  "https://23d8i6duf7.execute-api.ap-south-1.amazonaws.com/Prod/remove-cart";
export const priceSchedulApi =
  "https://imn76pp9zc.execute-api.ap-south-1.amazonaws.com/Prod/price-schedule";
export const checkOutApi =
  "https://imn76pp9zc.execute-api.ap-south-1.amazonaws.com/Prod/pay-page";
export const watchhistoryupdateApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/watch-history-update";
export const certificategenerateApi =
  "https://9t8b3tf2ea.execute-api.ap-south-1.amazonaws.com/Prod/certificate-generate";
export const recentpaymentstatusApi =
  "https://imn76pp9zc.execute-api.ap-south-1.amazonaws.com/Prod/recent-payment-status";

export const fetchblogApi =
  "https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/fetch-all-blogs";
export const fetchIndividualBlogApi =
  "https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/fetch-blog-post";
export const LikeBlogApi =
  "	https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/like-blog";
export const dislikeBlogApi =
  "https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/unlike-blog";
export const commentBlogApi =
  "https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/comment-blog";
export const fetchblogcategorywiseApi =
  "https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/list-blogs-categorywise";
export const DynamicCourseApi =
  "https://nyajn14dsg.execute-api.ap-south-1.amazonaws.com/Prod/popular-sl-course";

/**onlinr */
export const onlineSignupApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/signup";
export const onlineHomepageApi =
  "https://nyajn14dsg.execute-api.ap-south-1.amazonaws.com/Prod/olhomepage";
export const onlineIndividualCourseDetailsApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/ol-course-details";
export const onlineDynamicCourseApi =
  "https://nyajn14dsg.execute-api.ap-south-1.amazonaws.com/Prod/popular-ol-course";
export const onlinefetchbatchaccrdtocourseApi =
  "https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-batches";
export const onlinerecentpaymentStatusApi =
  "https://imn76pp9zc.execute-api.ap-south-1.amazonaws.com/Prod/recent-payment-status";
export const onlineforgotpasswordApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/forgot-password";

export const offlinehomepage_Api =
  "https://nyajn14dsg.execute-api.ap-south-1.amazonaws.com/Prod/ophomepage";
export const offlineIndividualCourseDetailsApi =
  "https://hm91waz49h.execute-api.ap-south-1.amazonaws.com/Prod/op-course-details";
export const offlineDynamicCourseApi =
  "https://nyajn14dsg.execute-api.ap-south-1.amazonaws.com/Prod/popular-op-course";
export const offlinefetchbatchesacctocourseApi =
  "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/fetch-batches";
export const offlinerecentpaymentStatusApi =
  "https://imn76pp9zc.execute-api.ap-south-1.amazonaws.com/Prod/recent-payment-status";
export const offlineforgotpasswordApi =
  "https://0v5exi80p1.execute-api.ap-south-1.amazonaws.com/Prod/forgot-password";
