import React, { useEffect, useState } from "react";
import backgroundImage from "../../../utils/selfpageimgs/testimonial.png";
import startsimg from "../../../utils/selfpageimgs/Group 29.svg";
import vector from "../../../utils/selfpageimgs/Vector2.png";

const Testimonial = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 1030);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div
        className={` ${isSmallScreen ? "px-0 mt-4" : "px-8"} `}
        style={{
          display: isSmallScreen ? "" : "flex",
          paddingTop: isSmallScreen ? "6%" : "0%",

          // border:"1px solid"
        }}
      >
        <div
          className={`content ${isSmallScreen ? "pl-0" : "pl-4"} `}
          style={{
            width: isSmallScreen ? "100%" : "50%",
            display: "flex",
            justifyContent: "start",
            marginBottom: "8%",
            overflowX: "hidden",
            // border:"1px solid red"
          }}
        >
          <div
            className={`testimonial ${isSmallScreen ? "pl-4" : "pl-6"}`}
            style={{ width: isSmallScreen ? "80%" : "50%" }}
          >
            <div
              className="testtitle d-flex justify-content-inline"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                //  border:"1px solid blue",
                width: isSmallScreen ? "50%" : "",
              }}
            >
              <hr
                style={{
                  width: isSmallScreen ? "35px" : "80px",
                  height: isSmallScreen ? "0.5px" : "1px",
                  textAlign: isSmallScreen ? "start" : "",
                  backgroundColor: "#525596",
                  color: isSmallScreen ? "#525596" : "#000",
                }}
              />
              <p
                style={{
                  color: "#0E8E60",
                  fontFamily: "Inter",
                  fontSize: isSmallScreen ? "10px" : "20px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: isSmallScreen ? "2px" : "4px",
                }}
              >
                TESTIMONIAL
              </p>
            </div>
            <div className={`testtext text-left ${isSmallScreen ? "" : ""}`}>
              <h1
                style={{
                  width: "430px",
                  color: "#000",
                  fontFamily: "Inter",
                  fontSize: isSmallScreen ? "16px" : "50px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  marginTop: isSmallScreen ? "8px" : "0",
                }}
              >
                What They Say?
              </h1>
              <p
                style={{
                  width: isSmallScreen ? "80%" : "401px",
                  color: "#000",
                  textAlign: "justify",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen ? "12px" : "24px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "145%",
                  marginTop: isSmallScreen ? "8px" : "0",
                  // border:"1px solid red"
                }}
              >
                Join our community of enthusiastic learners who are eager to
                make a difference. Together, we'll build a thriving hub of
                knowledge and personal growth.
              </p>
              <h2
                style={{
                  width: isSmallScreen ? "243px" : "396px",
                  color: "#252641",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen ? "10px" : "26px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "160%",
                  letterSpacing: isSmallScreen ? "0.1px" : "0.52px",
                  paddingTop: isSmallScreen ? "0%" : "54%",
                  marginTop: isSmallScreen ? "6px" : "0",
                }}
              >
                Are you too? Please give your feedback
              </h2>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div
                  style={{
                    color: "#0E8E60",
                    fontFamily: "Poppins",
                    fontSize: isSmallScreen ? "8px" : "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "160%",
                    letterSpacing: isSmallScreen ? "0.18px" : "0.44px",
                    border: "1px solid #0E8E60",
                    width: isSmallScreen ? "70%" : "110%",
                    padding: isSmallScreen ? "12px 8px" : "12px",
                    display: "inline-block",
                    borderRadius: "40px",
                    position: "relative", // Add relative positioning
                    marginTop: isSmallScreen ? "8px" : "20px",
                  }}
                >
                  Write{" "}
                  <span
                    style={{ display: isSmallScreen ? "inline" : "inline" }}
                  >
                    Your Feedback
                  </span>{" "}
                  <span style={{ display: isSmallScreen ? "inline" : "none" }}>
                    Here
                  </span>
                  <button
                    style={{
                      width: isSmallScreen ? "30px" : "35px", // Adjust the width for small screens
                      height: isSmallScreen ? "30px" : "35px", // Adjust the height for small screens
                      border: "0.5px solid #0E8E60",
                      borderRadius: "50%",
                      backgroundColor: "#fff",
                      position: "absolute",
                      top: "50%", // Center the button vertically
                      right: "0px", // Align to the right corner
                      transform: "translateY(-50%)", // Adjust for vertical centering
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: isSmallScreen ? "20px" : "24px", // Remove padding for smaller button
                      color: "#0E8E60",
                      cursor: "pointer",
                    }}
                  >
                    {isSmallScreen ? (
                      ">"
                    ) : (
                      <img src={vector} height={20} alt="..." />
                    )}
                    {/* <img src={vector} alt="..." /> */}
                    {/* Using text content instead of an image */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="image"
          style={{
            width: isSmallScreen ? "100%" : "50%",
            height: isSmallScreen ? "281.517px" : "", // Set a height to the container for visibility
            // border:"1px solid blue",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <img
            src={backgroundImage}
            alt="..."
            style={{
              borderRadius: isSmallScreen ? "10px" : "20px",
              width: isSmallScreen ? "233.057px" : "",
              height: isSmallScreen ? "281.517px" : "520px",
              marginLeft: isSmallScreen ? "20%" : "-30%",
            }}
          />
          <div
            style={{
              width: isSmallScreen ? "231px" : "90%",
              // height: isSmallScreen ? "144px" : "",
              flexShrink: 0,
              borderRadius: isSmallScreen ? "5px" : "0px 20px 20px 0px",
              background: "#0E8E60",
              boxShadow: "2px 4px 60px 0px rgba(41, 44, 124, 0.10)",
              position: "absolute",
              top: isSmallScreen ? "67%" : "47%", // Adjust this value to control the starting point
              left: "10%", // Adjust left position if needed
              color: "#fff", // Example text color
              boxSizing: "border-box",
              alignItems: "center",
              padding: "1% 6% 4% 6% ",
              // border:"1px solid red"
            }}
          >
            <div style={{ display: "flex", gap: "2%", alignItems: "center" }}>
              <div>
                {isSmallScreen ? (
                  ""
                ) : (
                  <hr
                    style={{
                      width: "1px",
                      height: "139.014px",
                      color: "#BDBDD1",
                      backgroundColor: "#BDBDD1",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  width: isSmallScreen ? "186px" : "534px",
                  height: isSmallScreen ? "" : "",
                }}
              >
                <p
                  className="-ml-1"
                  style={{
                    color: "#FFF",
                    fontFamily: "Poppins",
                    fontSize: isSmallScreen ? "10px" : "22px",
                    fontStyle: "normal",
                    fontWeight: isSmallScreen ? "300" : "400",
                    lineHeight: isSmallScreen ? "160%" : "180%" /* 39.6px */,
                    letterSpacing: isSmallScreen ? "0.08px" : "0.44px",
                    textAlign: isSmallScreen ? "left" : "",
                    paddingTop: isSmallScreen ? "8px" : "",
                  }}
                >
                  "Thank you so much for your help. It's exactly what I've been
                  looking for. You won't regret it. It really saves me time and
                  effort. BrainyBits is exactly what our business has been
                  lacking."
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="name"
                style={{
                  marginTop: isSmallScreen ? "" : "",
                }}
              >
                <h1
                  style={{
                    color: "#FFF",
                    fontFamily: "Poppins",
                    fontSize: isSmallScreen ? "9px" : "32px",
                    fontStyle: "normal",
                    fontWeight: isSmallScreen ? 400 : 600,
                  }}
                >
                  Josephine
                </h1>
                <p
                  style={{
                    color: "#FFF",
                    fontFamily: "Poppins",
                    fontSize: isSmallScreen ? "7px" : "16px",
                    fontStyle: "normal",
                    fontWeight: isSmallScreen ? "300" : 400,
                    marginTop: isSmallScreen ? "-1px" : "",
                    marginLeft: isSmallScreen ? "" : "17px",
                    lineHeight: isSmallScreen ? "120%" : "" /* 11.2px */,
                    letterSpacing: isSmallScreen ? "0.08px" : "",
                  }}
                >
                  Data Scientist
                </p>
              </div>
              <div className="rating">
                <img
                  src={startsimg}
                  alt="..."
                  style={{
                    width: isSmallScreen ? "40px" : "137px",
                    height: isSmallScreen ? "7px" : "",
                    flexShrink: 0,
                    marginTop: isSmallScreen ? "" : "40px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
