

export const FETCH_BLOG_REQUEST= "BLOG_REQUEST";
export const FETCH_BLOG_SUCCESS= "BLOG_SUCCESS";
export const FETCH_BLOG_FAIL= "BLOG_FAIL";

export const FETCH_INDIVIDUAL_BLOG_REQUEST= "INDIVIDUAL_BLOG_REQUEST";
export const FETCH_INDIVIDUAL_BLOG_SUCCESS= "INDIVIDUAL_BLOG_SUCCESS";
export const FETCH_INDIVIDUAL_BLOG_FAIL= "INDIVIDUAL_BLOG_FAIL";


export const COMMENT_ADD_SUCCESS='ADD_COMMENT_SUCCESS'

export const RESET='RESET'