import React, { useEffect, useRef, useState } from "react";
import Navbar from "../SelfNavbar";
import Footer from "../Foot";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { ProfilePhoto, ProfileReset } from "../../../Redux/SelfLearning/SelfAction/Profile.action";
import { Toast } from "primereact/toast";

const EditProfile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 876);
  const [file,setFile]=useState(null)
const dispatch=useDispatch()
const toast = useRef(null);
const navigate=useNavigate()
const {data,message,loading}=useSelector(store=>store.profile)
  useEffect(() => {
    // Function to update isSmallScreen when the window is resized
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 876);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFile(file)
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };
  const handleSubmit=async(e)=>{
    e.preventDefault()
    const base64String = await fileToBase64(file);
// console.log(base64String);
dispatch(ProfilePhoto(base64String))
  }
  useEffect(()=>{
if(message=="successful"){
  showSuccess("Profile updated")
    dispatch(ProfileReset())
    setTimeout(()=>{
    navigate("/self-learning")
    },3000)
}
  },[message])
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 1000,
    });
  };
  

  const listStyles = {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: isSmallScreen?"16px":"20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    listStyle: "none",
    textDecoration: "none",
    paddingLeft: "5%",
    marginRight: "5%",
  };
  let name=data && data.full_name ? data.full_name.split(" ") : "";

  return (
    <>                  <Toast ref={toast} />

      <Navbar />
      <div
        className="fle "
        style={{
          display: isSmallScreen ? "" : "flex",
          justifyContent: isSmallScreen ? "center" : "flex-start",
          alignItems: "flex-start",
          marginTop: "6%",
          gap: "6%",
        }}
      >
        <div
          className="c pl-4 pr-3 md:pl-0 md:pr-0 pb-3 md:pb-0"
          style={{
            width: isSmallScreen ? "100%" : "306px",
            height: isSmallScreen ? "auto" :"492px",
            flexShrink: 0,
            marginLeft: isSmallScreen ? "" : "5%",
            borderRadius: "10px",
            border: isSmallScreen ? "none" : "1px solid #FFF",
            background: "#FFF",
            boxShadow: isSmallScreen
              ? "none"
              : "0px 1px 4px 2px rgba(0, 176, 90, 0.15)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: isSmallScreen?"":"7%",
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Selected Profile"
                style={{ width: "100px", height: "100px", borderRadius: "50%",objectFit:"cover" }}
              />
            ) : (
              <label htmlFor="imageUpload">
                <Button
              className=" flex align-items-center "
              icon="pi pi-user"
              style={{
                width:  isSmallScreen ?"90px":"100px",
                height: isSmallScreen ?"90px":"100px",
                flexShrink: 0,
                fill: "#0E8E60",
                backgroundColor: "#0E8E60",
                border: "none",
                borderRadius: "50%",
              }}
            />
              </label>
            )}
            <input
              type="file"
              id="imageUpload"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <div>
            <p
                style={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen ?"20px":"24px",
                  fontStyle: "normal",
                  fontWeight: isSmallScreen ?500:600,
                  lineHeight: "normal",
                }}
              >
                {name[0]} {name[1]}
              </p>
            </div>
          </div>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "50px",
            }}
          >
            <Link
              to="/self-learning/edit-profile"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="text-left" style={listStyles}>
                Profile
              </li>
            </Link>
            <Link
              to="/self-learning/edit-photo"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li
                className="text-left py-2 md:py-0"
                style={{
                  color: isSmallScreen ? "#fff" : "#0E8E60",
                  backgroundColor: isSmallScreen ? "#0E8E60" : "#fff",
                  paddingLeft: "5%",
                  fontFamily: "Poppins",
                  fontSize: isSmallScreen?"16px":"20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  listStyle: "none",
                  textDecoration: "none",
                  marginRight: isSmallScreen ?"3%": "5%",
                  borderRadius:isSmallScreen? "5px":""
                }}
              >
                Photo
              </li>
            </Link>
            {/* <Link
              to="/self-learning/add-payments"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <li className="text-left" style={listStyles}>
                Payment Method
              </li>
            </Link> */}
            <li className="text-left" style={listStyles}>
              Logout
            </li>
          </ul>
        </div>
        <div
          className="w-full md:w-11 pt-4 pl-0 md:pl-6 pr-1 h-full card2"
          style={{
            display: "flex",
            flexDirection: "column",
            width: isSmallScreen ? "100%" : "70%",
          }}
        >
          <div
            className="flex flex-column"
            style={{
              width: isSmallScreen ? "100%" : "70%",
              display: "flex !important",
              alignContent: "center",
              flexDirection: "column",
              alignItems: "center", // Center vertically
              margin: "0 auto", // Center horizontally
              marginRight: isSmallScreen ? "none" : "30%",
              marginLeft: isSmallScreen ? "" : "",
            }}
          >
            <h1
              style={{
                color: "#0E8E60",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "16px" : "36px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginTop: isSmallScreen?"7px":"-30px",
              }}
            >
              Photo
            </h1>
            <p
              style={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "12px" : "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Add your nice photo here
            </p>
          </div>
          <h3
            className="checkoutformhead pl-4 md:pl-0 mt-4 md:mt-1 text-left "
            style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: isSmallScreen ? "12px" : "28px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            Image preview
          </h3>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1%",
              marginLeft: isSmallScreen ? "" : "",
            }}
          >
            {selectedImage ? (
              <div
                style={{
                  width: isSmallScreen ? "230px" : "884px",
                  height: isSmallScreen ? "230px" :"341px",
                  flexShrink: 0,
                  borderRadius: "15px",
                  border: "1px solid #000",
                  background: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: isSmallScreen ? "5% 10% 5% 0" : "5% 0 5% 0",
                }}
              >
                {" "}
                <img
                  src={selectedImage}
                  alt="Selected Profile"
                  style={{
                    width: isSmallScreen ? "10rem" : "20rem",
                    height: isSmallScreen ? "10rem" :"20rem",
                    borderRadius: "50%",objectFit:"cover"
                  }}
                />{" "}
              </div>
            ) : (
              <div
                style={{
                  width: isSmallScreen ? "300px" : "884px",
                  height: isSmallScreen ? "180px" : "341px",
                  justifyContent: "center",
                  flexShrink: 0,
                  borderRadius: "15px",
                  border: "1px solid #000",
                  background: "#FFF",
                  display: "flex",

                  alignItems: "center",
                  margin: "5% 0 5% 0",
                }}
              >
                <i
                  className="pi pi-user"
                  style={{
                    fontSize: isSmallScreen ? "6rem" : "12.5rem",
                    color: "grey",
                  }}
                ></i>
              </div>
            )}
            <h1
              className="text-left "
              style={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: isSmallScreen ? "12px" : "28px",
                fontStyle: "normal",
                fontWeight:isSmallScreen ? 400 : 600,
                lineHeight: "normal",
              }}
            >
              Add / Change image
            </h1>

            <div className="mt-2 md:mt-0 px-4 md:px-0" >
              <label htmlFor="imageUpload">
                <div className="p-inputgroup">
                  <InputText  type="file"
              id="imageUpload"
              accept="image/*"
              onChange={handleImageUpload} placeholder="Keyword" />
                  <Button
                    label="Upload"
                    style={{
                      backgroundColor: "#fff",
                      color: "#0E8E60",
                      border:"2px solid #e1e5e9"
                    }}
                    onClick={() => {
                      document.getElementById("imageUpload").click();
                    }}
                  />
                </div>
              </label>
            </div>
          </div>
          {loading?
              <Button  className="align-self-center"
              style={{
                width: isSmallScreen ? "80%" : "126px",
                height: "50px",
                flexShrink: 0,
                borderRadius: "5px",
                border: "1px solid #888",
                background: "#0E8E60",
                marginBottom: "6%",
                marginTop: "6%",
              }}
               type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"white" }}></i>} />
             :      <Button
             onClick={handleSubmit}
               label="Save"
               className="align-self-center"
               style={{
                 width: isSmallScreen ? "80%" : "126px",
                 height: "50px",
                 flexShrink: 0,
                 borderRadius: "5px",
                 border: "1px solid #888",
                 background: "#0E8E60",
                 marginBottom: "6%",
                 marginTop: "6%",
               }}
             />
             }
          {/* <Button
          onClick={handleSubmit}
            label="Save"
            className="align-self-center"
            style={{
              width: isSmallScreen ? "80%" : "126px",
              height: "50px",
              flexShrink: 0,
              borderRadius: "5px",
              border: "1px solid #888",
              background: "#0E8E60",
              marginBottom: "6%",
              marginTop: "6%",
            }}
          /> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditProfile;
