import { FETCH_PROFILE_FAIL, FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS, PROFILE_PHOTO, PROFILE_RESET, UPDATE_PROFILE_SUCCESS } from "../SelfTypes/Profile.types";

  
//   let brainystudenttoken = JSON.parse(localStorage.getItem("brainystudenttoken"));
  const initialState = {
    loading: false,
    error: false,
    message: "",
    data:[]
  };
  
  export const ProfileReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_PROFILE_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case FETCH_PROFILE_SUCCESS: {
        return {
          ...state,
          loading: false,data:payload
        };
      }
      case FETCH_PROFILE_FAIL: {
        return {
          ...state,
          loading: false,error:true
        };
      }
      case UPDATE_PROFILE_SUCCESS:{
        return{
            ...state,
            loading:false,error:false,message:payload
        }
      }
     case PROFILE_RESET:{
        return{
            ...state,message:""
        }
     }
   case PROFILE_PHOTO:{
    return{
        ...state,message:payload,loading:false
    }
   }
     
      default:
        return state;
    }
  };
  