import React, { useEffect, useRef, useState } from "react";
//import { useRouter } from 'next/router';
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import "./profilesmall.css";
import { useDispatch, useSelector } from "react-redux";
import { SignOutfuntion, SignupReset } from "../../../Redux/SelfLearning/SelfAction/Auth.action";
import { Dialog } from "primereact/dialog";
export default function PopupDoc() {
  const navigate = useNavigate();
  const menuLeft = useRef(null);
const dispatch=useDispatch()
  //const router = useRouter();
  const toast = useRef(null);
  let obj=JSON.parse(localStorage.getItem("brainystudenttoken"))
  let name= obj?obj.full_name.split(" "):"User"
  const items = [
    {
      label: (
        <>
          <div
            className="flex align-items-center text-align-center justify-content-start -ml-2"
            style={{ width: "fitContent", gap: "3%",borderTopLeftRadius:"60px",borderTopRightRadius:"60px" }}
          >
            <span className="flex align-items-center text-align-center -mb-2">
              <p
                className="flex justify-content-center align-items-center text-center"
                style={{
                  backgroundColor: "#0E8E60",
                  fontSize: "1rem",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  color: "#fff",
                  fontFamily:"poppins",
                }}
              >
                {name[0][0]}
              </p>
            </span>
            <div 
              style={{
                display: "flex ",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <span
                style={{
                  color: "#0E8E60",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%" /* 24px */,
                }}
              >
                                {name[0]}
              </span>

              <p
                style={{
                  color: "#263238",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "100%" /* 16px */,
                }}
              >
{obj&&obj.email_id.substring(0, 15)+"..."}              </p>
            </div>
          </div>
        </>
      ),

      items: [
        {
          label: "My Learning",

          command: () => {
            navigate("/self-learning/mylearning");
          },
        },
        {
          label: "Edit Profile",

          command: () => {
            navigate("/self-learning/edit-profile");
          },
        },
        {
          label: "My Cart",

          command: () => {
            navigate("/self-learning/cart");
          },
        },
        {
          label: "Update Password",

          command: () => {
            navigate("/self-learning/update-password")
          },
        },
        {
          label: "Logout",

          command: () => {
            dispatch(SignOutfuntion())
          },
        },
      ],
    },
  ];
  const {token,message}=useSelector(store=>store.auth)

  const [visible2, setVisible2] = useState(false);
  const footerContent = (
    <div className="w-full flex justify-content-center align-items-center" >
        <Button label="OK" icon="pi pi-check" onClick={() => {setVisible2(false)}} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
  );
    useEffect(()=>{
      //  console.log(message);
    if(message=='login successful'){ 
      // console.log(message);
      setVisible2(true)
      setTimeout(()=>{
        dispatch(SignupReset())
      },3000)
    // dispatch(SignupReset())
    }
    else{
      dispatch(SignupReset())
      setTimeout(()=>{
        dispatch(SignupReset())
      },3000)
      // setVisible2(true)
    }
    // console.log(visible2);
    // dispatch(SignupReset())

    },[message])
  return (
    <div className="card flex justify-content-start">
       <Dialog header=""  className=".p-dialog .p-dialog-header .p-dialog-title  dghdr mb-0   md:w-4 md:max-w-full" visible={visible2} onHide={()=>{setVisible2(false)}}   breakpoints={{ '960px': '75vw', '638px': '100vw' }}>
       <style>
    {`
      .p-dialog .p-dialog-header {
        display: none !important;
      }
      .p-dialog .p-dialog-content:last-of-type {
        border-Top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    `}
  </style>
              <h3 className="my-4  m-auto text-center " >You have successfully Login.</h3>
              <div className="w-full flex justify-content-center align-items-center" >
        <Button label="OK" icon="pi pi-check" onClick={() => {setVisible2(false)}} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
              </Dialog>
      <Toast ref={toast}></Toast>
      <Menu model={items} popup ref={menuLeft} id="popup_menu_left" popupAlignment="left" className="lftmnu w-6 mt-2"/>
      <ul className="list-none flex flex-column w-full h-8rem  justify-content-between p-0  pt-2  text-white" >

      <span  className="flex gap-2 align-items-center text-white" onClick={(e) => menuLeft.current.toggle(e)}>          <li className={`headers text-sm text-white no-underline cursor-pointer`} >Profile</li><i className={`pi pi-angle-down`} style={{ color: "white",cursor:"pointer" }}></i></span>
</ul>
      {/* <Button
        label="profile"
        className="signupbtn my-1"
        onClick={(event) => menuLeft.current.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
        style={{
          backgroundColor: "#0E8E60 !important",
          border: "none",
          marginLeft: "-12px",
        }}
      /> */}
    </div>
  );
}
