import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/SelfNavbar";
import bro from "../../../asserts/Bro.png";
import Foot from "../../components/Foot";
import NewCourseTop from "../../components/NewCourseComponents/NewCourseTop";

import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";

import "@splidejs/splide/dist/css/splide.min.css";
import { Slider } from "primereact/slider";
import brain from "../../../brain.gif"
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import ranjith from "../../../asserts/Ranjith.png"
import Rectanglerobot from "../../../asserts/Rectanglerobot.png";
import Artificialinteligence from "../../../asserts/Artificialinteligence.png";
import RectangleDeepLearning from "../../../asserts/RectangleDeepLearning.png";
import RectangleMechineLearning from "../../../asserts/Test_Automation.png";
import { Panel } from 'primereact/panel';
import "./selfcheck.css"
import CustomVideo from "../../components/BuyThisCourseComponents/SelfBuyThisComponent/video";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseDetails } from "../../../Redux/SelfLearning/SelfAction/Course.action";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ReviewApi, certificategenerateApi, watchhistoryupdateApi } from "../../../Api";
import { Rating } from "primereact/rating";
import { Toast } from 'primereact/toast'
import { InputTextarea } from "primereact/inputtextarea";
import { AddToCartFunction } from "../../../Redux/SelfLearning/SelfAction/Cart.action";
const array = [
  {
    id: 0,
    image: Rectanglerobot,
    courseName: "Masters Program in Data Science & AI",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 40,000",
  },
  {
    id: 1,
    image: RectangleMechineLearning,
    courseName: "Manual Testing &     Advancing in Tricentis Tosca ",
    frameWork: "Test Automation",
    mentor: "Pramod k ",
    price: "₹ 25,000",
  },
  {
    id: 2,
    image: RectangleDeepLearning,
    courseName: "Deep Learning with Specialisation and more ",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 30,000",
  },
  {
    id: 3,
    image: Artificialinteligence,
    courseName: "Artificial Intelligence with Python and more..",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 30,000",
  },
  {
    id: 4,
    image: RectangleDeepLearning,
    courseName: "Deep Learning with Specialisation and more",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 30,000",
  },
  {
    id: 5,
    image: Artificialinteligence,
    courseName: "Artificial Intelligence with Python and more..",
    frameWork: "Python Framework",
    mentor: "Ranjith Kumar",
    price: "₹ 30,000",
  },
];


const SelfCoursesPage = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 876);
  const [checked, setChecked] = useState(false);
  const {id}=useParams()
  const dispatch=useDispatch()
  const {Coursedata}=useSelector(store=>store.course)
  const videoRef = useRef(null);
  const navigate=useNavigate()
  // console.log(id,"params")
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 876);
  };
  useEffect(()=>{
dispatch(fetchCourseDetails(id))
  },[])

  const [src,SetSrc]=useState("")
  const [wat,SetWat]=useState("")
  const [activeIndex,SetActiveIndex]=useState(0)
  const [checkedCheckboxes, setCheckedCheckboxes] = useState({});
  const [flag,setFlag]=useState(0)

  console.log(Coursedata);
  useEffect(() => {
    if (Coursedata && Coursedata.course_content) {
      console.log("yes");
      
      outerLoop: // Create a label for the outer loop
      for (let i = 0; i < Coursedata.course_content.length; i++) {
        let el = Coursedata.course_content[i];
        console.log(el, "el");
        for (let j = 0; j < el.sub_topic.length; j++) {
          let elem = el.sub_topic[j];
          console.log(elem, "elem");
          if (elem.video_link) {
            SetSrc(elem.video_link);
            SetWat(elem.watched)
            break outerLoop; // Use the label to break out of the outer loop
            SetActiveIndex(i)
          }
        }
      }
    }
  }, [Coursedata]);


  // useEffect(()=>{
  //   if(Coursedata && Coursedata.course_id==id){
  //     console.log("course match");
  //    let z=true
  //     for(let i=0 ; i < Coursedata.course_content.length ; i++){
  //       let element=Coursedata.course_content[i]
  //       console.log(element,"element");
  //       for(let j= 0; j < element.sub_topic.length; j++){
  //         let a=element.sub_topic[j]
  //         console.log(a);
  //         SetWat(a.watched)
  //         if(a.watched=="unwatched"){
  //          z=false
  //           break
            
  //         }
  //       }
  //      }
     
  //       if(z==true){
  //         setVisible(true)
  //        }
      
      
  //   }
          
  // },[Coursedata])
//   const handleVideoEnded = () => {
//     const currentMainTopic = Coursedata.course_content.find(topic =>
//       topic.sub_topic.find(sub => sub.video_link === src)
//     );
// console.log(currentMainTopic);
// if (currentMainTopic) {
//   const currentIndex = currentMainTopic.sub_topic.findIndex(
//     sub => sub.video_link === src
//   );

//   if (currentIndex !== -1 && currentIndex < currentMainTopic.sub_topic.length - 1) {
//     // Update the video source to the next video within the same main topic
//     console.log("finded in first main");
//     const nextVideoLink = currentMainTopic.sub_topic[currentIndex + 1].video_link;
//     // SetSrc("https://storage.googleapis.com/web-dev-assets/video-and-source-tags/chrome.webm");
//     SetSrc(nextVideoLink)
//     const newIndex = parseInt(currentIndex, 10) ; 
//     if (!isNaN(newIndex) && newIndex >= 0 ) {
//       SetActiveIndex(newIndex);
//     }
//     setChecked(true); // Check the checkbox
//   }
//       else {
//         // If no more videos in the same main topic, find the index of the current main topic
//         const currentMainTopicIndex = Coursedata.course_content.findIndex(topic => topic.main_topic === currentMainTopic.main_topic );
//   console.log("finded current main index",currentMainTopicIndex);
//         if (currentMainTopicIndex !== -1 && currentMainTopicIndex < Coursedata.course_content.length - 1) {
//           // Find the next main topic with videos
//           let nextMainTopicIndex = currentMainTopicIndex + 1;
//           while (nextMainTopicIndex < Coursedata.course_content.length && Coursedata.course_content[nextMainTopicIndex].sub_topic.length === 0) {
//             nextMainTopicIndex++;
//           }
//   console.log("finded in next main Index",nextMainTopicIndex);
//           if (nextMainTopicIndex < Coursedata.course_content.length) {
//             // Update the video source to the first video of the next main topic
//             const nextVideoLink = Coursedata.course_content[nextMainTopicIndex].sub_topic[0].video_link;
//             const newIndex2 = parseInt(nextMainTopicIndex, 10) ; 
//     if (!isNaN(newIndex2) && newIndex2 >= 0 ) {
//       SetActiveIndex(newIndex2);
//     }
//             // SetActiveIndex(parseInt(nextMainTopicIndex))
//             SetSrc(nextVideoLink);
//             setChecked(true); // Check the checkbox
//           }
//         }
//       }

// }





//   }

const handleVideoEnded = async() => {
  const currentMainTopic = Coursedata.course_content.find(topic =>
    topic.sub_topic.find(sub => sub.video_link === src)
  );
  console.log(currentMainTopic,"cmtopic");

  if (currentMainTopic) {
    const currentIndex = currentMainTopic.sub_topic.findIndex(
      sub => sub.video_link === src
    );
    currentMainTopic.sub_topic[currentIndex].checked = true;
    let user=JSON.parse(localStorage.getItem("brainystudenttoken"));
    let payload={
      "user_id": user.user_id,
      "course_id": id,
      "main_topic" :currentMainTopic.main_topic,
      "sub_topic" :currentMainTopic.sub_topic[currentIndex].sub_topic
    }
    console.log(payload)
         try{
            let resp=await axios.post(watchhistoryupdateApi,payload)
            console.log(resp.data);
            if(resp.data.key=="Congratulations You have completed all the videos please click on `Generate Certificate` to download your certificate"){
              setVisible(true)
              let paylod2={
                "user_id":  user.user_id,
                "course_id":  id
              }
              try{
                let res=await axios.post(certificategenerateApi,paylod2)
                console.log(res.data);
              }
              catch(e){
                 console.log(e);
              }
             
            }
         }
         catch(e){
console.log(e);
         }
    if (currentIndex !== -1 && currentIndex < currentMainTopic.sub_topic.length - 1) {
      // Update the video source to the next video within the same main topic
      console.log("finded in first main index",currentIndex);
      const nextVideoLink = currentMainTopic.sub_topic[currentIndex + 1].video_link;
      SetSrc(nextVideoLink);
      SetWat(currentMainTopic.sub_topic[currentIndex + 1].watched)
    
      // Set the 'checked' property to true for the current sub-topic
      let currentMainTopicIndex1 = Coursedata.course_content.findIndex(topic => topic.main_topic === currentMainTopic.main_topic);

      const newIndex = parseInt(currentMainTopicIndex1, 10);
      if (!isNaN(newIndex) && newIndex >= 0) {
        SetActiveIndex(newIndex);
      }
    }
     else {
      // If no more videos in the same main topic, find the index of the current main topic
      const currentMainTopicIndex = Coursedata.course_content.findIndex(topic => topic.main_topic === currentMainTopic.main_topic);
      console.log("finded current main index", currentMainTopicIndex,currentMainTopic);
       

      if (currentMainTopicIndex !== -1 && currentMainTopicIndex < Coursedata.course_content.length - 1) {
        // Find the next main topic with videos
        let nextMainTopicIndex = currentMainTopicIndex + 1;
        while (nextMainTopicIndex < Coursedata.course_content.length && Coursedata.course_content[nextMainTopicIndex].sub_topic.length === 0) {
          nextMainTopicIndex++;
        }
        console.log("finded in next main Index", nextMainTopicIndex);

        if (nextMainTopicIndex < Coursedata.course_content.length) {
          // Update the video source to the first video of the next main topic
          const nextVideoLink = Coursedata.course_content[nextMainTopicIndex].sub_topic[0].video_link;
          const newIndex2 = parseInt(nextMainTopicIndex, 10);
          if (!isNaN(newIndex2) && newIndex2 >= 0) {
            SetActiveIndex(newIndex2);
          }
          // const newIndex2 = parseInt(currentMainTopicIndex, 10);
          // if (!isNaN(newIndex2) && newIndex2 >= 0) {
          //   SetActiveIndex(newIndex2);
          // }
          // SetActiveIndex(parseInt(nextMainTopicIndex))
    //       let user=JSON.parse(localStorage.getItem("brainystudenttoken"));
    //       let payload={
    //         "user_id": user.user_id,
    //         "course_id": id,
    //         "main_topic" :currentMainTopic.main_topic,
    //         "sub_topic" :currentMainTopic.sub_topic[currentMainTopicIndex].sub_topic
    //       }
    //       console.log(payload)
    //            try{
    //               let resp=await axios.post('https://2cl0hfffna.execute-api.ap-south-2.amazonaws.com/Prod/watch-history-update',payload)
    //               console.log(resp.data);
    //               if(resp.data.key=="Congratulations You have completed all the videos please click on `Generate Certificate` to download your certificate"){
    //                 setVisible(true)
    //                 let payload2={
    //                   "user_id":  user.user_id,
    //                   "course_id":  id
    //                 }
    //                 try{
    //                   let res=await axios.post('https://sbn9vfk8d9.execute-api.ap-south-2.amazonaws.com/Prod/certificate-generate',payload2)
    //                   console.log(res.data);
    //                 }catch(e){
    //                   console.log(e);
    //                 }
                    
    //               }
    //            }
    //            catch(e){
    // console.log(e);
    //            }
          SetSrc(nextVideoLink);
          SetWat(Coursedata.course_content[nextMainTopicIndex].sub_topic[0].watched)
          setChecked(true); // Check the checkbox
        }
      }
    }
  }
}
function calculateAgeInMonths(inputDate) {
  // Parse the inputDate string into a Date object
  const dateObject = new Date(inputDate);

  // Check if the inputDate is a valid date
  if (isNaN(dateObject)) {
    return "Invalid date";
  }

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in months
  const monthsDifference = (currentDate.getFullYear() - dateObject.getFullYear()) * 12 +
    (currentDate.getMonth() - dateObject.getMonth());

  return monthsDifference;
}

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const handleDownload = (link) => {
    const anchor = document.createElement('a');
    anchor.href = link;
    anchor.download =  'downloaded-file';

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const [visible, setVisible] = useState(false);
  const footerContent = (
    <div className="w-full flex justify-content-center align-items-center" >
        <Button label="OK" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus className="" style={{backgroundColor:"#0E8E60",color:"#fff",border:"none",boxShadow:"none"}} />
    </div>
);
const CartAddfunction=(id,el)=>{
  let elem={
    // course_name: Coursedata&&Coursedata.overview&&Coursedata.overview.course_name,
    // instructor_name: Coursedata&&Coursedata.overview&&Coursedata.overview.instructor_full_name,
    // course_cost: Coursedata&&Coursedata.overview&&Coursedata.overview.course_cost,
    // course_id: id,
    // course_photo: Coursedata&&Coursedata.overview&&Coursedata.overview.course_image,
    // ratings: Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average,
    // num_of_reviews:1
    course_cost: el.overview? el.overview.course_cost : el.course_cost,
    course_id: id,
    course_name: el.overview? el.overview.course_name : el.course_name,
    course_photo: el.overview? el.overview.course_image : el.course_image,
    instructor_name: el.overview? el.instructor.name : el.full_name,
    ratings: el.overview? el.instructor.rating : el.rating,
    small_description: el.overview? el.overview.short_description : el.small_description
  }
dispatch(AddToCartFunction(id,{...elem,course_type:"SL"}))
  navigate('/self-learning/cart')
}

// const handleSeeking = () => {
//     // While seeking is in progress, set hasSeeked to false
//     // setHasSeeked(false);
//   setTimeout(() => {
//         setHasSeeked(false)
     
//       }, 1000); 
//   };

//   const handleSeeked = () => {
//     // Once seeking has stopped, update the last valid time and set hasSeeked to true
//     if(!hasSeeked){
//       console.log("Seeked value:", videoRef.current.currentTime);
//       setLastValidTime(videoRef.current.currentTime);
//       setHasSeeked(true);
//       setTimeout(() => {
//         videoRef.current.currentTime = lastValidTime;
     
//       }, 0);  
    
//       }
//   };
const [supposedCurrentTime, setSupposedCurrentTime] = useState(0);

// useEffect(() => {
//   if(wat==="unwatched"){ 
//     const video = videoRef.current;

//     if (!video) {
//       // If the video element is not available, do nothing
//       return;
//     }
//     let lastTime = 0;

//   const handleTimeUpdate = () => {
//     if (!video.seeking) {
//       setSupposedCurrentTime(video.currentTime);
//     }
//   };

//   // const handleSeeking = () => {
//   //   const delta = video.currentTime - supposedCurrentTime;
//   //   if (Math.abs(delta) > 0.01) {
//   //     console.log("Seeking is disabled");
//   //     video.currentTime = supposedCurrentTime;
//   //   }
//   // };
//   const handleSeeking = () => {
//     const delta = video.currentTime - supposedCurrentTime;

//     // Determine the direction of playback
//     const isForwardSeek = video.currentTime > lastTime;
//     lastTime = video.currentTime;

//     // Allow backward seeking, prevent forward seeking
//     if (isForwardSeek && delta >= 0) {
//       console.log("Forward seeking is disabled");
//       video.currentTime = supposedCurrentTime;
//     }
//   };

//   const handleEnded = () => {
//     setSupposedCurrentTime(0);
//   };

//   // Add event listeners
//   video.addEventListener('timeupdate', handleTimeUpdate);
//   video.addEventListener('seeking', handleSeeking);
//   video.addEventListener('ended', handleEnded);

//   // Remove event listeners on component unmount
//   return () => {
//     video.removeEventListener('timeupdate', handleTimeUpdate);
//     video.removeEventListener('seeking', handleSeeking);
//     video.removeEventListener('ended', handleEnded);
//   };
//   }
// }, [supposedCurrentTime,Coursedata]);
  
const [feedback,setFeedback]=useState("")
const [rating,setRating]=useState(null)
const toast = useRef(null);
const [check,setCheck]=useState(false)
const [reviewLoader,setreviewLoader]=useState(false)

const handlePostReview=async()=>{
setCheck(true)
console.log(feedback,rating);
if(feedback!="" && rating!=null ){
let userdata= JSON.parse(localStorage.getItem("brainystudenttoken"))
if(userdata){
  setreviewLoader(true)
  setCheck(false)
  try{
    let payload={
      "full_name": "", 
    "user_id": userdata.user_id,
    "rating": rating,
    "review_comment": feedback,
    "course_id": id,
    "course_type":"SL"
    }
  let resp=await axios.post(ReviewApi,payload)
  console.log(resp.data);
  setreviewLoader(false)
  if(resp.data.key=='review has been posted successfully'){
  showSuccess(resp.data.key)
  setFeedback("")
  setRating(null)
  }
  else{
  showError("Please try after Somtime")
  
  }
  }
  catch(e){
  setreviewLoader(false)
  showError("Please try after Somtime")
  }
}else{
  showError("Please Login First")
}

}

}
const showSuccess = (message) => {
toast.current.show({
severity: "success",
summary: "Success",
detail: message,
life: 2000,
});
};
const showError = (message) => {
toast.current.show({
severity: "error",
summary: "Error",
detail: message,
life: 3000,
});
};
const handleContextMenu = (event) => {
  event.preventDefault();
};
const handleFullScreenClick = () => {
  const videoElement = videoRef.current;

  if (videoElement.requestFullscreen) {
    videoElement.requestFullscreen();
  } else if (videoElement.mozRequestFullScreen) { // Firefox
    videoElement.mozRequestFullScreen();
  } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
    videoElement.webkitRequestFullscreen();
  } else if (videoElement.msRequestFullscreen) { // IE/Edge
    videoElement.msRequestFullscreen();
  }
};

return (
    <> <Toast ref={toast} />
      <Navbar />
   
{Coursedata.length==0?<div className="hidden md:flex justify-content-center align-items-center h-25rem" ><img src={brain} width={isSmallScreen?200:80} className=" m-auto   hidden  md:block" style={{marginTop:isSmallScreen?"10%":""}}></img></div>:
<div className='hidden md:block '>
  <div  className='flex align-items-start pl-8 pr-8  pt-6 overflow-scroll card2'>
    <div className='w-8    ' >
   
<div className="w-full  vidCont mb-5 shadow-2" style={{height:"72vh",maxHeight:"72vh", margin: 0, padding: 0,backgroundColor:"#fff",borderTop:"1px solid #dee2e6",borderLeft:"1px solid #dee2e6",borderBottom:"1px solid #dee2e6"}}>
  <video ref={videoRef} width={"100%"} height={"100%"} style={{maxHeight: "100%", objectFit: "contain", margin: 0, padding: 0  }} src={src}  onEnded={handleVideoEnded} controlsList="nodownload"    onContextMenu={handleContextMenu} controls preload="true" />
  {/* <CustomVideo videoSrc={"https://storage.googleapis.com/web-dev-assets/video-and-source-tags/chrome.webm"} control="controls"  /> */}
</div>
    


        
    
    <div className='w-full  pl-1' >
    <div className="card "  >
            <TabView >
                <TabPanel className='individualtabhdr '  header="Overview">
                 
                
                   <div>
                   <h1 className='individualrequirement text-left mb-2 mt-3 '>What you’ll learn</h1>

                   <div className='w-full flex gap-4 ' >
        <div className='w-6 flex flex-column   pt-4' >
         <ul className="ml-3" >
          {Coursedata&&Coursedata.overview&&Coursedata.overview.what_you_will_learn?.slice(0,3).map((el)=>(
            <li className='indiduallist1'>{el}</li>
          ))}
            
          
         </ul>
        </div>
        
        <div className='w-6 pt-6 pb-4 ' >
        {/* <h1 className='individualrequirement text-left mb-0 '>&nbsp;</h1> */}

        <ul className="m-auto -mt-4" >
        {Coursedata&&Coursedata.overview&&Coursedata.overview.what_you_will_learn?.slice(3,6).map((el)=>(
            <li className='indiduallist1'>{el}</li>
          ))}
            </ul>
        </div>
       
    </div>
                {/* <h1 className='individualrequirement text-left mb-3 pl-8'>About this Course</h1>
                <ul className="ml-5 pl-8">
                    <li className='individualrequirementlist'>Lorem ipsum deeper suman de corperum mapgna deppler gurbemme gabvred  </li>
                    <li className='individualrequirementlist'>Lorem ipsum deeper suman de corperum mapgna deppler gurbemme gabvred  </li>
                    <li className='individualrequirementlist'>Lorem ipsum deeper suman de corperum mapgna deppler gurbemme gabvred  </li>                    
                </ul> */}
                <h1 className='individualrequirement text-left mt-3 mb-3   '>Description</h1>
                <p className='individualrequirementdesc pr-3 '>
                {flag === 0 ?
    (Coursedata && Coursedata.overview && Coursedata.overview.long_description.split('\n\n').slice(0, 1).map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    )))
    :
    (Coursedata && Coursedata.overview && Coursedata.overview.long_description.split('\n\n').map((paragraph, index, array) => (
      <React.Fragment key={index}>
        <p>{paragraph}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    )))
  }
</p>
<h3 className='seemorbtn text-right' onClick={() => setFlag(flag === 0 ? 1 : 0)}>
  {flag === 0 ? "Show more" : "Show less"}
</h3>
                </div>
                </TabPanel>
           
                <TabPanel className='individualtabhdr' header="Reviews">
                    <div>

                <h1 className='individualrequirementreviews mt-4 text-left'>Students <span className='individualrequirementreviews2'>Feedback</span> </h1>
                <div  className='w-8 flex  justify-content-between align-items-center'>
                <div  className='w-3 flex flex-column'>
                        <h3  className='individualrating mt-0 mb-0 pb-0'>{parseFloat(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average.toFixed(2))}</h3>
                        <span className='-mt-3'><svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>0?"#0E8E60":"#E0E0E0"}/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>1?"#0E8E60":"#E0E0E0"}/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>2?"#0E8E60":"#E0E0E0"}/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>3?"#0E8E60":"#E0E0E0"}/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>4?"#0E8E60":"#E0E0E0"}/>
                        </svg></span>
                        <span className='individualratingdesc -mt-2'>Tutorial rating</span> 
                    </div>
                    <div  className='w-5 flex flex-column justify-content-around pt-2  gap-3'>
                    {/* <Slider  className="w-14rem" value={70} />
                    <Slider  className="w-14rem" value={50} />
                    <Slider  className="w-14rem" value={20} />
                    <Slider  className="w-14rem" value={10} />
                    <Slider  className="w-14rem" value={0} /> */}
                    <Slider  className="w-14rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_5)} />
                    <Slider  className="w-14rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_4)} />
                    <Slider  className="w-14rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_3)} />
                    <Slider  className="w-14rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_2)} />
                    <Slider  className="w-14rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_1)} />

                    </div>
                    <div className='w-4 flex flex-column  pt-1  gap-0'>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0 '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#0E8E60"/>
                        </svg> <span className='individualpercnt '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_5)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_4)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_3)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_2)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_1)}%</span>
                        </span>
                    </div>
                </div>
                
                {Coursedata&&Coursedata.reviews&&Coursedata.reviews.length>0&&<h1 className='individualrequirementrewieshd text-left mt-4'>Reviews</h1>}
                <div>
                <div className='w-full my-2 pt-2'>
                  <h1 className='individualrequirement text-left'>Give Your Feedback <span style={{color:"#0E8E60"}}>*</span></h1>
                  <InputTextarea className='w-full mt-2' value={feedback} onChange={(e) =>{ setFeedback(e.target.value);setCheck(false)}} placeholder='comment'  rows={2} cols={30} />
                  <h6 className="w-full  text-xs" style={{color:"red",textAlign:"left",fontWeight:"400"}}>{check==true?feedback==""?"feedback is missing":"":""}</h6> 
                  <div className='flex gap-4'><h1 className='individualratingdescR  text-left'>Overall rating <span style={{color:"#0E8E60"}}>*</span></h1> 
                  <div className='flex flex-column'><Rating value={rating} onChange={(e) =>{ setRating(e.value);setCheck(false)}} cancel={false} />
                  <span className=" text-xs" style={{color:"red",fontWeight:"400"}}>{check==true?rating==null?"rating is missing":"":""}</span> </div></div>
                  {reviewLoader? <Button className="crsadtocrtbtnR mt-2" type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem',color:"#0E8E60" }}></i>} />:
                          <Button className='crsadtocrtbtnR mt-2' onClick={handlePostReview} >Post Feedback</Button>}
                 
                  </div>
                <div>
                  {Coursedata&&Coursedata.reviews&&Coursedata.reviews.map((el)=>(
  <div  className='w-6 flex my-4'>
  <div className='w-2 pt-1'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<circle cx="20" cy="20" r="20" fill="#0E8E60"/>
</svg></div>
  <div className='w-10 flex flex-column'>
    <h3 className='text-left individualreviewname mt-0'>{el.full_name}</h3>
    <span className='flex justify-content-start align-items-center mt-0 mb-0 -ml-3 '>
      {el.rating==4?
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
<path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
<path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
<path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
<path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
<path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
    </svg>: <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
<path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#D9D9D9"/>
<path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#D9D9D9"/>
<path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#D9D9D9"/>
<path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
<path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
    </svg>}
    <span className='individualdate '>{calculateAgeInMonths(el.ingested_date_time)} months ago</span>
    </span>
    <span className='individualreviewtext'>{el.review_text}</span>
    <div className='flex gap-3'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
<path d="M8.5206 13.7144H12.9622C13.3718 13.7144 13.7222 13.4858 13.8702 13.1566L15.3606 9.93378C15.405 9.82864 15.4297 9.71893 15.4297 9.60007V8.68578C15.4297 8.18293 14.9855 7.7715 14.4427 7.7715L11.3286 7.7715L11.7975 5.68236L11.8123 5.53607C11.8123 5.34864 11.7284 5.17493 11.5951 5.0515L11.072 4.5715L7.81982 7.58407C7.64216 7.74864 7.53358 7.97721 7.53358 8.22864L7.53358 12.8001C7.53358 13.3029 7.97774 13.7144 8.5206 13.7144ZM8.5206 8.22864L10.6624 6.24464L10.0011 8.68578H14.4427V9.60007L12.9622 12.8001H8.5206V8.22864ZM6.54657 13.7144H4.57255V8.22864H6.54657V13.7144Z" fill="black"/>
</svg><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
<path d="M11.4794 5.71436H7.03784C6.62823 5.71436 6.27784 5.94293 6.12979 6.27207L4.6394 9.49493C4.59499 9.60007 4.57031 9.70978 4.57031 9.82864V10.7429C4.57031 11.2458 5.01447 11.6572 5.55733 11.6572H8.67135L8.20252 13.7464L8.18771 13.8926C8.18771 14.0801 8.27161 14.2538 8.40486 14.3772L8.92797 14.8572L12.1802 11.8446C12.3578 11.6801 12.4664 11.4515 12.4664 11.2001V6.62864C12.4664 6.12578 12.0223 5.71436 11.4794 5.71436ZM11.4794 11.2001L9.33758 13.1841L9.99888 10.7429H5.55733V9.82864L7.03784 6.62864H11.4794V11.2001ZM13.4534 5.71436H15.4275V11.2001H13.4534V5.71436Z" fill="black" fill-opacity="0.9"/>
</svg> <span className='rprtbtn'>Report</span> </div>
  </div>

</div>
                  ))}
              
                {/* <div  className='w-6 flex my-4'>
                      <div className='w-2 pt-1'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <circle cx="20" cy="20" r="20" fill="#0E8E60"/>
</svg></div>
                      <div className='w-10 flex flex-column'>
                        <h3 className='text-left individualreviewname mt-0'>Shanthi.k</h3>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0 -ml-3 '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#0E8E60"/>
                        </svg> <span className='individualdate '>6 months ago</span>
                        </span>
                        <span className='individualreviewtext'>Lorem ipsum manga monmey negens degree cumonde Was this review helpful?</span>
                        <div className='flex gap-3'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
  <path d="M8.5206 13.7144H12.9622C13.3718 13.7144 13.7222 13.4858 13.8702 13.1566L15.3606 9.93378C15.405 9.82864 15.4297 9.71893 15.4297 9.60007V8.68578C15.4297 8.18293 14.9855 7.7715 14.4427 7.7715L11.3286 7.7715L11.7975 5.68236L11.8123 5.53607C11.8123 5.34864 11.7284 5.17493 11.5951 5.0515L11.072 4.5715L7.81982 7.58407C7.64216 7.74864 7.53358 7.97721 7.53358 8.22864L7.53358 12.8001C7.53358 13.3029 7.97774 13.7144 8.5206 13.7144ZM8.5206 8.22864L10.6624 6.24464L10.0011 8.68578H14.4427V9.60007L12.9622 12.8001H8.5206V8.22864ZM6.54657 13.7144H4.57255V8.22864H6.54657V13.7144Z" fill="black"/>
</svg><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
  <path d="M11.4794 5.71436H7.03784C6.62823 5.71436 6.27784 5.94293 6.12979 6.27207L4.6394 9.49493C4.59499 9.60007 4.57031 9.70978 4.57031 9.82864V10.7429C4.57031 11.2458 5.01447 11.6572 5.55733 11.6572H8.67135L8.20252 13.7464L8.18771 13.8926C8.18771 14.0801 8.27161 14.2538 8.40486 14.3772L8.92797 14.8572L12.1802 11.8446C12.3578 11.6801 12.4664 11.4515 12.4664 11.2001V6.62864C12.4664 6.12578 12.0223 5.71436 11.4794 5.71436ZM11.4794 11.2001L9.33758 13.1841L9.99888 10.7429H5.55733V9.82864L7.03784 6.62864H11.4794V11.2001ZM13.4534 5.71436H15.4275V11.2001H13.4534V5.71436Z" fill="black" fill-opacity="0.9"/>
</svg> <span className='rprtbtn'>Report</span> </div>
                      </div>

                </div>

                <div  className='w-6 flex my-4'>
                      <div className='w-2 pt-1'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <circle cx="20" cy="20" r="20" fill="#0E8E60"/>
</svg></div>
                      <div className='w-10 flex flex-column'>
                        <h3 className='text-left individualreviewname mt-0'>Shanthi.k</h3>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0 -ml-3 '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#0E8E60"/>
                        </svg> <span className='individualdate '>6 months ago</span>
                        </span>
                        <span className='individualreviewtext'>Lorem ipsum manga monmey negens degree cumonde Was this review helpful?</span>
                        <div className='flex gap-3'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
  <path d="M8.5206 13.7144H12.9622C13.3718 13.7144 13.7222 13.4858 13.8702 13.1566L15.3606 9.93378C15.405 9.82864 15.4297 9.71893 15.4297 9.60007V8.68578C15.4297 8.18293 14.9855 7.7715 14.4427 7.7715L11.3286 7.7715L11.7975 5.68236L11.8123 5.53607C11.8123 5.34864 11.7284 5.17493 11.5951 5.0515L11.072 4.5715L7.81982 7.58407C7.64216 7.74864 7.53358 7.97721 7.53358 8.22864L7.53358 12.8001C7.53358 13.3029 7.97774 13.7144 8.5206 13.7144ZM8.5206 8.22864L10.6624 6.24464L10.0011 8.68578H14.4427V9.60007L12.9622 12.8001H8.5206V8.22864ZM6.54657 13.7144H4.57255V8.22864H6.54657V13.7144Z" fill="black"/>
</svg><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
  <path d="M11.4794 5.71436H7.03784C6.62823 5.71436 6.27784 5.94293 6.12979 6.27207L4.6394 9.49493C4.59499 9.60007 4.57031 9.70978 4.57031 9.82864V10.7429C4.57031 11.2458 5.01447 11.6572 5.55733 11.6572H8.67135L8.20252 13.7464L8.18771 13.8926C8.18771 14.0801 8.27161 14.2538 8.40486 14.3772L8.92797 14.8572L12.1802 11.8446C12.3578 11.6801 12.4664 11.4515 12.4664 11.2001V6.62864C12.4664 6.12578 12.0223 5.71436 11.4794 5.71436ZM11.4794 11.2001L9.33758 13.1841L9.99888 10.7429H5.55733V9.82864L7.03784 6.62864H11.4794V11.2001ZM13.4534 5.71436H15.4275V11.2001H13.4534V5.71436Z" fill="black" fill-opacity="0.9"/>
</svg> <span className='rprtbtn'>Report</span> </div>
                      </div>

                      </div> */}

                </div>
                {/* <Divider className='w-6' /> */}
              
                      {/* <Divider className='w-6' /> */}

                </div>
                   {/* <h3 className='seemorbtn text-right w-6'>Show more</h3> */}
                </div>

                </TabPanel>

                <TabPanel className='individualtabhdr' header="Instructors">
                   <div  className='w-full '>
                      <h3 className='insthed text-left my-4'>Instructor</h3>
                      <div className='flex w-6 gap-4 mb-4'>
                        <div className='w-4 pb-0 mb-0' style={{backgroundColor:"#0E8E60",borderRadius:"10px"}}><img src={Coursedata&&Coursedata.instructor&&Coursedata.instructor.photo} style={{width:"100%",borderRadius:"10px",height:"100%",objectFit:"cover"}} className='' /></div>
                        <div className='w-4 flex flex-column justify-content-center gap-2 ' >
                          <h3 className='insnm text-left my-0'>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.name}</h3>
                          <span className='flex justify-content-start gap-2 insrating'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path opacity="0.95" d="M9.33426 1.04894C9.54381 0.404018 10.4562 0.404018 10.6657 1.04894L12.537 6.80811C12.6307 7.09652 12.8995 7.2918 13.2027 7.2918H19.2583C19.9364 7.2918 20.2183 8.15953 19.6697 8.55811L14.7707 12.1175C14.5254 12.2957 14.4227 12.6117 14.5164 12.9001L16.3877 18.6593C16.5972 19.3042 15.8591 19.8405 15.3105 19.4419L10.4115 15.8825C10.1661 15.7043 9.83389 15.7043 9.58855 15.8825L4.68951 19.4419C4.14091 19.8405 3.40277 19.3042 3.61232 18.6593L5.48358 12.9001C5.5773 12.6117 5.47464 12.2957 5.2293 12.1175L0.330251 8.55811C-0.218349 8.15953 0.0635941 7.2918 0.7417 7.2918H6.79725C7.10051 7.2918 7.36928 7.09652 7.46299 6.80811L9.33426 1.04894Z" fill="#0E8E60" fill-opacity="0.95"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.rating} rating</span>
<span className='flex justify-content-start gap-1 insrating'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
  <path d="M4.8 15H14.4V12.5H4.8V15ZM16.8 15H19.2V12.5H16.8V15ZM4.8 10H7.2V7.5H4.8V10ZM9.6 10H19.2V7.5H9.6V10ZM2.4 20C1.74 20 1.1748 19.755 0.704402 19.265C0.234002 18.775 -0.000797963 18.1867 2.03735e-06 17.5V2.5C2.03735e-06 1.8125 0.235202 1.22375 0.705602 0.733752C1.176 0.243752 1.7408 -0.000831211 2.4 2.12224e-06H21.6C22.26 2.12224e-06 22.8252 0.245002 23.2956 0.735002C23.766 1.225 24.0008 1.81334 24 2.5V17.5C24 18.1875 23.7648 18.7762 23.2944 19.2662C22.824 19.7562 22.2592 20.0008 21.6 20H2.4ZM2.4 17.5H21.6V2.5H2.4V17.5Z" fill="#0E8E60"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.total_num_reviews} reviews</span>
<span className='flex justify-content-start gap-2 insrating'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M4.375 0C5.03804 0 5.67393 0.231785 6.14277 0.644365C6.61161 1.05694 6.875 1.61652 6.875 2.2C6.875 2.78348 6.61161 3.34306 6.14277 3.75564C5.67393 4.16822 5.03804 4.4 4.375 4.4C3.71196 4.4 3.07607 4.16822 2.60723 3.75564C2.13839 3.34306 1.875 2.78348 1.875 2.2C1.875 1.61652 2.13839 1.05694 2.60723 0.644365C3.07607 0.231785 3.71196 0 4.375 0ZM2.5 5.5H6.25C6.91304 5.5 7.54893 5.73179 8.01777 6.14437C8.48661 6.55695 8.75 7.11652 8.75 7.7V13.75H6.875V22H1.875V13.75H0V7.7C0 7.11652 0.263392 6.55695 0.732233 6.14437C1.20107 5.73179 1.83696 5.5 2.5 5.5ZM15.625 0C16.288 0 16.9239 0.231785 17.3928 0.644365C17.8616 1.05694 18.125 1.61652 18.125 2.2C18.125 2.78348 17.8616 3.34306 17.3928 3.75564C16.9239 4.16822 16.288 4.4 15.625 4.4C14.962 4.4 14.3261 4.16822 13.8572 3.75564C13.3884 3.34306 13.125 2.78348 13.125 2.2C13.125 1.61652 13.3884 1.05694 13.8572 0.644365C14.3261 0.231785 14.962 0 15.625 0ZM13.75 5.5H17.5C18.163 5.5 18.7989 5.73179 19.2678 6.14437C19.7366 6.55695 20 7.11652 20 7.7V13.75H18.125V22H13.125V13.75H11.25V7.7C11.25 7.11652 11.5134 6.55695 11.9822 6.14437C12.4511 5.73179 13.087 5.5 13.75 5.5Z" fill="#0E8E60"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.total_num_students} students</span>
<span className='flex justify-content-start gap-2 insrating'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M8.5 14.5842V7.41616C8.50015 7.32648 8.52442 7.23848 8.57026 7.1614C8.61611 7.08432 8.68184 7.02099 8.76057 6.97804C8.83931 6.9351 8.92814 6.91412 9.01777 6.91731C9.1074 6.9205 9.19452 6.94773 9.27 6.99616L14.846 10.5792C14.9166 10.6244 14.9747 10.6867 15.0149 10.7602C15.0552 10.8338 15.0763 10.9163 15.0763 11.0002C15.0763 11.084 15.0552 11.1665 15.0149 11.2401C14.9747 11.3137 14.9166 11.3759 14.846 11.4212L9.27 15.0052C9.19452 15.0536 9.1074 15.0808 9.01777 15.084C8.92814 15.0872 8.83931 15.0662 8.76057 15.0233C8.68184 14.9803 8.61611 14.917 8.57026 14.8399C8.52442 14.7628 8.50015 14.6748 8.5 14.5852V14.5842Z" fill="#0E8E60"/>
  <path d="M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 1.5C8.48044 1.5 6.06408 2.50089 4.28249 4.28249C2.50089 6.06408 1.5 8.48044 1.5 11C1.5 13.5196 2.50089 15.9359 4.28249 17.7175C6.06408 19.4991 8.48044 20.5 11 20.5C13.5196 20.5 15.9359 19.4991 17.7175 17.7175C19.4991 15.9359 20.5 13.5196 20.5 11C20.5 8.48044 19.4991 6.06408 17.7175 4.28249C15.9359 2.50089 13.5196 1.5 11 1.5Z" fill="#0E8E60"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.num_of_courses} Courses</span>
                        </div>
                      </div>
                      <p className='insdesc'>
  {Coursedata && Coursedata.instructor && Coursedata.instructor.description &&
    Coursedata.instructor.description.split('\n\n').map((paragraph, index, array) => (
      <React.Fragment key={index}>
        <p>{paragraph}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ))}
</p>                      {/* <p className='insdesc'>Lorem ipsum isd jnisa nknqd jqdn nknkasc bnqisdnk inkindsak ninknsda bhdishf tegugusa vuhihcx xasbdhkscbjgugdi vugaiushd bjosdjfo tegwugfi bdasiocf bafiwheo tqwygeiuo sbciashdof jvjascbisncon bvweugfrhe gugwue asfguagf augsudgwe buwd jgadihiqw vusgadu bubasud vausdv jbubsind ajbdiqwbed fjbqw fiqwebf jqfbiqwef jfbi fkqnwifnfk fbibfi fbiff ifk finbiqwhojwdo qdiqwnfojmqfwnmf noqwjpd nisjfif jhwidnqwf jhuqwijdiqw uhuqnhd  jbwu jnwqidjiw qwjdnwwkdj jqhdiwdj qwjdniqwdj qwjdnqwimf qufuwgdy wgegywg hgufej orkgplr ltkjtoknhr ejhqwijoqw wjhrieokwoqrf uwehriwjrf </p> */}
                   </div>
                </TabPanel>
            </TabView>
        </div>

    </div>
    <div className='w-full  mb-5 pl-2' >
    <h3 className='individualcrslhdr text-left  mb-4'>You Might also <span className='individualcrslhdr2'>Like</span></h3>
    <Splide  hasTrack={ false } className='sdbox ' options={{
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 3, 
            gap: '1rem',
            arrows: true,
            pagination: true, }}
        > 
             <SplideTrack>

{Coursedata&&Coursedata.you_might_like&&Coursedata.you_might_like.map((el)=>(
  <SplideSlide >
<div key={el.course_id} className='tabcrslbox w-11 p-3 flex flex-column justify-content-between'>
               <div  className='flex h-2rem align-items-center gap-2 cursor-pointer mb-1' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}><img src={el.profile_photo_link} style={{borderRadius:"50%",height:"100%",borderRadius:"15px",objectFit:"cover"}} alt="image" /><span className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.full_name}</span></div>
               <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_image} onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>

               {/* <div className='h-12rem pt-2  cursor-pointer'  onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}><img src={el.course_image} style={{height:"100%",width:"100%",borderRadius:"10px",objectFit:"cover"}} /></div> */}
               <span className='tabcrslcoursename pt-2  cursor-pointer' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
               <span className='text-left flex align-items-center gap-1 cursor-pointer' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{height:"15px"}}> <span className='tabcrslname'>{el.rating}</span> <svg xmlns="http://www.w3.org/2000/svg" className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
               <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.rating)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.rating)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.rating)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.rating)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.rating)>4?"#0E8E60":"#E0E0E0"}/>
                  </svg></span>
               <div className='flex justify-content-between align-items-center'><span className='tabprice  cursor-pointer' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn' onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
            </div></SplideSlide>
))}
</SplideTrack>

      
   <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-6"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-3"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
    </div>
    </div>
    
    <div style={{maxHeight:"72vh"}} className='w-4   shadow-2 ' >
      <Panel style={{maxHeight:"72vh",overflow:"auto"}} header={      <h1 className="sidehrd pl-4">Course content</h1>} className="border-noround">
        <Accordion style={{maxHeight:"65%"}} activeIndex={activeIndex}   onTabChange={(e) => SetActiveIndex(e.index)} className=" overflow-scroll   shadow-none "    expandIcon="pi pi-angle-down custom-expand-iconoffline" collapseIcon="pi pi-angle-up custom-expand-iconoffline"   >
            {Coursedata&&Coursedata.course_content&&Coursedata.course_content.map((el,index)=>(
               <AccordionTab  header={<h3 className="acrhdrnew text-left">Section {index+1} : {el.main_topic}</h3>}  className="p-accordion-header accordion-tab "   >
                    {el.sub_topic.map((elem,subIndex)=>(
                      <div className="flex align-items-center justify-content-between" >
                         <div className="flex justify-content-start align-items-center gap-3 mb-3 cursor-pointer w-9"  onClick={() =>{SetSrc(elem.video_link);SetWat(elem.watched)} } >
                         <Checkbox  checked={elem.watched=="watched" || elem.checked}></Checkbox>
                         <div  className="flex flex-column align-items-start w-12" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"wrap"}} >
                            <h1 className="acrbdyttl w-10 text-left" style={{color:src==elem.video_link?"#0E8E60":"",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{elem.sub_topic}</h1>
                            <div className="flex gap-3 align-items-center"><i className="pi pi-video mt-1" style={{color:src==elem.video_link?"#0E8E60":""}}></i>  <span className="acrbdyttl mt-1" style={{color:src==elem.video_link?"#0E8E60":""}}>{elem.video_length}</span></div>
                         </div>
                         </div>
                         {elem.file_link&&
                         <Button className="  h-2rem  -mt-2 flex justify-content-center align-items-center dldbtn" onClick={()=>handleDownload(elem.file_link)}  label="Download" icon="pi pi-file" />
                        //  <div className="w-2 flex justify-content-center pt-2 mt-1 " ><i className="pi  pi-download cursor-pointer" onClick={()=>handleDownload(elem.file_link)} style={{ fontSize: '1.5rem',color:"grey" }}></i></div>
                         }
                        </div>
                    ))} 
              

              </AccordionTab>
            ))}

        
                   </Accordion>
        
      </Panel>
    </div>

  </div>
    
    </div>}
{/* <div className="md:hidden " style={{minHeight:"60vh"}}> */}
    {Coursedata.length==0?<div className="flex justify-content-center align-items-center h-25rem" ><img src={brain} width={isSmallScreen?80:420} className="md:hidden m-auto"   ></img></div>:
    <div className='block md:hidden pl-3 pr-3'>
  <div  className='flex flex-column align-items-start  pt-6'>
    <div className='w-full    overflow-scroll card2' >
  
<div className="w-full px-1">
  {/* <video width={"100%"} src={src}  onEnded={handleVideoEnded} controlsList="nodownload" controls preload="true"   /> */}
  <video ref={videoRef} width={"100%"} height={"100%"} style={{ maxHeight: "100%", objectFit: "contain" }} src={src} fullscreen={true} onEnded={handleVideoEnded} controlsList="nodownload"    onContextMenu={handleContextMenu}  playsinline  controls preload="true" />

</div>
    

<div className='w-full border-round-lg pt-3  shadow-2' >
      <h1 className="sidehrd pl-4">Course content</h1>
      <Accordion  activeIndex={activeIndex}   onTabChange={(e) => SetActiveIndex(e.index)} className="border-round-lg accordion-container mb-5 mt-2  "    expandIcon="pi pi-angle-down custom-expand-iconoffline" collapseIcon="pi pi-angle-up custom-expand-iconoffline"   >
            {Coursedata&&Coursedata.course_content&&Coursedata.course_content.map((el,index)=>(
               <AccordionTab  header={<h3 className="acrhdrnew text-left" style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>Section {index+1} : {el.main_topic}</h3>}  className="p-accordion-header accordion-tab "   >
                    {el.sub_topic.map((elem,subIndex)=>(
                        <div className="flex align-items-center justify-content-between w-full"  >
                         <div className="flex justify-content-start align-items-center gap-3 mb-3 cursor-pointer w-8" onClick={() =>{SetSrc(elem.video_link);SetWat(elem.watched)} }>
                         <Checkbox   checked={elem.watched=="watched" || elem.checked}></Checkbox>
                         <div  className="flex flex-column align-items-start w-12">
                            <h1 className="acrbdyttl w-10 text-left" style={{color:src==elem.video_link?"#0E8E60":"",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{elem.sub_topic}</h1>
                            <div className="flex gap-3 align-items-center"><i className="pi pi-video mt-1" style={{color:src==elem.video_link?"#0E8E60":""}}></i>  <span className="acrbdyttl mt-1" style={{color:src==elem.video_link?"#0E8E60":""}}>{elem.video_length}</span></div>
                         </div>
         
                        </div>
                        {elem.file_link&&
                         <Button className=" w-4 h-2rem px-2 -mt-2 dldbtn" onClick={()=>handleDownload(elem.file_link)} style={{fontSize:"10px",fontWeight:500}} label="Download" icon="pi pi-file" />
                        //  <div className="w-2 flex justify-content-center pt-2 mt-1 " ><i className="pi  pi-download cursor-pointer" onClick={()=>handleDownload(elem.file_link)} style={{ fontSize: '1.5rem',color:"grey" }}></i></div>
                         }
                        </div>
                    ))} 
               {/* <div className="flex align-items-center gap-3 mb-3">
                <Checkbox onChange={(e) => setChecked(e.checked)} checked={checked} ></Checkbox>
                <div  className="flex flex-column align-items-start">
                   <h1 className="acrbdyttl ">Measure of Central Tendency</h1>
                   <div className="flex gap-3 align-items-center"><i className="pi pi-video mt-1"></i>  <span className="acrbdyttl mt-1">2 min</span></div>
                </div>

               </div>
               <div className="flex align-items-center gap-3 mb-3">
                <Checkbox onChange={(e) => setChecked(e.checked)}  ></Checkbox>
                <div  className="flex flex-column align-items-start">
                   <h1 className="acrbdyttl ">Measure of Central Tendency</h1>
                   <div className="flex gap-3 align-items-center"><i className="pi pi-video mt-1"></i>  <span className="acrbdyttl mt-1">2 min</span></div>
                </div>

               </div>
               <div className="flex align-items-center gap-3 mb-3">
                <Checkbox onChange={(e) => setChecked(e.checked)}  ></Checkbox>
                <div  className="flex flex-column align-items-start">
                   <h1 className="acrbdyttl ">Measure of Central Tendency</h1>
                   <div className="flex gap-3 align-items-center"><i className="pi pi-video mt-1"></i>  <span className="acrbdyttl mt-1">2 min</span></div>
                </div>

               </div>
               */}

              </AccordionTab>
            ))}

                      
                   </Accordion>
          {/* <Accordion className="border-round-lg accordion-container mb-5 mt-2  "    expandIcon="pi pi-angle-down custom-expand-iconoffline" collapseIcon="pi pi-angle-up custom-expand-iconoffline"   >

                      
                      <AccordionTab  header={<h3 className="acrhdrnew text-left">Section 1 : Course Introduction</h3>}  className=" accordion-tab "   >
                      <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                      <AccordionTab  header={<span className="acrhdrnew">Computer Vision</span>}  className=" accordion-tab "   >
                      <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                      <AccordionTab  header={<span className="acrhdrnew">Time Series Forecasting</span>}  className=" accordion-tab "   >
                      <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                       <AccordionTab  header={<span className="acrhdrnew">Azure Cloud Services</span>}  className=" accordion-tab "   >
                       <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                       <AccordionTab  header={<span className="acrhdrnew">AWS Cloud Services</span>}  className=" accordion-tab "   >
                       <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                      <AccordionTab  header={<span className="acrhdrnew">Section 1 : Course Introduction</span>}  className=" accordion-tab "   >
                      <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                      <AccordionTab  header={<span className="acrhdrnew">Computer Vision</span>}  className=" accordion-tab "   >
                      <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                      <AccordionTab  header={<span className="acrhdrnew">Time Series Forecasting</span>}  className=" accordion-tab "   >
                      <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                       <AccordionTab  header={<span className="acrhdrnew">Azure Cloud Services</span>}  className=" accordion-tab "   >
                       <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                       <AccordionTab  header={<span className="acrhdrnew">AWS Cloud Services</span>}  className=" accordion-tab "   >
                       <div  className='flex justify-content-between accordion-content'>
     <section className='flex flex-column gap-3 w-full'  >
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 3.  Expected Value</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 4.  Measure of Skewness</h1><h1 className='acrbdyttl'>01:55</h1></span>
      <span className="flex justify-content-between align-items-center w-full" >        <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1><h1 className='acrbdyttl'>01:55</h1></span>

        
     </section>
</div>
                      </AccordionTab>
                   </Accordion> */}
    </div>
        
    
    <div className='w-full  px-2' >
    <div className="card " >
            <TabView>
                <TabPanel className='tabhdr0'  header="Overview">
                
                   <div>
                   <div className='w-full flex  ' >
        <div className='w-full flex flex-column pt-4' >
        <h1 className='individualheader0 text-left mb-2'>What you’ll <span className='individualheader20'>learn</span></h1>
         <ul className="ml-3" >
         {Coursedata&&Coursedata.overview&&Coursedata.overview.what_you_will_learn?.map((el)=>(
            <li className='indiduallist1 '>{el}</li>
          ))}
         </ul>
        </div>
       
    </div>
                {/* <h1 className='individualrequirement text-left mb-3 pl-8'>About this Course</h1>
                <ul className="ml-5 pl-8">
                    <li className='individualrequirementlist'>Lorem ipsum deeper suman de corperum mapgna deppler gurbemme gabvred  </li>
                    <li className='individualrequirementlist'>Lorem ipsum deeper suman de corperum mapgna deppler gurbemme gabvred  </li>
                    <li className='individualrequirementlist'>Lorem ipsum deeper suman de corperum mapgna deppler gurbemme gabvred  </li>                    
                </ul> */}
                <h1 className='individualheader0 text-left mt-3 mb-3 '>Description</h1>
                <p className='individualrequirementdesc '> {flag === 0 ?
    (Coursedata && Coursedata.overview && Coursedata.overview.long_description.split('\n\n').slice(0, 1).map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    )))
    :
    (Coursedata && Coursedata.overview && Coursedata.overview.long_description.split('\n\n').map((paragraph, index, array) => (
      <React.Fragment key={index}>
        <p>{paragraph}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    )))
  }
</p>
<h3 className='seemorbtn text-right' onClick={() => setFlag(flag === 0 ? 1 : 0)}>
  {flag === 0 ? "Show more" : "Show less"}
</h3> </div>
                </TabPanel>
                <TabPanel className='tabhdr0' header="Reviews">
                    <div className="mt-2">

                <h1 className='individualrequirementreviews0 text-left'>Students <span className='individualrequirementreviews20'>Feedback</span> </h1>
                <div  className='w-full flex gap-1  justify-content-between align-items-center pl-0'>
                <div  className='w-3 flex flex-column ' >
                        <h3  className='individualrating0 mt-0 mb-0 pb-0 m-auto'>{parseFloat(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average.toFixed(2))}</h3>
                        <span className='-mt-1 mb-1  flex justify-content-start'><svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>0?"#0E8E60":"#E0E0E0"}/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>1?"#0E8E60":"#E0E0E0"}/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>2?"#0E8E60":"#E0E0E0"}/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>3?"#0E8E60":"#E0E0E0"}/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(Coursedata&&Coursedata.reviews_insights&&Coursedata.reviews_insights.average)>4?"#0E8E60":"#E0E0E0"}/>
                        </svg></span>
                        <span className='individualratingdesc0 text-center'>Tutorial rating</span> 
                    </div>
                    <div  className='w-5 flex flex-column justify-content-around pt-2  gap-3'>
                    {/* <Slider  className="w-9rem" value={70} />
                    <Slider  className="w-9rem" value={50} />
                    <Slider  className="w-9rem" value={20} />
                    <Slider  className="w-9rem" value={10} />
                    <Slider  className="w-9rem" value={0} /> */}
                    <Slider  className="w-8rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_5)} />
                    <Slider  className="w-8rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_4)} />
                    <Slider  className="w-8rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_3)} />
                    <Slider  className="w-8rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_2)} />
                    <Slider  className="w-8rem" value={Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_1)} />

                    </div>
                    <div className='w-4 flex flex-column  pt-1  gap-1 ml-2'>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0 '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#0E8E60"/>
                        </svg> <span className='individualpercnt0 '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_5)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg"width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt0 '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_4)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt0 '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_3)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt0 '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_2)}%</span>
                        </span>
                        <span className='flex justify-content-start align-items-center mt-0 mb-0'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
                        </svg> <span className='individualpercnt0 '>{Coursedata&&Coursedata.reviews_insights&&parseInt(Coursedata.reviews_insights.percentage_1)}%</span>
                        </span>
                    </div>
                </div>
                
                <h1 className='individualrequirementrewieshd0 text-left mb-2'>Reviews</h1>
                <div className='w-full my-2 pt-2'>
                  <h1 className='individualrequirement0 text-left'>Give Your Feedback <span style={{color:"#0E8E60"}}>*</span></h1>
                  <InputTextarea className='w-full mt-2' value={feedback} onChange={(e) =>{ setFeedback(e.target.value);setCheck(false)}} placeholder='comment'  rows={2} cols={30} />
                  <h6 className="w-full  text-xs" style={{color:"red",textAlign:"left",fontWeight:"400"}}>{check==true?feedback==""?"feedback is missing":"":""}</h6> 
                  <div className='flex gap-4'><h1 className='individualratingdescR  text-left'>Overall rating <span style={{color:"#0E8E60"}}>*</span></h1> 
                  <div className='flex flex-column'><Rating value={rating} onChange={(e) =>{ setRating(e.value);setCheck(false)}} cancel={false} />
                  <span className=" text-xs" style={{color:"red",fontWeight:"400"}}>{check==true?rating==null?"rating is missing":"":""}</span> </div></div>
                  {reviewLoader? <Button className="crsadtocrtbtnR mt-2" type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem',color:"white" }}></i>} />:
                          <Button className='crsadtocrtbtnR mt-2' onClick={handlePostReview} >Post Feedback</Button>}
                 
                  </div>
                <div>
                  {Coursedata&&Coursedata.reviews&&Coursedata.reviews.map((el)=>(
  <div  className='w-full flex my-4'>
  <div className='w-2 pt-1'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<circle cx="20" cy="20" r="20" fill="#0E8E60"/>
</svg></div>
  <div className='w-10 flex flex-column'>
    <h3 className='text-left individualreviewname0 mt-0'>{el.full_name}</h3>
    <span className='flex justify-content-start align-items-center -mt-0 mb-0 -ml-3 '>
      {el.rating==4?
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
<path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
<path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
<path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
<path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
<path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
    </svg>: <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
<path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#D9D9D9"/>
<path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#D9D9D9"/>
<path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#D9D9D9"/>
<path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#D9D9D9"/>
<path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#D9D9D9"/>
    </svg>}
    <span className='individualdate '>{calculateAgeInMonths(el.ingested_date_time)} months ago</span>
    </span>
    <span className='individualreviewtext'>{el.review_text}</span>
    <div className='flex gap-3'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
<path d="M8.5206 13.7144H12.9622C13.3718 13.7144 13.7222 13.4858 13.8702 13.1566L15.3606 9.93378C15.405 9.82864 15.4297 9.71893 15.4297 9.60007V8.68578C15.4297 8.18293 14.9855 7.7715 14.4427 7.7715L11.3286 7.7715L11.7975 5.68236L11.8123 5.53607C11.8123 5.34864 11.7284 5.17493 11.5951 5.0515L11.072 4.5715L7.81982 7.58407C7.64216 7.74864 7.53358 7.97721 7.53358 8.22864L7.53358 12.8001C7.53358 13.3029 7.97774 13.7144 8.5206 13.7144ZM8.5206 8.22864L10.6624 6.24464L10.0011 8.68578H14.4427V9.60007L12.9622 12.8001H8.5206V8.22864ZM6.54657 13.7144H4.57255V8.22864H6.54657V13.7144Z" fill="black"/>
</svg><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
<path d="M11.4794 5.71436H7.03784C6.62823 5.71436 6.27784 5.94293 6.12979 6.27207L4.6394 9.49493C4.59499 9.60007 4.57031 9.70978 4.57031 9.82864V10.7429C4.57031 11.2458 5.01447 11.6572 5.55733 11.6572H8.67135L8.20252 13.7464L8.18771 13.8926C8.18771 14.0801 8.27161 14.2538 8.40486 14.3772L8.92797 14.8572L12.1802 11.8446C12.3578 11.6801 12.4664 11.4515 12.4664 11.2001V6.62864C12.4664 6.12578 12.0223 5.71436 11.4794 5.71436ZM11.4794 11.2001L9.33758 13.1841L9.99888 10.7429H5.55733V9.82864L7.03784 6.62864H11.4794V11.2001ZM13.4534 5.71436H15.4275V11.2001H13.4534V5.71436Z" fill="black" fill-opacity="0.9"/>
</svg> <span className='rprtbtn'>Report</span> </div>
  </div>

</div>
                  ))}
                {/* <div  className='w-full flex mb-2'>
                      <div className='w-2 pt-1'><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <circle cx="20" cy="20" r="20" fill="#0E8E60"/>
</svg></div>
                      <div className='w-10 flex flex-column'>
                        <h3 className='text-left individualreviewname0 mt-0'>Shanthi.k</h3>
                        <span className='flex justify-content-start align-items-center -mt-0 mb-0 -ml-3 '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="11" viewBox="10 1 35 8" fill="none">
      <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z" fill="#0E8E60"/>
      <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill="#0E8E60"/>
      <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill="#0E8E60"/>
                        </svg> <span className='individualdate '>6 months ago</span>
                        </span>
                        <span className='individualreviewtext'>Lorem ipsum manga monmey negens degree cumonde Was this review helpful?</span>
                        <div className='flex gap-3'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
  <path d="M8.5206 13.7144H12.9622C13.3718 13.7144 13.7222 13.4858 13.8702 13.1566L15.3606 9.93378C15.405 9.82864 15.4297 9.71893 15.4297 9.60007V8.68578C15.4297 8.18293 14.9855 7.7715 14.4427 7.7715L11.3286 7.7715L11.7975 5.68236L11.8123 5.53607C11.8123 5.34864 11.7284 5.17493 11.5951 5.0515L11.072 4.5715L7.81982 7.58407C7.64216 7.74864 7.53358 7.97721 7.53358 8.22864L7.53358 12.8001C7.53358 13.3029 7.97774 13.7144 8.5206 13.7144ZM8.5206 8.22864L10.6624 6.24464L10.0011 8.68578H14.4427V9.60007L12.9622 12.8001H8.5206V8.22864ZM6.54657 13.7144H4.57255V8.22864H6.54657V13.7144Z" fill="black"/>
</svg><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <circle cx="10" cy="10" r="9.75" fill="white" stroke="#0E8E60" stroke-width="0.5"/>
  <path d="M11.4794 5.71436H7.03784C6.62823 5.71436 6.27784 5.94293 6.12979 6.27207L4.6394 9.49493C4.59499 9.60007 4.57031 9.70978 4.57031 9.82864V10.7429C4.57031 11.2458 5.01447 11.6572 5.55733 11.6572H8.67135L8.20252 13.7464L8.18771 13.8926C8.18771 14.0801 8.27161 14.2538 8.40486 14.3772L8.92797 14.8572L12.1802 11.8446C12.3578 11.6801 12.4664 11.4515 12.4664 11.2001V6.62864C12.4664 6.12578 12.0223 5.71436 11.4794 5.71436ZM11.4794 11.2001L9.33758 13.1841L9.99888 10.7429H5.55733V9.82864L7.03784 6.62864H11.4794V11.2001ZM13.4534 5.71436H15.4275V11.2001H13.4534V5.71436Z" fill="black" fill-opacity="0.9"/>
</svg> <span className='rprtbtn'>Report</span> </div>
                      </div>

                </div> */}
               
             

                </div>
                   {/* <h3 className='seemorbtn text-right w-full'>Show more</h3> */}
                </div>

                </TabPanel>
                <TabPanel className='tabhdr0' header="Instructors">
                <div  className='' >
                <h3 className='individualrequirementrewieshd0 mb-2 text-left'>Instructor</h3>
                      <div className='flex w-full gap-3 mb-3'>
                        <div className='w-6 pb-0 mb-0 insphoto' style={{backgroundColor:"#0E8E60",borderRadius:"6px"}}><img src={Coursedata&&Coursedata.instructor&&Coursedata.instructor.photo} style={{width:"100%",borderRadius:"6px",objectFit:"cover"}}  /></div>
                        <div className='w-8 gap-2  flex flex-column' >
                          <h3 className='insnm text-left my-0' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.name}</h3>
                          <span className='flex justify-content-start gap-2 insrating0'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path opacity="0.95" d="M9.33426 1.04894C9.54381 0.404018 10.4562 0.404018 10.6657 1.04894L12.537 6.80811C12.6307 7.09652 12.8995 7.2918 13.2027 7.2918H19.2583C19.9364 7.2918 20.2183 8.15953 19.6697 8.55811L14.7707 12.1175C14.5254 12.2957 14.4227 12.6117 14.5164 12.9001L16.3877 18.6593C16.5972 19.3042 15.8591 19.8405 15.3105 19.4419L10.4115 15.8825C10.1661 15.7043 9.83389 15.7043 9.58855 15.8825L4.68951 19.4419C4.14091 19.8405 3.40277 19.3042 3.61232 18.6593L5.48358 12.9001C5.5773 12.6117 5.47464 12.2957 5.2293 12.1175L0.330251 8.55811C-0.218349 8.15953 0.0635941 7.2918 0.7417 7.2918H6.79725C7.10051 7.2918 7.36928 7.09652 7.46299 6.80811L9.33426 1.04894Z" fill="#0E8E60" fill-opacity="0.95"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.rating} rating</span>
<span className='flex justify-content-start gap-1 insrating0'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
  <path d="M4.8 15H14.4V12.5H4.8V15ZM16.8 15H19.2V12.5H16.8V15ZM4.8 10H7.2V7.5H4.8V10ZM9.6 10H19.2V7.5H9.6V10ZM2.4 20C1.74 20 1.1748 19.755 0.704402 19.265C0.234002 18.775 -0.000797963 18.1867 2.03735e-06 17.5V2.5C2.03735e-06 1.8125 0.235202 1.22375 0.705602 0.733752C1.176 0.243752 1.7408 -0.000831211 2.4 2.12224e-06H21.6C22.26 2.12224e-06 22.8252 0.245002 23.2956 0.735002C23.766 1.225 24.0008 1.81334 24 2.5V17.5C24 18.1875 23.7648 18.7762 23.2944 19.2662C22.824 19.7562 22.2592 20.0008 21.6 20H2.4ZM2.4 17.5H21.6V2.5H2.4V17.5Z" fill="#0E8E60"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.total_num_reviews} reviews</span>
<span className='flex justify-content-start gap-2 insrating0'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
  <path d="M4.375 0C5.03804 0 5.67393 0.231785 6.14277 0.644365C6.61161 1.05694 6.875 1.61652 6.875 2.2C6.875 2.78348 6.61161 3.34306 6.14277 3.75564C5.67393 4.16822 5.03804 4.4 4.375 4.4C3.71196 4.4 3.07607 4.16822 2.60723 3.75564C2.13839 3.34306 1.875 2.78348 1.875 2.2C1.875 1.61652 2.13839 1.05694 2.60723 0.644365C3.07607 0.231785 3.71196 0 4.375 0ZM2.5 5.5H6.25C6.91304 5.5 7.54893 5.73179 8.01777 6.14437C8.48661 6.55695 8.75 7.11652 8.75 7.7V13.75H6.875V22H1.875V13.75H0V7.7C0 7.11652 0.263392 6.55695 0.732233 6.14437C1.20107 5.73179 1.83696 5.5 2.5 5.5ZM15.625 0C16.288 0 16.9239 0.231785 17.3928 0.644365C17.8616 1.05694 18.125 1.61652 18.125 2.2C18.125 2.78348 17.8616 3.34306 17.3928 3.75564C16.9239 4.16822 16.288 4.4 15.625 4.4C14.962 4.4 14.3261 4.16822 13.8572 3.75564C13.3884 3.34306 13.125 2.78348 13.125 2.2C13.125 1.61652 13.3884 1.05694 13.8572 0.644365C14.3261 0.231785 14.962 0 15.625 0ZM13.75 5.5H17.5C18.163 5.5 18.7989 5.73179 19.2678 6.14437C19.7366 6.55695 20 7.11652 20 7.7V13.75H18.125V22H13.125V13.75H11.25V7.7C11.25 7.11652 11.5134 6.55695 11.9822 6.14437C12.4511 5.73179 13.087 5.5 13.75 5.5Z" fill="#0E8E60"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.total_num_students} students</span>
<span className='flex justify-content-start gap-2 insrating0'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M8.5 14.5842V7.41616C8.50015 7.32648 8.52442 7.23848 8.57026 7.1614C8.61611 7.08432 8.68184 7.02099 8.76057 6.97804C8.83931 6.9351 8.92814 6.91412 9.01777 6.91731C9.1074 6.9205 9.19452 6.94773 9.27 6.99616L14.846 10.5792C14.9166 10.6244 14.9747 10.6867 15.0149 10.7602C15.0552 10.8338 15.0763 10.9163 15.0763 11.0002C15.0763 11.084 15.0552 11.1665 15.0149 11.2401C14.9747 11.3137 14.9166 11.3759 14.846 11.4212L9.27 15.0052C9.19452 15.0536 9.1074 15.0808 9.01777 15.084C8.92814 15.0872 8.83931 15.0662 8.76057 15.0233C8.68184 14.9803 8.61611 14.917 8.57026 14.8399C8.52442 14.7628 8.50015 14.6748 8.5 14.5852V14.5842Z" fill="#0E8E60"/>
  <path d="M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 1.5C8.48044 1.5 6.06408 2.50089 4.28249 4.28249C2.50089 6.06408 1.5 8.48044 1.5 11C1.5 13.5196 2.50089 15.9359 4.28249 17.7175C6.06408 19.4991 8.48044 20.5 11 20.5C13.5196 20.5 15.9359 19.4991 17.7175 17.7175C19.4991 15.9359 20.5 13.5196 20.5 11C20.5 8.48044 19.4991 6.06408 17.7175 4.28249C15.9359 2.50089 13.5196 1.5 11 1.5Z" fill="#0E8E60"/>
</svg>{Coursedata&&Coursedata.instructor&&Coursedata.instructor.num_of_courses} Courses</span>
                        </div>
                      </div>
                      <p className='insdesc0'>{Coursedata && Coursedata.instructor && Coursedata.instructor.description &&
    Coursedata.instructor.description.split('\n\n').map((paragraph, index, array) => (
      <React.Fragment key={index}>
        <p >{paragraph}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ))}</p>
                      {/* <p className='insdesc0'>Lorem ipsum isd jnisa nknqd jqdn nknkasc bnqisdnk inkindsak ninknsda bhdishf tegugusa vuhihcx xasbdhkscbjgugdi vugaiushd bjosdjfo tegwugfi bdasiocf bafiwheo tqwygeiuo sbciashdof jvjascbisncon bvweugfrhe gugwue asfguagf augsudgwe buwd jgadihiqw vusgadu bubasud vausdv jbubsind ajbdiqwbed fjbqw fiqwebf jqfbiqwef jfbi fkqnwifnfk fbibfi fbiff ifk finbiqwhojwdo qdiqwnfojmqfwnmf noqwjpd nisjfif jhwidnqwf jhuqwijdiqw uhuqnhd  jbwu jnwqidjiw qwjdnwwkdj jqhdiwdj qwjdniqwdj qwjdnqwimf qufuwgdy wgegywg hgufej orkgplr ltkjtoknhr ejhqwijoqw wjhrieokwoqrf uwehriwjrf </p> */}
                  
                      </div>
                </TabPanel>
            </TabView>
        </div>

    </div>
    <div className='w-full px-4 mb-5'>
    <h3 className='individualcrslhdr0 text-left my-2 -ml-2'>You Might also <span className='individualcrslhdr20'>Like</span></h3>
    <Splide hasTrack={ false } className='sdbox px-4'  options={{
            // type: 'loop',
            rewind:false, 
            autoplay: true,
            perMove: 1,
            perPage: 1, 
            gap: '0rem',
            arrows: true,
            pagination: true
           
         }}
        > 
          <SplideTrack>

        {Coursedata&&Coursedata.you_might_like&&Coursedata.you_might_like?.slice(0,12).map((el)=>(
 <SplideSlide>
<div  key={el.id} className='tabcrslbox w-12 p-3 flex flex-column'>
                       <div  className='flex h-2rem align-items-center gap-2 mb-1' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}><img src={el.profile_photo_link} style={{borderRadius:"50%",height:"100%",objectFit:"cover"}} /><span className='tabcrslname' style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.full_name}</span></div>
                       <div className='h-12rem pt-2 flex justify-content-center align-items-center' style={{borderRadius:"10px",overflow:"hidden"}}><img src={el.course_image} onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{ width: "100%",borderRadius:"10px",cursor:"pointer",objectFit:"cover",objectPosition:"50% 50%"}} /></div>
                       {/* <div className='h-12rem pt-2' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}><img src={el.course_image} style={{height:"100%",width:"100%",borderRadius:"10px",objectFit:"cover"}} /></div> */}
                       <span className='tabcrslcoursename pt-2' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} style={{overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>{el.course_name}</span>
                       <span className="flex align-items-center gap-1"><span className='tabcrslname'>{el.rating}</span><svg xmlns="http://www.w3.org/2000/svg" onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)} className='' width="55" height="11" viewBox="0 0 55 11" fill="none">
                       <path d="M4.83426 2.04893C5.04381 1.40402 5.95619 1.40402 6.16574 2.04894L6.57767 3.31672C6.67138 3.60513 6.94015 3.80041 7.24341 3.80041H8.57643C9.25454 3.80041 9.53648 4.66814 8.98788 5.06672L7.90944 5.85025C7.6641 6.0285 7.56144 6.34446 7.65515 6.63287L8.06708 7.90066C8.27663 8.54558 7.53849 9.08186 6.98989 8.68328L5.91145 7.89975C5.66611 7.7215 5.33389 7.7215 5.08855 7.89975L4.01011 8.68328C3.46151 9.08186 2.72337 8.54558 2.93292 7.90066L3.34485 6.63287C3.43856 6.34446 3.3359 6.0285 3.09056 5.85025L2.01212 5.06672C1.46352 4.66814 1.74546 3.80041 2.42357 3.80041H3.75659C4.05985 3.80041 4.32862 3.60513 4.42233 3.31672L4.83426 2.04893Z"   fill={Math.round(el.rating)>0?"#0E8E60":"#E0E0E0"}/>
                         <path d="M15.8343 2.04893C16.0438 1.40402 16.9562 1.40402 17.1657 2.04894L17.5777 3.31672C17.6714 3.60513 17.9401 3.80041 18.2434 3.80041H19.5764C20.2545 3.80041 20.5365 4.66814 19.9879 5.06672L18.9094 5.85025C18.6641 6.0285 18.5614 6.34446 18.6552 6.63287L19.0671 7.90066C19.2766 8.54558 18.5385 9.08186 17.9899 8.68328L16.9114 7.89975C16.6661 7.7215 16.3339 7.7215 16.0886 7.89975L15.0101 8.68328C14.4615 9.08186 13.7234 8.54558 13.9329 7.90066L14.3448 6.63287C14.4386 6.34446 14.3359 6.0285 14.0906 5.85025L13.0121 5.06672C12.4635 4.66814 12.7455 3.80041 13.4236 3.80041H14.7566C15.0599 3.80041 15.3286 3.60513 15.4223 3.31672L15.8343 2.04893Z" fill={Math.round(el.rating)>1?"#0E8E60":"#E0E0E0"}/>
                         <path d="M26.8343 2.04893C27.0438 1.40402 27.9562 1.40402 28.1657 2.04894L28.5777 3.31672C28.6714 3.60513 28.9401 3.80041 29.2434 3.80041H30.5764C31.2545 3.80041 31.5365 4.66814 30.9879 5.06672L29.9094 5.85025C29.6641 6.0285 29.5614 6.34446 29.6552 6.63287L30.0671 7.90066C30.2766 8.54558 29.5385 9.08186 28.9899 8.68328L27.9114 7.89975C27.6661 7.7215 27.3339 7.7215 27.0886 7.89975L26.0101 8.68328C25.4615 9.08186 24.7234 8.54558 24.9329 7.90066L25.3448 6.63287C25.4386 6.34446 25.3359 6.0285 25.0906 5.85025L24.0121 5.06672C23.4635 4.66814 23.7455 3.80041 24.4236 3.80041H25.7566C26.0599 3.80041 26.3286 3.60513 26.4223 3.31672L26.8343 2.04893Z" fill={Math.round(el.rating)>2?"#0E8E60":"#E0E0E0"}/>
                         <path d="M37.8343 2.04893C38.0438 1.40402 38.9562 1.40402 39.1657 2.04894L39.5777 3.31672C39.6714 3.60513 39.9401 3.80041 40.2434 3.80041H41.5764C42.2545 3.80041 42.5365 4.66814 41.9879 5.06672L40.9094 5.85025C40.6641 6.0285 40.5614 6.34446 40.6552 6.63287L41.0671 7.90066C41.2766 8.54558 40.5385 9.08186 39.9899 8.68328L38.9114 7.89975C38.6661 7.7215 38.3339 7.7215 38.0886 7.89975L37.0101 8.68328C36.4615 9.08186 35.7234 8.54558 35.9329 7.90066L36.3448 6.63287C36.4386 6.34446 36.3359 6.0285 36.0906 5.85025L35.0121 5.06672C34.4635 4.66814 34.7455 3.80041 35.4236 3.80041H36.7566C37.0599 3.80041 37.3286 3.60513 37.4223 3.31672L37.8343 2.04893Z" fill={Math.round(el.rating)>3?"#0E8E60":"#E0E0E0"}/>
                         <path d="M48.8343 2.04893C49.0438 1.40402 49.9562 1.40402 50.1657 2.04894L50.5777 3.31672C50.6714 3.60513 50.9401 3.80041 51.2434 3.80041H52.5764C53.2545 3.80041 53.5365 4.66814 52.9879 5.06672L51.9094 5.85025C51.6641 6.0285 51.5614 6.34446 51.6552 6.63287L52.0671 7.90066C52.2766 8.54558 51.5385 9.08186 50.9899 8.68328L49.9114 7.89975C49.6661 7.7215 49.3339 7.7215 49.0886 7.89975L48.0101 8.68328C47.4615 9.08186 46.7234 8.54558 46.9329 7.90066L47.3448 6.63287C47.4386 6.34446 47.3359 6.0285 47.0906 5.85025L46.0121 5.06672C45.4635 4.66814 45.7455 3.80041 46.4236 3.80041H47.7566C48.0599 3.80041 48.3286 3.60513 48.4223 3.31672L48.8343 2.04893Z" fill={Math.round(el.rating)>4?"#0E8E60":"#E0E0E0"}/>
              </svg></span>
                       <div className='flex justify-content-between align-items-center'><span className='tabprice' onClick={()=>navigate(`/self-learning/selfbuythiscourse/${el.course_id}`)}>₹ {el.course_cost}</span><Button className='tabbuybtn'  onClick={()=>CartAddfunction(el.course_id,el)}>Buy now</Button></div>
                    </div></SplideSlide>
        ))}
        </SplideTrack>

    
  <div className="splide__arrows">
    <button className="splide__arrow splide__arrow--prev shadow-3 -mt-5 -ml-4"><i className="pi pi-angle-left" style={{ fontSize: '1.2rem' }}></i></button>
    <button className="splide__arrow splide__arrow--next shadow-3 -mt-5 -mr-4"><i className="pi pi-angle-right" style={{ fontSize: '1.2rem',color:"white" }}></i>

</button>
  </div>
       </Splide>
    </div>
    </div>
    
   

  </div>
    
    </div>}
    {/* </div> */}
      <Foot  />


 

      <Dialog header="" footer={footerContent} className=".p-dialog .p-dialog-header .p-dialog-title  dghdr mb-0  md:max-w-full" visible={visible} onHide={()=>{setVisible(false)}}   breakpoints={{ '960px': '75vw', '638px': '100vw' }}>
                           <h2 className="text-center"> Congratulations!</h2>
              <h3 className="mt-2  m-auto text-center " >You have successfully finished the course.</h3>
              
              </Dialog>
    </>
  );
};

export default SelfCoursesPage;
